import moment from "moment";
const { t } = require("react-i18nify");

export default class Calculations {
  static prettyPrintFlightTime(flightTime) {
    if (typeof flightTime === "undefined") {
      return "";
    }
    if (flightTime.indexOf(":") === "-1") {
      flightTime = flightTime + ":0";
    }
    const times = flightTime.split(":");
    let prettyPrint = "";
    if (parseInt(times[0], 10) > 0) {
      prettyPrint += parseInt(times[0], 10) + t("date.h");
    }
    if (parseInt(times[1], 10) > 0) {
      prettyPrint += parseInt(times[1], 10) + t("date.m");
    }
    return prettyPrint;
  }
  static convertTextDuration(durationText) {
    let times = durationText.split(":");
    return parseInt(times[0], 10) * 60 + parseInt(times[1], 10);
  }
  static convertDuration(duration) {
    var h = Math.floor(duration / 60);
    var m = Math.round(duration % 60, 2);
    var totalTimeString = "";
    if (h > 0) {
      totalTimeString += h + t("date.h");
    }
    if (m > 0) {
      totalTimeString += m + t("date.m");
    }
    return totalTimeString;
  }

  static convertDurationToTextNotation(duration) {
    var h = Math.floor(duration / 60);
    var m = Math.round(duration % 60, 2);
    var totalTimeString = "";
    if (h > 0) {
      totalTimeString += h;
    }
    if (m > 0) {
      totalTimeString += ":" + m;
    }
    return totalTimeString;
  }

  static calculateRemainingTimeReal(departureTime, offset, duration) {
    console.log(departureTime, duration);
    var diff =
      duration * 60 * 1000 - (moment.utc() - moment.unix(departureTime));
    if (diff < 0) {
      diff = 0;
    }
    if (diff > duration * 60 * 1000) {
      diff = 0;
    }
    var msec = diff;
    var hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    var mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    var ss = Math.floor(msec / 1000);
    msec -= ss * 1000;

    return [Math.abs(hh), Math.abs(mm)];
  }

  static calculateRemainingPercentageReal(totalTimeMinute, remainingTime) {
    var remainingTimeMinute = remainingTime[0] * 60 + remainingTime[1];
    var totalTimeInAir = totalTimeMinute - remainingTimeMinute;
    return Math.round((100 * totalTimeInAir) / totalTimeMinute);
  }

  static calculateRemainingFlightTime(departureTime, duration) {
    // Convert departure time from Unix timestamp to JavaScript Date object
    const departureDate = new Date(departureTime * 1000);
    // Calculate estimated arrival time
    const arrivalDate = new Date(departureDate.getTime() + duration * 60000);
  
    // Get current date and time
    const currentDate = new Date();
  
    // Calculate remaining time in milliseconds
    let remainingTimeMs = arrivalDate.getTime() - currentDate.getTime();
  
    // If the remaining time is negative, the flight might have already landed
    if (remainingTimeMs < 0) {
      return t("live_flights.landing"); 
    }
  
    // Convert milliseconds to minutes
    const remainingTimeMinutes = Math.floor(remainingTimeMs / 60000);
    const hours = Math.floor(remainingTimeMinutes / 60);
    const minutes = remainingTimeMinutes % 60;
  
    // Format output with translation
    let timeString = "";
    if (hours > 0) {
      timeString += `${hours} ${t("date.h")}${hours > 1 ? "" : ""} `;
    }
    if (minutes > 0 || timeString === "") {
      timeString += `${minutes} ${t("date.m")}${minutes !== 1 ? "" : ""}`;
    }
    return timeString;
  }
}
