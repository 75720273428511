
const profileStateReducer = (state = {
    type: 'standard',
  },
  action) => {

    // jshint ignore:start
    switch (action.type) {
      case 'SET_PROFILE_TYPE':
        return {
          ...state,
          type: action.value
        };
      default:
        return state;
    }
    // jshint ignore:end
};

export default profileStateReducer;
