import {LngLat, LngLatBounds} from 'mapbox-gl';
const mapBounds = new LngLatBounds(
  new LngLat(-150, -56),
  new LngLat(180, 74)
);
const minZoomLevel = 1;//2.11;//1.982;//1.5305;//1.4563837527874364; //1.948; //1.5305;
const maxZoomLevel = (minZoomLevel) * 6;
const mapboxOptions = {
    accessToken: 'pk.eyJ1IjoiZWZhYnJpa2EiLCJhIjoiY2pta2p0eGkyMG5lZTN2cnM1bGFsMWFjdCJ9.kD3E9ZHe_AwQXCmSC4XTCg',
    minZoomLevel: minZoomLevel,
    maxZoomLevel: maxZoomLevel,
    language: 'tr',
    worldview: 'TR',
    mapOptions: {
        container: 'map',
        style: 'mapbox://styles/efabrika/cldtjcyfl000501nxzmzsagm3',
        zoom: minZoomLevel,
        center: [29.8186794, 20.9818546],
        renderWorldCopies : false,
        hash: false,
        pitchWithRotate: false,
       // maxBounds: mapBounds,
        minZoom: minZoomLevel,
        maxZoom: maxZoomLevel,
        language: 'tr',
        worldview: 'TR',
    },
    buildId: 'v'
      + process.env.REACT_APP_VERSION_BREAKING
      + '.'
      + process.env.REACT_APP_VERSION_FEATURE
      + '.'
      + process.env.REACT_APP_VERSION_FIX
};

export default mapboxOptions;
