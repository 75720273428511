import React, {Component} from "react";
import {connect} from "react-redux";
import {t, Translate} from "react-i18nify";
import {FlightTimes, Frequencies, Routes, Strings} from "helpers";
import DestinationsTooltip from "./DestinationTooltip";
import RadialChart from "./RadialChart";
import {Tooltip} from "react-tooltip";
import moment from "moment";
import {Calculations} from "../../../helpers";

let formatPhoneNumber = (str) => {
  let formattedNumber = str.substring(str.length - 2, str.length);
  formattedNumber =
    str.substring(str.length - 4, str.length - 2) + " " + formattedNumber;
  formattedNumber =
    str.substring(str.length - 7, str.length - 4) + " " + formattedNumber;
  formattedNumber =
    str.substring(str.length - 10, str.length - 7) + " " + formattedNumber;
  formattedNumber =
    "+" + str.substring(0, str.length - 10) + " " + formattedNumber;
  return formattedNumber;
};
class DestinationsBoxForManager extends Component {
  constructor(props) {
    super(props);
    this.selectedRouteFromCityFrequency = [0, 0, 0, 0, 0, 0, 0];
    this.selectedRouteToCityFrequency = [0, 0, 0, 0, 0, 0, 0];
    this.fromCityIata = "IST";
    this.toCityIata = null;

    this.state = {
      tooltipData: {
        display: "none",
        position: {
          left: 0,
          top: 0,
        },
      },
      tooltipType: "to",
      cityData: null,
      searchResultsForToCity: null,
      searchToCityFirstResult: null,
      searchResultsForFromCity: null,
      searchFromCityFirstResult: null,
      formToOnWriting: false,
      formFromOnWriting: false,
      selectedManagerCity: 0
    };
    this.changeDisplay = this.changeDisplay.bind(this);
    this.changeTooltipType = this.changeTooltipType.bind(this);
    this.activeTooltipType = null;
    this.activeTooltip = 0;
    this.searchToCityFirstResult = null;

    this.tooltipCity = null;
    this.transferredFlight = false;
  }

  componentDidCatch(error, info) {
    console.error(error, info);
  }



  componentDidUpdate() {

    console.log(this.props);
    if (this.props.fromCity !== null) {
      if (
        this.state.searchResultsForFromCity === null &&
        this.state.formFromOnWriting === false
      ) {
        document.getElementById("manager-destination-text-from").value = t(
          this.props.fromCity.cityName
        );
      }
    } else {
      const cityData =
        this.props.destinations[this.props.language].activeAirports.IST;
      if (
        this.state.searchResultsForFromCity === null &&
        this.state.formFromOnWriting === false
      ) {
        this.props.setFormCity("SET_FROM_CITY", cityData);
        document.getElementById("manager-destination-text-from").value = "";
      }
    }
    if (this.props.toCity !== null && this.state.formToOnWriting === false) {
      document.getElementById("manager-destination-text-to").value = t(
        this.props.toCity.cityName
      );
    } else {
      if (this.state.searchResultsForToCity === null) {
        this.props.setFormCity("SET_TO_CITY", null);
        document.getElementById("manager-destination-text-to").value = "";
      }
    }
  }

  changeDisplay() {
    this.props.changeDisplay();
  }

  changeTooltipType(type) {
    this.props.changeTooltipType(type);
  }

  componentDidMount() {
    document.getElementById("manager-destination-text-from").value =
      t("İstanbul");
  }

  searchDestinationsForFrom(e) {
    var charCode = typeof e.which === "number" ? e.which : e.keyCode;
    if (charCode === 13 && this.state.searchFromCityFirstResult !== null) {
      this.setState({ formFromOnWriting: false });
      document.getElementById("manager-destinations-form-from").style.display =
        "none";
      if (
        this.props.selectedRegion !==
        this.props.destinations[this.props.language].activeAirports[
          this.state.searchFromCityFirstResult
        ].region
      ) {
        this.props.setRegion("all");
        window.setTimeout(
          function () {
            this.setCity(
              this.state.searchFromCityFirstResult,
              document.getElementById(this.state.searchFromCityFirstResult)
            );
          }.bind(this),
          200
        );
        return true;
      }
      this.setCity(
        this.state.searchFromCityFirstResult,
        document.getElementById(this.state.searchFromCityFirstResult)
      );
      return true;
    }
    document.getElementById("manager-destinations-form-from").style.display =
      "flex";
    const val = document.getElementById("manager-destination-text-from").value;
    const needle = Strings.removeDiacritics(val);
    const searchResults = this.needleInTheHaystack(needle);
    let searchResult = [];
    if (val === "") {
      this.setState({
        searchFromCityFirstResult: null,
      });
      this.setState({
        searchResultsForFromCity: null,
      });
      return false;
    }

    if (Object.keys(searchResults).length === 0) {
      searchResult.push(
        <li key="no-res" className="manager-search-alert">
          {t("destinations.search_no_result")}
        </li>
      );
      this.setState({
        searchFromCityFirstResult: null,
      });
    } else {
      searchResult = [];
      let counter = 0;
      for (let airpotIata in searchResults) {
        let city = searchResults[airpotIata];
        if (counter === 0) {
          this.setState({
            searchFromCityFirstResult: city.cityIata,
          });
        }
        searchResult.push(
          <li key={city.iata}>
            <a data-iata={city.iata} onClick={this.selectCity.bind(this)}>
              {city.name} ({city.iata})
            </a>
          </li>
        );

        counter++;
      }
    }
    this.setState({
      searchResultsForFromCity: searchResult,
    });
  }

  searchDestinationsForTo(e) {
    var charCode = typeof e.which === "number" ? e.which : e.keyCode;
    if (charCode === 13 && this.state.searchToCityFirstResult !== null) {
      this.setState({ formToOnWriting: false });
      document.getElementById("manager-destinations-form-to").style.display =
        "none";
      if (
        this.props.selectedRegion !==
        this.props.destinations[this.props.language].activeAirports[
          this.state.searchToCityFirstResult
        ].region
      ) {
        this.props.setRegion("all");
        window.setTimeout(
          function () {
            this.setCity(
              this.state.searchToCityFirstResult,
              document.getElementById(this.state.searchToCityFirstResult)
            );
          }.bind(this),
          200
        );
        return true;
      }
      this.setCity(
        this.state.searchToCityFirstResult,
        document.getElementById(this.state.searchToCityFirstResult)
      );
      return true;
    }
    document.getElementById("manager-destinations-form-to").style.display =
      "flex";
    const val = document.getElementById("manager-destination-text-to").value;
    const needle = Strings.removeDiacritics(val);
    const searchResults = this.needleInTheHaystack(needle);
    let searchResult = [];
    if (val === "") {
      this.setState({
        searchToCityFirstResult: null,
      });
      this.setState({
        searchResultsForToCity: null,
      });
      return false;
    }

    if (Object.keys(searchResults).length === 0) {
      this.searchToCityFirstResult = null;
      searchResult.push(
        <li key="nor-res" className="manager-search-alert">
          {t("destinations.search_no_result")}
        </li>
      );
      this.setState({
        searchToCityFirstResult: null,
      });
    } else {
      searchResult = [];
      let counter = 0;
      for (let airpotIata in searchResults) {
        let city = searchResults[airpotIata];
        if (counter === 0) {
          this.setState({
            searchToCityFirstResult: city.cityIata,
          });
          this.searchToCityFirstResult = city.cityIata;
        }
        searchResult.push(
          <li key={city.iata}>
            <a data-iata={city.iata} onClick={this.selectCity.bind(this)}>
              {city.name} ({city.iata})
            </a>
          </li>
        );
        counter++;
      }
    }

    this.setState({
      searchResultsForToCity: searchResult,
    });
  }

  hello() {
    console.log("Hello, i am DestinationsBox");
  }

  selectCity(e) {
    e.preventDefault();
    this.setState({selectedManagerCity:0})
    const element = e.target;

    const iataCode = element.getAttribute("data-iata");

    if (
      this.props.selectedRegion !==
      this.props.destinations[this.props.language].activeAirports[iataCode]
        .region
    ) {
      this.props.setRegion("all");
      window.setTimeout(
        function () {
          const destinationElement = document.getElementById(iataCode);
          this.setCity(iataCode, destinationElement);
        }.bind(this),
        100
      );
      return false;
    }

    const destinationElement = document.getElementById(iataCode);
    this.setCity(iataCode, destinationElement);
  }

  setCity(iataCode, element) {
    if (this.props.formFocus === "from") {
      if (
        this.props.toCity !== null &&
        this.props.toCity.cityIata === iataCode
      ) {
        return;
      }
      this.setState({
        searchFromCityFirstResult: null,
      });
      this.setState({
        searchResultsForFromCity: null,
      });
    } else {
      if (
        this.props.fromCity !== null &&
        this.props.fromCity.cityIata === iataCode
      ) {
        return;
      }
      this.setState({
        searchToCityFirstResult: null,
      });
      this.setState({
        searchResultsForToCity: null,
      });
    }

    const elementPosition = element.getBoundingClientRect();
    var event = new MouseEvent("click", {
      x: elementPosition.x,
      y: elementPosition.y,
    });
    element.dispatchEvent(event);

    /*window.setTimeout(function () {
      if (this.props.fromCity !== null && this.props.toCity !== null) {
        this.getAdminStatistics(
          this.props.fromCity.cityIata,
          this.props.toCity.cityIata
        );
      }
    }.bind(this), 300);*/
  }

  needleInTheHaystack(needle) {
    const searchResults = {};
    let nofResults = 0;

    for (let haystack in this.props.searchIndex[this.props.language]) {
      let item = this.props.searchIndex[this.props.language][haystack];
      if (nofResults > 10) {
        break;
      }
      if (
        (haystack.search(new RegExp(needle, "i")) !== -1 ||
          item.cityNameIndex.search(new RegExp(needle, "i")) !== -1) &&
        needle !== ""
      ) {
        nofResults++;
        searchResults[haystack] = item;
      }
    }
    return this.ksort(searchResults);
  }

  ksort(obj) {
    var keys = Object.keys(obj).sort(),
      sortedObj = {};

    for (var i in keys) {
      sortedObj[keys[i]] = obj[keys[i]];
    }

    return sortedObj;
  }

  checkCityIata(cityData) {
    if (cityData === null) {
      return "";
    }
    return cityData.airportIata;
  }

  clearFrom() {
    document.getElementById("manager-destination-text-from").focus();
    const cityData =
      this.props.destinations[this.props.language].activeAirports.IST;

    if (this.props.toCity !== null && this.props.toCity.cityIata !== "IST") {
      this.props.setFormCity("SET_FROM_CITY", null);
      this.props.setFrequency("SET_FROM_CITY_ROUTE_FREQUENCY", null);
    }
    window.setTimeout(function () {
      const element = document.getElementById("IST");
      const elementPosition = element.getBoundingClientRect();
      var event = new MouseEvent("click", {
        x: elementPosition.x,
        y: elementPosition.y,
      });
      element.dispatchEvent(event);
      document.getElementById("manager-destination-text-from").value = "";
      document.getElementById("manager-destinations-form-from").style.display =
        "none";
    }, 300);
    return false;
  }
  clearFrom2() {
    //this.props.setManagerData(null);

      this.setState({formFromOnWriting: true});

    this.props.setFormCity("SET_FROM_CITY", null);
    this.props.setFrequency("SET_FROM_CITY_ROUTE_FREQUENCY", null);
    document.getElementById("manager-destination-text-from").value = "";
    document.getElementById("manager-destinations-form-from").style.display =
      "none";
    window.setTimeout(() => {
      document.getElementById("manager-destination-text-from").focus();
    }, 300);
    return false;
  }
  clearTo() {
    //this.props.setManagerData(null);
   // this.setState({ formToOnWriting: true });
    this.props.setFormCity("SET_TO_CITY", null);
    this.props.setFrequency("SET_FROM_CITY_ROUTE_FREQUENCY", null);
    document.getElementById("manager-destination-text-to").value = "";
    document.getElementById("manager-destinations-form-to").style.display =
      "none";
    window.setTimeout(() => {
      document.getElementById("manager-destination-text-to").focus();
    }, 300);

    return false;
  }

  showTooltipFromCity(e, fromCity) {

    const domReact = document
      .getElementById("destination-box-manager")
      .getBoundingClientRect();
    const top = domReact.top;
    if (this.props.showTooltip === 1 && this.activeTooltipType !== "from") {
      this.props.changeDisplay();
    }
    window.setTimeout(
      function () {
        this.activeTooltipType = "from";
        this.setState({
          tooltipData: {
            display: "block",
            position: {
              left: 577,
              top: top,
            },
          },
          tooltipType: "from",
          cityData: fromCity,
        });

        this.props.changeDisplay();
      }.bind(this),
      10
    );
  }

  showTooltipToCity(e, toCity) {
    const bottom = e.pageY;
    const domReact = document
      .getElementById("destination-box-manager")
      .getBoundingClientRect();
    const top = domReact.top;

    if (this.props.showTooltip === 1 && this.activeTooltipType !== "to") {
      this.props.changeDisplay();
    }
    window.setTimeout(
      function () {
        this.activeTooltipType = "to";

        this.setState({
          tooltipData: {
            display: "block",
            position: {
              left: 577,
              bottom: bottom,
              top: top,
            },
          },
          tooltipType: "to",
          cityData: toCity,
        });

        this.props.changeDisplay();
      }.bind(this),
      10
    );
  }

  setFocus(e, type) {
    let target = "manager-destinations-form-" + type;
    let position = e.target.getBoundingClientRect();
    let bottomPosition = window.innerHeight - position.top + 30;
    //  document.getElementById(target).style.bottom = bottomPosition + 'px';
    if (type === "to") {
    //  this.setState({ formToOnWriting: true });
    } else {
   //   this.setState({ formFromOnWriting: true });
    }

    this.props.setFocus(type);
  }

  setOnBlur(type) {
    this.setState({ formFromOnWriting: false });
    this.setState({ formToOnWriting: false });
    let target = "manager-destinations-form-" + type;
    window.setTimeout(
      function () {
        const el = document.getElementById(target);
        if (el !== null) {
          el.style.display = "none";
        }
        let cityName = "";

        if (type === "from" && this.props.fromCity !== null) {
          cityName = this.props.fromCity.cityName;
        }
        if (type === "to" && this.props.toCity !== null) {
          cityName = this.props.toCity.cityName;
        }
        const element = document.getElementById(
          "manager-destination-text-" + type
        );
        if (element !== null) {
          element.value = cityName;
        }
      }.bind(this),
      200
    );
  }

  closeBox() {
    document.getElementById("toggleBox").classList.remove("open");
    //document.getElementById('search-box').style.display = 'none';
    document.getElementById("result-box").style.display = "none";
  }

  openBox() {
    document.getElementById("toggleBox").classList.add("open");
    // document.getElementById('search-box').style.display = 'block';
    document.getElementById("result-box").style.display = "block";
  }

  setRouteAndFlightTimes() {
    if (this.props.fromCity !== null && this.props.toCity !== null) {
      const fromIata = this.props.fromCity.airportIata;
      const toIata = this.props.toCity.airportIata;
    }
  }

  getFrequency(frequencyString) {
    if (typeof frequencyString === "undefined") {
      return [0, 0, 0, 0, 0, 0, 0];
    }
    let frequencyArray = frequencyString.split(",");
    for (let i = 0; i < frequencyArray.length; i++) {
      if (frequencyArray[i] === ".") {
        frequencyArray[i] = 0;
      }
      frequencyArray[i] = parseInt(frequencyArray[i], 10);
    }

    return frequencyArray;
  }

  componentWillUnmount() {
    this.props.setManagerStopCityData(null);
    this.props.setManagerData(null);
  }

  render() {
    const istanbul = t("İstanbul");
    let fromCityFormValue = istanbul;
    let toCityFormValue = "";

    let isFromIstanbul = 0;
    let isToIstanbul = 0;
    let showTwoLeggedFlights = 0;
    let tooltipType = "from";

    if (
      this.props.fromCity !== null &&
      this.props.fromCity.cityIata === "IST"
    ) {
      isFromIstanbul = 1;
      this.fromCityIata = "IST";
    } else if (
      this.props.fromCity !== null &&
      this.props.fromCity.cityIata !== "IST"
    ) {
      this.fromCityIata = this.props.fromCity.cityIata;
    }

    if (this.props.toCity !== null && this.props.toCity.cityIata === "IST") {
      isToIstanbul = 1;
      this.toCityIata = "IST";
      tooltipType = "to";
    } else if (
      this.props.toCity !== null &&
      this.props.toCity.cityIata !== "IST"
    ) {
      this.toCityIata = this.props.toCity.cityIata;
      tooltipType = "to";
    }
    const tooltipData = this.state.tooltipData;

    let cityData = this.props.fromCity;

    if (this.props.formFocus === "to") {
      cityData = this.props.toCity;
    }

    if (this.state.tooltipData.display === "block") {
      tooltipType = this.state.tooltipType;
    }

    let destinationTooltip = "";
    if (this.props.showTooltip === 1) {
      destinationTooltip = (
        <DestinationsTooltip
          tooltipData={tooltipData}
          tooltipType={tooltipType}
          cityData={cityData}
          changeDisplay={this.changeDisplay}
          type="manager"
        />
      );
    }

    this.selectedRouteFromCityFrequency = [0, 0, 0, 0, 0, 0, 0, 0];
    this.selectedRouteToCityFrequency = [0, 0, 0, 0, 0, 0, 0, 0];

    const routes = new Routes(
      this.props.frequencies,
      this.props.fromCity,
      this.props.toCity
    );

    const points = routes.getRoutes();
    let transferPointIataCode = Routes.getTransferPoint(points);
    const flightTimes = new FlightTimes(
      this.props.flightTimes,
      points,
      transferPointIataCode
    );
    const flightFrequencies = new Frequencies(this.props.frequencies, points);
    const routeFlightTimes = flightTimes.getFlightTimes();
    const routeFrequencies = flightFrequencies.getFrequencies();
    if (points.length > 2) {
      showTwoLeggedFlights = 1;
    }


    // let transferPoint = this.props.destinations[this.props.language].activeAirports[transferPointIataCode];
    let totalFlightTimeFromTransferPoint =
      routeFlightTimes.totalFlightFromTransferPoint !== ""
        ? routeFlightTimes.totalFlightFromTransferPoint
        : 0;
    let totalFlightTimeToTransferPoint =
      routeFlightTimes.totalFlightToTransferPoint !== ""
        ? routeFlightTimes.totalFlightToTransferPoint
        : 0;
    let totalFlightTime =
      routeFlightTimes.totalFlightTime !== ""
        ? routeFlightTimes.totalFlightTime
        : 0;

    if (points.length > 2) {
      this.selectedRouteFromCityFrequency = this.getFrequency(
        routeFrequencies[0]
      );
    }
    if (transferPointIataCode === "IST" && points.length === 2) {
      this.selectedRouteToCityFrequency = this.getFrequency(
        routeFrequencies[0]
      );
    }
    if (transferPointIataCode === "IST" && points.length === 3) {
      this.selectedRouteToCityFrequency = this.getFrequency(
        routeFrequencies[1]
      );
    }
    if (transferPointIataCode === "IST" && points.length === 4) {
      this.selectedRouteToCityFrequency = this.getFrequency(
        routeFrequencies[routeFrequencies.length - 1]
      );
    }
    if (transferPointIataCode === "IST" && points.length > 4) {
      this.selectedRouteToCityFrequency = this.getFrequency(
        routeFrequencies[routeFrequencies.length - 2]
      );
    }
    if (transferPointIataCode !== "IST") {
      let pos = routeFrequencies.length - 1;
      this.selectedRouteToCityFrequency = this.getFrequency(
        routeFrequencies[pos]
      );
    }

    let fromCityFrequencyStyle = { display: "block" };
    let fromCityNoFlight = { display: "none" };

    let toCityFrequencyStyle = { display: "block" };
    let toCityNoFlight = { display: "none" };

    if (totalFlightTimeToTransferPoint === 0) {
      fromCityNoFlight = { display: "block" };
      fromCityFrequencyStyle = { display: "none" };
    }
    if (totalFlightTimeFromTransferPoint === 0) {
      toCityNoFlight = { display: "block", color: "rgb(30, 30, 30)" };
      toCityFrequencyStyle = { display: "none" };
    }
    if (points.length === 2) {
      fromCityNoFlight = { display: "none" };
      fromCityFrequencyStyle = { display: "none" };
    }

    if (points.length < 2) {
      toCityNoFlight = { display: "none" };
      toCityFrequencyStyle = { display: "none" };

      fromCityNoFlight = { display: "none" };
      fromCityFrequencyStyle = { display: "none" };
    }
    if (this.selectedRouteFromCityFrequency.reduce((partialSum, a) => partialSum + a, 0) === 0) {
      fromCityFrequencyStyle = { display: "none" };
      fromCityNoFlight = { display: "block" };
    }
    if (this.selectedRouteToCityFrequency.reduce((partialSum, a) => partialSum + a, 0) === 0) {
      toCityFrequencyStyle = { display: "none" };
      toCityNoFlight = { display: "block", color: "rgb(30, 30, 30)" };
    }
    let flightPlan = '';
    if (this.props.fromCity && this.props.toCity && !this.props.managerStopCityData && this.props.managerCityData && this.props.managerCityData.stopStation === undefined ) {
      flightPlan = <DirectFlight
          origin={this.props.fromCity.airportIata}
          destination={this.props.toCity.airportIata}
      />
    }
    if (this.props.fromCity && this.props.toCity && this.props.managerStopCityData && this.props.managerStopCityData.stopStation)  {
      flightPlan = <LeggedFlight
        origin={this.props.fromCity.airportIata}
        destination={this.props.toCity.airportIata}
        stopStation={this.props.managerStopCityData.stopStation}
        flightTime1={totalFlightTimeToTransferPoint}
        flightTime2={totalFlightTimeFromTransferPoint}
        flightTimes={this.props.flightTimes}
        totalFlightTime={totalFlightTime}
    />}
    if (this.props.fromCity && this.props.toCity && this.props.managerCityData && this.props.managerCityData.stopStation !== undefined) {
      flightPlan = <LeggedFlight
        origin={this.props.fromCity.airportIata}
        destination={this.props.toCity.airportIata}
        stopStation={this.props.managerCityData.stopStation}
        flightTime1={totalFlightTimeToTransferPoint}
        flightTime2={totalFlightTimeFromTransferPoint}
        flightTimes={this.props.flightTimes}

        totalFlightTime={totalFlightTime}
    />}


    console.log(this.state.formToOnWriting)
    return (
      <div
        className="ft-destination-box-manager zIndexTop"
        id="destination-box-manager"
      >
        <a
          className="ft-toggle-searchbox open"
          id="toggleBox"
          style={{ height: 48, paddingTop: 5 }}
        >
          <span className="ft-toggle-searchbox__label">
            {false && <Translate value="destinations.choose_destination" />}
          </span>
          <span
            id="open-box-button"
            className="ft-toggle-searchbox__plus"
            onClick={() => this.openBox()}
          >
            +
          </span>
          <span
            id="close-box-button"
            className="ft-toggle-searchbox__minus"
            onClick={() => this.closeBox()}
          >
            &ndash;
          </span>
        </a>
        <div style={{ backgroundColor: "#ffffff" }} id={"search-box"}>
          <div className="manager-search-box">
            <div className="manager-search-box-item">
              <div>
                <Translate value="destinations.from" />
              </div>
              <div
                style={{
                  display: this.props.fromCity === null ? "block" : "none",
                }}
              >
                <input
                  type="text"
                  required
                  className={
                    this.props.formFocus === "from"
                      ? "form-control selected"
                      : "form-control"
                  }
                  name="text-from"
                  id="manager-destination-text-from"
                  defaultValue={
                    this.props.fromCity !== null
                      ? this.props.fromCity.cityName
                      : ""
                  }
                  onKeyUp={(e) => this.searchDestinationsForFrom(e)}
                  onFocus={(e) => this.setFocus(e, "from")}
                  onBlur={(e) => this.setOnBlur("from")}
                />
              </div>
              {this.props.fromCity !== null && (
                <div className={"manager-search-box-city-name"}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <a onClick={this.clearFrom2.bind(this)}>
                      <span
                        className="material-symbols-outlined"
                        style={{ opacity: 0.5, lineHeight: 2 }}
                      >
                        cancel
                      </span>
                    </a>
                    <span style={{ fontSize: "76%" }}>
                      {t(this.props.fromCity.cityName) +
                        ", " +
                        t(this.props.fromCity.country)}
                    </span>
                  </div>
                  <div
                    style={{ border: 0, display: "flex", alignItems: "center" }}
                  >
                    {this.props.fromCity &&
                      this.props.fromCity.showDetails === 1 && (
                        <a
                          className="manager-show-tooltip"
                          data-type="from"
                          onClick={(e) => {
                            this.showTooltipFromCity(e, this.props.fromCity);
                          }}
                        >
                          ...
                        </a>
                      )}
                  </div>
                </div>
              )}
              <div
                id="manager-destinations-form-from"
                className={" search-results-popup manager-search "}
                onBlur={() => (this.target.style.display = "none")}
                style={
                  this.state.searchResultsForFromCity === null
                    ? { display: "none" }
                    : { display: "flex" }
                }
              >
                <div className={"destinations-ul-list-container"}>
                  <ul>{this.state.searchResultsForFromCity}</ul>
                </div>
              </div>
            </div>
            <div className="manager-search-box-item">
              <div>
                <Translate value="destinations.to" />
              </div>
              <div
                style={{
                  display: this.props.toCity === null ? "block" : "none",
                }}
              >
                <input
                  type="text"
                  className={
                    this.props.formFocus === "to"
                      ? "form-control selected"
                      : "form-control"
                  }
                  name="text-to"
                  id="manager-destination-text-to"
                  onKeyUp={(e) => this.searchDestinationsForTo(e)}
                  onFocus={(e) => this.setFocus(e, "to")}
                  onBlur={(e) => this.setOnBlur("to")}
                />
              </div>
              {this.props.toCity !== null && (
                <div className={"manager-search-box-city-name"}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <a onClick={this.clearTo.bind(this)}>
                      <span
                        className="material-symbols-outlined"
                        style={{ opacity: 0.5, lineHeight: 2 }}
                      >
                        cancel
                      </span>
                    </a>
                    <span style={{ fontSize: "76%" }}>
                      {t(this.props.toCity.cityName) +
                        ", " +
                        t(this.props.toCity.country)}
                    </span>
                  </div>
                  <div
                    style={{ border: 0, display: "flex", alignItems: "center" }}
                  >
                    {this.props.toCity &&
                      this.props.toCity.showDetails === 1 && (
                        <a
                          className="manager-show-tooltip"
                          data-type="to"
                          onClick={(e) => {
                            this.showTooltipToCity(e, this.props.toCity);
                          }}
                        >
                          ...
                        </a>
                      )}
                  </div>
                </div>
              )}

              <div
                id="manager-destinations-form-to"
                className={" search-results-popup manager-search "}
                onBlur={() => (this.target.style.display = "none")}
                style={
                  this.state.searchResultsForToCity === null
                    ? { display: "none" }
                    : { display: "flex" }
                }
              >
                <div className={"destinations-ul-list-container"}>
                  <ul>{this.state.searchResultsForToCity}</ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.managerCityData && this.props.managerCityData.error && (
          <div
            style={{
              color: "#ff6060",
              backgroundColor: "#ffffff",
              padding: 24,
            }}
          >
            <Translate value="destinations.no_data" />
          </div>
        )}
        {this.props.managerCityData === null && this.props.toCity !== null && (
          <div
            style={{
              color: "#ff6060",
              backgroundColor: "#ffffff",
              padding: 24,
              width: "100%",
            }}
          >
            <div className="spinner-container">
              <div className="loading-spinner"></div>
            </div>
          </div>
        )}

        {this.props.managerCityData !== null &&
          this.props.fromCity &&
          this.props.toCity && (
            <div className="ft-flightsearch-results" id="result-box">
              <div
                className="ft-flightsearch-results-item"
                id="destination-search-from"
              >
                <div className="ft-destination-flight-timetable">
                  <div className={"manager-flight-bar"}>
                    <div className={"manager-flight-bar-points"}>
                      {flightPlan}
                    </div>
                    <div className={"manager-flight-bar-time"}>
                      <span className="material-symbols-outlined" style={{color: '#fff'}}>
                        schedule
                      </span>
                      <div
                        style={{ flex: 1 }}
                        className={"manager-flight-bar-time-text"}
                      >
                        {totalFlightTime}
                      </div>
                    </div>
                  </div>
                  <div className={"manager-destination-tab-bar"}>
                    <div
                        className={this.state.selectedManagerCity === 0 ? "active" : ''}
                        onClick={() => this.setState({selectedManagerCity:0})}>
                      {this.props.managerStopCityData && this.props.managerStopCityData.stopStationCity && this.props.managerStopCityData && this.props.managerStopCityData.stopStation && this.props.managerStopCityData.stopStation === 'IST' ? this.props.fromCity.cityName : this.props.toCity.cityName }
                    </div>
                    <div
                        className={this.state.selectedManagerCity === 1 ? "active" : ''}
                        onClick={() => this.props.managerStopCityData && this.props.managerStopCityData.stopStation && this.props.managerStopCityData.stopStation === 'IST'  ? this.setState({selectedManagerCity:1}) : console.log('no action')}>
                      {this.props.managerStopCityData && this.props.managerStopCityData.stopStation && this.props.managerStopCityData.stopStation === 'IST' ? this.props.toCity.cityName : ''}
                    </div>
                  </div>
                  <div className={"manager-destination-tab-bar-caret"}>
                    <div className={this.state.selectedManagerCity === 0 ? "active" : ''}>
                      {this.state.selectedManagerCity === 0 && <span
                        className="material-symbols-outlined"
                        style={{
                          fontSize: 24,
                          color: "#1A344B",
                        }}
                      >
                        arrow_drop_down
                      </span>}
                    </div>
                    <div  className={this.state.selectedManagerCity === 1 ? "active" : ''}>
                      {this.state.selectedManagerCity === 1  && <span
                          className="material-symbols-outlined"
                          style={{
                            fontSize: 24,
                            color: "#1A344B",
                          }}
                      >
                        arrow_drop_down
                      </span>}
                    </div>

                  </div>
                  {this.state.selectedManagerCity === 0 && <CityManagerBox
                      key={"box-city-1"}
                      language={this.props.language}
                      selectedRouteToCityFrequency={this.props.managerStopCityData  && this.props.managerStopCityData.stopStation ? this.selectedRouteFromCityFrequency : this.selectedRouteToCityFrequency}
                      managerCityData={this.props.managerCityData}
                      fromCity={this.props.fromCity}
                      toCity={ this.props.managerStopCityData && this.props.managerStopCityData.stopStation && this.props.managerStopCityData.stopStation === 'IST' ? this.props.managerStopCityData.stopStationCity :this.props.toCity }
                      stopStationCity={ this.props.managerCityData && this.props.managerCityData.stopStation && this.props.managerCityData.stopStation !== 'IST' }
                      toCityNoFlight={this.props.managerStopCityData  && this.props.managerStopCityData.stopStation ?  fromCityNoFlight : toCityNoFlight}
                      toCityFrequencyStyle={this.props.managerStopCityData  && this.props.managerStopCityData.stopStation ?  fromCityFrequencyStyle : toCityFrequencyStyle}
                      destinationTooltip={destinationTooltip}
                      transferFlight={this.props.fromCity && this.props.toCity && this.props.managerStopCityData && this.props.managerStopCityData.stopStation}
                  />}
                  {this.state.selectedManagerCity === 1 && <CityManagerBox
                      key={"box-city-2"}
                      language={this.props.language}
                      selectedRouteToCityFrequency={this.props.managerStopCityData  && this.props.managerStopCityData.stopStation ?  this.selectedRouteToCityFrequency : this.selectedRouteFromCityFrequency}
                      managerCityData={this.props.managerStopCityData}
                      fromCity={this.props.managerStopCityData.stopStationCity}
                      toCity={this.props.toCity}
                      stopStationCity={ this.props.managerStopCityData && this.props.managerStopCityData.stopStation && this.props.managerStopCityData.stopStation !== 'IST' }
                      toCityNoFlight={this.props.managerStopCityData  && this.props.managerStopCityData.stopStation ? toCityNoFlight : fromCityNoFlight}
                      toCityFrequencyStyle={this.props.managerStopCityData  && this.props.managerStopCityData.stopStation ? toCityFrequencyStyle : fromCityFrequencyStyle}
                      destinationTooltip={destinationTooltip}
                      transferFlight={null}

                  />}
                </div>
              </div>
            </div>
          )}


      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeTab: state.app.activeTab,
    language: state.app.language,
    destinations: state.data.destinations,
    managerCityData: state.data.managerCityData,
    managerStopCityData: state.data.managerStopCityData,
    frequencies: state.data.frequencies,
    flightTimes: state.data.flightTimes,
    searchIndex: state.data.searchIndex,
    selectedRegion: state.app.activeDestinationsRegion,

    formFocus: state.app.destinationFormFocus,
    fromCity: state.app.destinationsFromCity,
    toCity: state.app.destinationsToCity,
    selectedRouteFromCityFrequency: state.app.selectedRouteFromCityFrequency,
    selectedRouteToCityFrequency: state.app.selectedRouteToCityFrequency,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRegion: (region) => {
      dispatch({
        type: "SET_DESTINATIONS_REGION",
        value: region,
      });
    },
    setFocus: (input) => {
      dispatch({
        type: "SET_FORM_FOCUS",
        value: input,
      });
    },
    setManagerData: (data) => {
      dispatch({
        type: "SET_MANAGER_DATA",
        value: data,
      });
    },
    setManagerStopCityData: (data) => {
      dispatch({
        type: 'SET_MANAGER_STOP_CITY_DATA',
        value: data
      });
    },
    setFormCity: (action, value) => {
      dispatch({
        type: action,
        value: value,
      });
    },
    setFrequency: (type, frequency) => {
      dispatch({
        type: type,
        value: frequency,
      });
    },
  };
};

const CityManagerBox = ({language, managerCityData, fromCity, toCity, stopStationCity, toCityNoFlight, toCityFrequencyStyle, destinationTooltip, selectedRouteToCityFrequency, transferFlight}) => {

  let thisYear = new Date().getFullYear();
  const thisMonth = new Date().getMonth();

  let lastYear = thisYear - 1;
  let previousYear = thisYear - 2;

  const occupancyRates = { monthly: null, annual: null };
  let destinationData = null;
  let basicIndicatorByYears = {};

  if (
      managerCityData !== null &&
      managerCityData !== undefined &&
      managerCityData.data !== undefined
  ) {
    destinationData = managerCityData;

    if (destinationData.data.basicIndicators === null) {
      destinationData.data.basicIndicators = {valuesByYears: [], updateDate: '1970-01-01'};
    }

    const sortedValuesByYears =
        destinationData.data.basicIndicators.valuesByYears.sort((a, b) => {
          return b.year - a.year;
        });

    destinationData.data.basicIndicatorByYears = destinationData.data
        .basicIndicators
        ? sortedValuesByYears
        : [];
    destinationData.data.occupancyRate =
        destinationData.data.occupancyRates.valuesByYears;
    if (destinationData.data.basicIndicatorByYears.length > 0) {
      const lastStatsYear =
          destinationData.data.basicIndicatorByYears[0].year;


      if (lastStatsYear !== thisYear) {
        thisYear = lastStatsYear;
        lastYear = lastStatsYear - 1;
        previousYear = lastStatsYear - 2;
      }
    }
    if (
        destinationData.data &&
        destinationData.data.occupancyRates &&
        destinationData.data.occupancyRates.valuesByYears.length > 0
    ) {
      destinationData.data.occupancyRate =
          destinationData.data.occupancyRates.valuesByYears;

      const monthlyDiff =
          destinationData.data.occupancyRate[0].monthlyRate -
          (destinationData.data.occupancyRate[1]
              ? destinationData.data.occupancyRate[1].monthlyRate
              : 0);
      const annuallyDiff =
          destinationData.data.occupancyRate[0].annualRate -
          (destinationData.data.occupancyRate[1]
              ? destinationData.data.occupancyRate[1].annualRate
              : 0);
      occupancyRates.monthly = {
        value: Math.abs(monthlyDiff),
        direction:
            monthlyDiff > 0 ? (
                <span
                    className="material-symbols-outlined"
                    style={{ color: "#2EB887" }}
                >
                north
              </span>
            ) : (
                <span
                    className="material-symbols-outlined"
                    style={{ color: "#FF626E" }}
                >
                south
              </span>
            ),
      };
      occupancyRates.annual = {
        value: Math.abs(annuallyDiff),
        direction:
            annuallyDiff > 0 ? (
                <span
                    className="material-symbols-outlined"
                    style={{ color: "#2EB887" }}
                >
                north
              </span>
            ) : (
                <span
                    className="material-symbols-outlined"
                    style={{ color: "#FF626E" }}
                >
                south
              </span>
            ),
      };
      if (monthlyDiff === 0) {
        occupancyRates.monthly.direction = (
            <span
                className="material-symbols-outlined"
                style={{ color: "#999999" }}
            >
              info
            </span>
        );
      }
      if (annuallyDiff === 0) {
        occupancyRates.annual.direction = (
            <span
                className="material-symbols-outlined"
                style={{ color: "#999999" }}
            >
              info
            </span>
        );
      }
    }
    if (
        destinationData.data &&
        destinationData.data.basicIndicatorByYears.length > 0
    ) {
      destinationData.data.basicIndicatorByYears.map((item) => {
        basicIndicatorByYears[item.year] = item;
      });
      Object.keys(basicIndicatorByYears).forEach((key, index) => {
        const previousYear = key - 1;

        if (basicIndicatorByYears[previousYear] !== undefined) {
          basicIndicatorByYears[key].nofPassengerChange = basicIndicatorByYears[previousYear].numberOfPassengers === null
              ? 0
              : basicIndicatorByYears[key].numberOfPassengers - basicIndicatorByYears[previousYear].numberOfPassengers;
          basicIndicatorByYears[key].nofPassengerChangeValue =
              new Intl.NumberFormat(language, {
                style: "decimal",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }).format(
                  Math.round(
                      (Math.abs(basicIndicatorByYears[key].nofPassengerChange) /
                          1000) *
                      100
                  ) / 100
              );

          basicIndicatorByYears[key].numberOfPassengersValue = basicIndicatorByYears[key].numberOfPassengers === null ? 0 :
              new Intl.NumberFormat(language, {
                style: "decimal",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }).format(
                  Math.round(
                      (Math.abs(basicIndicatorByYears[key].numberOfPassengers) /
                          1000) *
                      100
                  ) / 100
              );
          basicIndicatorByYears[key].nofPassengerChangeDirection = basicIndicatorByYears[key].nofPassengerChange === 0
              ? <span className="material-symbols-outlined" style={{color: '#999999'}}>remove</span>
              :
              basicIndicatorByYears[key].nofPassengerChange > 0 ? (
                  <span
                      className="material-symbols-outlined"
                      style={{color: "#2EB887"}}
                  >
                  north
                </span>
              ) : (
                  <span
                      className="material-symbols-outlined"
                      style={{color: "#FF626E"}}
                  >
                  south
                </span>
              );
          basicIndicatorByYears[key].revenueChange = basicIndicatorByYears[previousYear].revenue === null ? 0
              : basicIndicatorByYears[key].revenue - basicIndicatorByYears[previousYear].revenue;
          basicIndicatorByYears[key].revenueChangeValue =
              new Intl.NumberFormat(language, {
                style: "decimal",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }).format(
                  Math.round(
                      (Math.abs(basicIndicatorByYears[key].revenueChange) /
                          1000000) *
                      100
                  ) / 100
              );
          basicIndicatorByYears[key].revenueValue = new Intl.NumberFormat(
              language,
              {
                style: "decimal",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }
          ).format(
              Math.round(
                  (Math.abs(basicIndicatorByYears[key].revenue) / 1000000) * 100
              ) / 100
          );
          basicIndicatorByYears[key].revenueChangeDirection = basicIndicatorByYears[key].revenueChange === 0
              ? <span className="material-symbols-outlined" style={{color: '#999999'}}>remove</span>
              :
              basicIndicatorByYears[key].revenueChange > 0 ? (
                  <span
                      className="material-symbols-outlined"
                      style={{color: "#2EB887"}}
                  >
                  north
                </span>
              ) : (
                  <span
                      className="material-symbols-outlined"
                      style={{color: "#FF626E"}}
                  >
                  south
                </span>
              );

          basicIndicatorByYears[key].profitChange = basicIndicatorByYears[previousYear].profit === null
              ? 0
              : basicIndicatorByYears[key].profit - basicIndicatorByYears[previousYear].profit;
          basicIndicatorByYears[key].profitChangeValue =
              new Intl.NumberFormat(language, {
                style: "decimal",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }).format(
                  Math.round(
                      (basicIndicatorByYears[key].profitChange /
                          1000000) *
                      100
                  ) / 100
              );
          basicIndicatorByYears[key].profitValue = new Intl.NumberFormat(
              language,
              {
                style: "decimal",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }
          ).format(
              Math.round(
                  (basicIndicatorByYears[key].profit / 1000000) * 100
              ) / 100
          );

          basicIndicatorByYears[key].profitChangeDirection = basicIndicatorByYears[key].profitChange === 0
              ? <span className="material-symbols-outlined" style={{color: '#999999'}}>remove</span>
              :
              basicIndicatorByYears[key].profitChange > 0 ? (
                  <span
                      className="material-symbols-outlined"
                      style={{color: "#2EB887"}}
                  >
                  north
                </span>
              ) : (
                  <span
                      className="material-symbols-outlined"
                      style={{color: "#FF626E"}}
                  >
                  south
                </span>
              );
        } else {
          basicIndicatorByYears[key].nofPassengerChange = 0;
          basicIndicatorByYears[key].nofPassengerChangeValue =
              new Intl.NumberFormat(language, {
                style: "decimal",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }).format(
                  Math.round(
                      (Math.abs(basicIndicatorByYears[key].nofPassengerChange) /
                          1000) *
                      100
                  ) / 100
              );

          basicIndicatorByYears[key].numberOfPassengersValue =
              new Intl.NumberFormat(language, {
                style: "decimal",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }).format(
                  Math.round(
                      (Math.abs(basicIndicatorByYears[key].numberOfPassengers) /
                          1000) *
                      100
                  ) / 100
              );
          basicIndicatorByYears[key].nofPassengerChangeDirection = ""; //<span className="material-symbols-outlined" style={{color: '#999999'}}>info</span>;

          basicIndicatorByYears[key].revenueChange = 0;
          basicIndicatorByYears[key].revenueChangeValue =
              new Intl.NumberFormat(language, {
                style: "decimal",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }).format(
                  Math.round(
                      (Math.abs(basicIndicatorByYears[key].revenueChange) /
                          1000000) *
                      100
                  ) / 100
              );
          basicIndicatorByYears[key].revenueValue = new Intl.NumberFormat(
              language,
              {
                style: "decimal",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }
          ).format(
              Math.round(
                  (Math.abs(basicIndicatorByYears[key].revenue) / 1000000) * 100
              ) / 100
          );
          basicIndicatorByYears[key].revenueChangeDirection = ""; //<span className="material-symbols-outlined" style={{color: '#999999'}}>info</span>;

          basicIndicatorByYears[key].profitChange = 0;
          basicIndicatorByYears[key].profitChangeValue =
              new Intl.NumberFormat(language, {
                style: "decimal",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }).format(
                  Math.round(
                      (Math.abs(basicIndicatorByYears[key].profitChange) /
                          1000000) *
                      100
                  ) / 100
              );
          basicIndicatorByYears[key].profitValue = new Intl.NumberFormat(
              language,
              {
                style: "decimal",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }
          ).format(
              Math.round(
                  (Math.abs(basicIndicatorByYears[key].profit) / 1000000) * 100
              ) / 100
          );

          basicIndicatorByYears[key].profitChangeDirection = ""; //<span className="material-symbols-outlined" style={{color: '#999999'}}>info</span>;
        }
      });
    }
  }

  return (<>
    {destinationData.data.personnelInfo && (
        <div className="manager-personnel-info">
          <div
              className="avatar"
              style={{
                backgroundImage: `url(data:image/png;base64,${destinationData.data.personnelInfo.encodedImage})`,
              }}
          ></div>

          <div className="info">
            <div>
              {destinationData.data.personnelInfo.name}{" "}
              {destinationData.data.personnelInfo.surname}
            </div>
            <div className="station">
              {destinationData.data.personnelInfo.title},{" "}
              {destinationData.data.personnelInfo.location}
            </div>
            <div className="contact-info">
    <span className="material-symbols-outlined">
        mail
    </span>
    <a href={`msteams://teams.microsoft.com/l/call/0/0?users=${destinationData.data.personnelInfo.email}&withVideo=true`} target="_blank">
    {destinationData.data.personnelInfo.email}
    </a>
</div>
            <div className="contact-info">
                          <span className="material-symbols-outlined">
                            call
                          </span>
                <a href={`msteams://teams.microsoft.com/l/call/0/0?users=4:${encodeURIComponent(destinationData.data.personnelInfo.gsm)}`} target="_blank">
                {formatPhoneNumber(
                    destinationData.data.personnelInfo.gsm
                )}
              </a>
            </div>
          </div>
          <div className="tenure">

            <div>

              <Translate value="destinations.directorate_duration"/>
            </div>
            <div>

              {transferFlight === 'IST' ? fromCity.airportIata : toCity.airportIata} :{" "}

              {
                destinationData.data.personnelInfo
                    .periodOfDirectorateInStation ? destinationData.data.personnelInfo
                    .periodOfDirectorateInStation.replace('years', 'yr.').replace('months', 'mn') : ''
              }
            </div>
            <div>
              <Translate value="destinations.total"/> :{" "}
              {destinationData.data.personnelInfo
                  .periodOfDirectorateInTk ?
                  destinationData.data.personnelInfo
                      .periodOfDirectorateInTk.replace('years', 'yr.').replace('months', 'mn') : ''
              }
            </div>
          </div>
        </div>
    )}

    <div style={{backgroundColor: 'red', display: toCityNoFlight.display, padding: 5, textAlign: 'center'}}>

      <Translate value="destinations.no_flights"/>

    </div>
    <div className={"manager-stats"}>
      {destinationData.data.occupancyRates.valuesByYears
          .length === 0 && !stopStationCity && (
          <div className={"manager-indicators"}>
            <div className={"manager-h2"}>
              <Translate value="destinations.key_metrics"/>{" "}
              {false && <span className="material-symbols-outlined key-metrix-info">
                            info
                          </span>}
            </div>
            <div className={"manager-indicators-flight-dir"}>
              {fromCity.airportIata}{" "}
              <span className="material-symbols-outlined">
                            trending_flat
                          </span>{" "}
              {toCity.airportIata}
              <span className="material-symbols-outlined">
                            trending_flat
                          </span>{" "}
              {fromCity.airportIata}
            </div>
            <div className={"manager-indicators-flight-dir"} style={{color: '#ff6060'}}>
              <Translate value="destinations.no_data"/>
            </div>

            <Tooltip anchorSelect=".key-metrix-info" place="bottom">
              Lorem ipsum dolor sit amet, consectetur adipiscing
              elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua.
            </Tooltip>
          </div>
      )}
      {destinationData.data.occupancyRates.valuesByYears.length >
          0 && !stopStationCity && (
              <div className={"manager-indicators"}>
                <div className={"manager-h2"}>
                  <Translate value="destinations.key_metrics"/>{" "}
                  {false && <span className="material-symbols-outlined  key-metrix-info">
                            info
                          </span>}
                  <Tooltip
                      anchorSelect=".key-metrix-info"
                      place={"bottom-start"}
                      variant={"dark"}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit, sed do eiusmod tempor incididunt ut labore et
                    dolore magna aliqua.
                  </Tooltip>
                </div>
                <div className={"manager-indicators-flight-dir"}>
                  {fromCity.airportIata}{" "}
                  <span className="material-symbols-outlined">
                            trending_flat
                          </span>{" "}
                  {toCity.airportIata}
                  <span className="material-symbols-outlined">
                            trending_flat
                          </span>{" "}
                  {fromCity.airportIata}
                </div>
                <div className="manager-indicators-table">
                  <div
                      className="manager-indicators-text"
                      style={{
                        textAlign: "center",
                        width: "100%",
                        textTransform: "uppercase",
                      }}
                  >
                    {destinationData.data.basicIndicators.title} <Translate value="destinations.period"/>
                  </div>
                  <div className={"manager-indicators-table-headers "}>
                    <div
                        className={"table-tr-td-1 table-tr-td-no-border"}
                    ></div>
                    <div
                        className={"table-tr-td-2 table-tr-td-no-border"}
                    >
                              <span>
                                <Translate value="destinations.pass_count"/>
                              </span>
                    </div>
                    <div
                        className={"table-tr-td-3 table-tr-td-no-border"}
                    >
                              <span>
                                <Translate value="destinations.revenue"/>
                              </span>
                    </div>
                    <div
                        className={"table-tr-td-4 table-tr-td-no-border"}
                    >
                              <span>
                                <Translate value="destinations.profit"/>
                              </span>
                    </div>
                  </div>
                  <div className={"manager-indicators-table-main"}>
                    {destinationData.data.basicIndicatorByYears
                        .length >= 3 && (
                        <div className={"table-tr"}>
                          <div className={"table-tr-td-1"}>
                            <span>{thisYear}</span>
                          </div>
                          <div className={"table-tr-td-2"}>
                                   <span>
                                    {
                                      basicIndicatorByYears[thisYear]
                                          .numberOfPassengersValue
                                    }{" "}
                                     K
                                  </span>
                            {
                              basicIndicatorByYears[thisYear]
                                  .nofPassengerChangeDirection
                            }
                          </div>
                          <div className={"table-tr-td-3"}>
                                  <span>
                                    {
                                      basicIndicatorByYears[thisYear]
                                          .revenueValue
                                    }{" "}
                                    M
                                  </span>{" "}
                            {
                              basicIndicatorByYears[thisYear]
                                  .revenueChangeDirection
                            }
                          </div>
                          <div className={"table-tr-td-4"}>
                                  <span>
                                    {
                                      basicIndicatorByYears[thisYear]
                                          .profitValue
                                    }{" "}
                                    M
                                  </span>{" "}
                            {
                              basicIndicatorByYears[thisYear]
                                  .profitChangeDirection
                            }
                          </div>
                        </div>
                    )}
                    {destinationData.data.basicIndicatorByYears
                        .length === 1 && (
                        <div className={"table-tr"}>
                          <div className={"table-tr-td-1"}>
                            <span>{thisYear}</span>
                          </div>
                          <div className={"table-tr-td-2"}>
                                  <span>
                                    {
                                      basicIndicatorByYears[thisYear]
                                          .numberOfPassengersValue
                                    }{" "}
                                    K
                                  </span>{" "}
                            {
                              basicIndicatorByYears[thisYear]
                                  .nofPassengerChangeDirection
                            }
                          </div>
                          <div className={"table-tr-td-3"}>
                                  <span>
                                    {
                                      basicIndicatorByYears[thisYear]
                                          .revenueValue
                                    }{" "}
                                    M
                                  </span>{" "}
                            {
                              basicIndicatorByYears[thisYear]
                                  .revenueChangeDirection
                            }
                          </div>
                          <div className={"table-tr-td-4"}>
                                  <span>
                                    {
                                      basicIndicatorByYears[thisYear]
                                          .profitValue
                                    }{" "}
                                    M
                                  </span>{" "}
                            {
                              basicIndicatorByYears[thisYear]
                                  .profitChangeDirection
                            }
                          </div>
                        </div>
                    )}

                    {destinationData.data.basicIndicatorByYears.length >
                        1 && (
                            <div className={"table-tr"}>
                              <div className={"table-tr-td-1"}>
                                <span>{lastYear}</span>
                              </div>
                              <div className={"table-tr-td-2"}>
                                  <span>
                                    {
                                      basicIndicatorByYears[lastYear]
                                          .numberOfPassengersValue
                                    }{" "}
                                    K
                                  </span>{" "}
                                {
                                  basicIndicatorByYears[lastYear]
                                      .nofPassengerChangeDirection
                                }
                              </div>
                              <div className={"table-tr-td-3"}>
                                  <span>
                                    {
                                      basicIndicatorByYears[lastYear]
                                          .revenueValue
                                    }{" "}
                                    M
                                  </span>{" "}
                                {
                                  basicIndicatorByYears[lastYear]
                                      .revenueChangeDirection
                                }
                              </div>
                              <div className={"table-tr-td-4"}>
                                  <span>
                                    {
                                      basicIndicatorByYears[lastYear]
                                          .profitValue
                                    }{" "}
                                    M
                                  </span>{" "}
                                {
                                  basicIndicatorByYears[lastYear]
                                      .profitChangeDirection
                                }
                              </div>
                            </div>
                        )}
                    {destinationData.data.basicIndicatorByYears
                        .length >= 3 && (
                        <div className={"table-tr-no-border"}>
                          <div className={"table-tr-td-1"}>
                            <span>{previousYear}</span>
                          </div>
                          <div className={"table-tr-td-2"}>
                                  <span>
                                    {
                                      basicIndicatorByYears[previousYear]
                                          .numberOfPassengersValue
                                    }{" "}
                                    K
                                  </span>{" "}
                            {
                              basicIndicatorByYears[previousYear]
                                  .nofPassengerChangeDirection
                            }
                          </div>
                          <div className={"table-tr-td-3"}>
                                  <span>
                                    {
                                      basicIndicatorByYears[previousYear]
                                          .revenueValue
                                    }{" "}
                                    M
                                  </span>{" "}
                            {
                              basicIndicatorByYears[previousYear]
                                  .revenueChangeDirection
                            }
                          </div>
                          <div className={"table-tr-td-4"}>
                                  <span>
                                    {
                                      basicIndicatorByYears[previousYear]
                                          .profitValue
                                    }{" "}
                                    M
                                  </span>{" "}
                            {
                              basicIndicatorByYears[previousYear]
                                  .profitChangeDirection
                            }
                          </div>
                        </div>
                    )}
                    {destinationData.data.basicIndicatorByYears
                        .length <= 2 && (
                        <>
                          <div className={"table-tr"}>
                            <div className={"table-tr-td-1"}>
                              <span>{lastYear - 1}</span>
                            </div>
                            <div className={"table-tr-td-2"}>
                                    <span
                                        className="material-symbols-outlined"
                                        style={{color: "#999999"}}
                                    >
                                      unknown_med
                                    </span>
                              ;
                            </div>
                            <div className={"table-tr-td-3"}>
                                    <span
                                        className="material-symbols-outlined"
                                        style={{color: "#999999"}}
                                    >
                                      unknown_med
                                    </span>
                              ;
                            </div>
                            <div className={"table-tr-td-4"}>
                                    <span
                                        className="material-symbols-outlined"
                                        style={{color: "#999999"}}
                                    >
                                      unknown_med
                                    </span>
                              ;
                            </div>
                          </div>
                          <div className={"table-tr"}>
                            <div className={"table-tr-td-1"}>
                              <span>{lastYear - 2}</span>
                            </div>
                            <div className={"table-tr-td-2"}>
                                    <span
                                        className="material-symbols-outlined"
                                        style={{color: "#999999"}}
                                    >
                                      unknown_med
                                    </span>
                              ;
                            </div>
                            <div className={"table-tr-td-3"}>
                                    <span
                                        className="material-symbols-outlined"
                                        style={{color: "#999999"}}
                                    >
                                      unknown_med
                                    </span>
                              ;
                            </div>
                            <div className={"table-tr-td-4"}>
                                    <span
                                        className="material-symbols-outlined"
                                        style={{color: "#999999"}}
                                    >
                                      unknown_med
                                    </span>
                              ;
                            </div>
                          </div>
                        </>
                    )}
                  </div>
                </div>
                <div
                    className="manager-indicators-table-caption"
                    style={{marginTop: 24}}
                >
                  <Translate value="destinations.report_date"/>:{" "}
                  {destinationData.data.basicIndicators
                      ? destinationData.data.basicIndicators.updateDate.split(
                          " "
                      )[0]
                      : ""}
                </div>
              </div>
          )}
      <div className={"manager-flight-details"} style={{
        flexDirection: !!stopStationCity ? 'row' : 'column',
        width: !!stopStationCity ? 'auto' : 264

      }}>
        <div className={"manager-flight-frequency"} style={{
          borderBottom: !!stopStationCity ? 0 : '1px solid #E4E7EB',
          width: 264
        }}>
          <div className={"manager-h2"}>
            <Translate value="destinations.flight_frequency2"/>
          </div>
          <div className={"manager-indicators-flight-dir"}>
            {fromCity.airportIata}{" "}
            <span className="material-symbols-outlined">
                            trending_flat
                          </span>{" "}
            {toCity.airportIata}
          </div>
          <div
              className="manager-no-flight-alert"
              style={toCityNoFlight}
          >
            <Translate
                value="destinations.no_flights"
                className="ft-flight-duration-label"
            />
          </div>
          <div style={toCityFrequencyStyle}>
            <table
                className="ft-flight-timetable"
                style={{
                  border: 0,
                  marginTop: 20,
                  padding: 0,
                  marginBottom: 0,
                }}
            >
              <tbody style={{padding: 0, margin: 0}}>
              <tr
                  className="frequency"
                  style={{border: 0, padding: 0}}
              >
                <td>
                  <Translate value="date.MON"/>
                  <br/>
                  <strong className="day-0">
                    {selectedRouteToCityFrequency[0] !== 0
                        ? selectedRouteToCityFrequency[0] +
                        ""
                        : "-"}
                  </strong>
                </td>
                <td>
                  <Translate value="date.TUE"/>
                  <br/>
                  <strong className="day-1">
                    {selectedRouteToCityFrequency[1] !== 0
                        ? selectedRouteToCityFrequency[1] +
                        ""
                        : "-"}
                  </strong>
                </td>
                <td>
                  <Translate value="date.WED"/>
                  <br/>
                  <strong className="day-2">
                    {selectedRouteToCityFrequency[2] !== 0
                        ? selectedRouteToCityFrequency[2] +
                        ""
                        : "-"}
                  </strong>
                </td>
                <td>
                  <Translate value="date.THU"/>
                  <br/>
                  <strong className="day-3">
                    {selectedRouteToCityFrequency[3] !== 0
                        ? selectedRouteToCityFrequency[3] +
                        ""
                        : "-"}
                  </strong>
                </td>
                <td>
                  <Translate value="date.FRI"/>
                  <br/>
                  <strong className="day-4">
                    {selectedRouteToCityFrequency[4] !== 0
                        ? selectedRouteToCityFrequency[4] +
                        ""
                        : "-"}
                  </strong>
                </td>
                <td>
                  <Translate value="date.SAT"/>
                  <br/>
                  <strong className="day-5">
                    {selectedRouteToCityFrequency[5] !== 0
                        ? selectedRouteToCityFrequency[5] +
                        ""
                        : "-"}
                  </strong>
                </td>
                <td>
                  <Translate value="date.SUN"/>
                  <br/>
                  <strong className="day-6">
                    {selectedRouteToCityFrequency[6] !== 0
                        ? selectedRouteToCityFrequency[6] +
                        ""
                        : "-"}
                  </strong>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={"manager-customer-satisfaction"}>
          <div className={"manager-h2"}>
            <Translate value="destinations.customer_satisfaction"/>
          </div>
          <div className={"manager-indicators-flight-dir"}>
            {toCity.airportIata}
          </div>
          <div
              className="manager-indicators-table-caption"
              style={{
                textAlign: "center",
                width: "100%",
                textTransform: "uppercase",
              }}
          >
            {destinationData.data.customerSatisfaction ? destinationData.data.customerSatisfaction.title : ''}
            <Translate value="destinations.period"/>
          </div>
          {destinationData.data.customerSatisfaction &&
              destinationData.data.customerSatisfaction.target &&
              destinationData.data.customerSatisfaction.actual >
              0 && (
                  <div
                      className={"manager-customer-satisfaction-chart"}
                  >
                    <div
                        className={
                          "manager-customer-satisfaction-chart-stats"
                        }
                    >
                      <div>
                        <Translate value="destinations.percentage_tr"/>
                        {
                          destinationData.data.customerSatisfaction
                              .actual
                        }
                        <Translate value="destinations.percentage_en"/>
                      </div>
                      <div>
                        <Translate value="destinations.target"/> (
                        <Translate value="destinations.percentage_tr"/>
                        {
                          destinationData.data.customerSatisfaction
                              .target
                        }
                        <Translate value="destinations.percentage_en"/>
                        )
                      </div>
                    </div>
                    {destinationData.data.customerSatisfaction
                            .actual >
                        destinationData.data.customerSatisfaction
                            .target && (
                            <div
                                className={
                                  "manager-customer-satisfaction-chart-bar a-bt-t"
                                }
                            >
                              <div
                                  style={{
                                    width:
                                        destinationData.data
                                            .customerSatisfaction.target + "%",
                                  }}
                              ></div>
                              <div style={{width: 2}}></div>
                              <div
                                  style={{
                                    width:
                                        destinationData.data
                                            .customerSatisfaction.actual -
                                        destinationData.data
                                            .customerSatisfaction.target +
                                        "%",
                                  }}
                              ></div>
                            </div>
                        )}
                    {destinationData.data.customerSatisfaction
                            .actual <=
                        destinationData.data.customerSatisfaction
                            .target && (
                            <div
                                className={
                                  "manager-customer-satisfaction-chart-bar a-lt-t"
                                }
                            >
                              <div
                                  style={{
                                    width:
                                        destinationData.data
                                            .customerSatisfaction.actual > 0
                                            ? destinationData.data
                                            .customerSatisfaction.actual + "%"
                                            : 0,
                                  }}
                              ></div>
                              <div
                                  style={{
                                    width:
                                        destinationData.data
                                            .customerSatisfaction.target -
                                        destinationData.data
                                            .customerSatisfaction.actual +
                                        "%",
                                  }}
                              ></div>
                              <div style={{width: 2}}></div>
                            </div>
                        )}
                  </div>
              )}
          {(destinationData.data.customerSatisfaction ===
              undefined ||
              destinationData.data.customerSatisfaction === null ||
              destinationData.data.customerSatisfaction.actual ===
              0) && (
              <div>
                <div className="customer-satisfaction-no-data">
                  N/A
                </div>
                <div className="customer-satisfaction-no-data-bar"/>
              </div>
          )}
          {false && (
              <div
                  className="manager-indicators-table-caption"
                  style={{marginTop: 10}}
              >
                <Translate value="destinations.report_date"/>:{" "}
                {
                  destinationData.data.customerSatisfaction.updateDate.split(
                      " "
                  )[0]
                }
              </div>
          )}
        </div>
      </div>
    </div>
    <div className={"manager-occupancy-rate"}>
      <div className={"manager-h2"}>
        <Translate value="destinations.load_factor"/>
      </div>

      <div className={"manager-indicators-flight-dir"}>
        {fromCity.airportIata}{" "}
        <span className="material-symbols-outlined">
                        trending_flat
                      </span>{" "}
        {toCity.airportIata}
        <span className="material-symbols-outlined">
                        trending_flat
                      </span>{" "}
        {fromCity.airportIata}
      </div>

      {destinationData.data.occupancyRates.valuesByYears
          .length === 0 && (
          <div
              className={"manager-occupancy-items"}
              style={{color: "#ff6060"}}
          >
            <Translate value="destinations.no_data"/>
          </div>
      )}
      {destinationData.data.occupancyRates.valuesByYears.length >
          0 && (
              <div className={"manager-occupancy-items"}>
                <div className={"occupancy-item"}>
                  <div className={"occupancy-texts"}>
                            <span>
                              <Translate value="destinations.year_to_date_l_f"/>
                              (%)
                            </span>
                    {destinationData.data.occupancyRates.valuesByYears
                        .length > 1 && (
                        <div>
                          {occupancyRates.annual.direction}
                          <span>
                                  {new Intl.NumberFormat(language, {
                                    style: "decimal",
                                    minimumFractionDigits: 1,
                                    maximumFractionDigits: 1,
                                  }).format(occupancyRates.annual.value)}{" "}
                            pt
                                </span>
                        </div>
                    )}
                  </div>
                  <div style={{}}>
                    <RadialChart
                        progress={
                          destinationData.data.occupancyRate[0].annualRate
                        }
                        color="#FF002A"
                    />
                  </div>
                </div>
                <div className={"occupancy-item"}>
                  <div className={"occupancy-texts"}>
                            <span>
                              <Translate value="destinations.month_to_date_l_f"/>
                              (%)
                            </span>
                    {destinationData.data.occupancyRates.valuesByYears
                        .length > 1 && (
                        <div>
                          {occupancyRates.monthly.direction}
                          <span>
                                  {new Intl.NumberFormat(language, {
                                    style: "decimal",
                                    minimumFractionDigits: 1,
                                    maximumFractionDigits: 1,
                                  }).format(occupancyRates.monthly.value)}{" "}
                            pt
                                </span>
                        </div>
                    )}
                  </div>
                  <div style={{}}>
                    <RadialChart
                        progress={
                          destinationData.data.occupancyRate[0]
                              .monthlyRate
                        }
                        color="#FF002A"
                    />
                  </div>
                </div>
              </div>
          )}
      <div
          className="manager-indicators-table-caption"
          style={{marginTop: 10}}
      >
        <Translate value="destinations.report_date"/>:{" "}
        {moment(
            destinationData.data.occupancyRates.updateDate,
            "DD.MM.YYYY hh:mm:ss"
        )
            /*.subtract(3, "days")*/
            .format("DD.MM.YYYY")}
      </div>
    </div>
    {destinationTooltip}
  </>)

}

const DirectFlight = ({origin, destination}) => {
  return (
      <div className={"direct-flight-info"}>
        <div className={"direct-flight-info-city-list"}>
          <div className={"text-align-left flex-1"}>{origin}</div>
          <div className={"text-align-right flex-1"}>{destination}</div>
        </div>
        <div className={"direct-flight-info-plane"}>
          <div
              style={{
                width: 11,
                height: 11,
                borderRadius: 5,
                borderColor: "rgba(20,30,46, 1)",
                borderWidth: 1,
                borderStyle: "solid",
              }}
          ></div>
          <div className={"flight-line"}></div>
          <div style={{marginLeft: 5, marginRight: 5, marginTop: 3}}>
            <span className="material-symbols-outlined flight">flight</span>
          </div>
          <div className={"flight-line"}></div>
          <div
              style={{
                width: 11,
                height: 11,
                borderRadius: 5,
                borderColor: "rgba(20,30,46, 1)",
                borderWidth: 1,
                borderStyle: "solid",
              }}
          ></div>
        </div>
      </div>
  );
};
const LeggedFlight = React.memo(({origin, destination, stopStation, flightTimes, totalFlightTime}) => {

  const points1 = [origin, stopStation];
  const points2 = [stopStation, destination];
  const flightTimes1 = new FlightTimes(
      flightTimes,
      points1,
      null
  );

  const routeFlightTimes = flightTimes1.getFlightTimes();

  // let transferPoint = this.props.destinations[this.props.language].activeAirports[transferPointIataCode];
  let flightTime1 =
      routeFlightTimes.totalFlightTime !== ""
          ? routeFlightTimes.totalFlightTime
          : 0;
  let flightTimes2 = new FlightTimes(
      flightTimes,
      points2,
      null
  );

  const routeFlightTimes2 = flightTimes2.getFlightTimes();

  // let transferPoint = this.props.destinations[this.props.language].activeAirports[transferPointIataCode];
  let flightTime2 =
      routeFlightTimes2.totalFlightTime !== ""
          ? routeFlightTimes2.totalFlightTime
          : 0;

  const getMinuteFromText = (time) => {
    if (!time) {
      return 0;
    }
    let regex = /(.*?)sa(.*?)dk/;
    let foundValues = time.match(regex);
    let hour = 0;
    let minute = 0;
    if (time.includes('sa') && time.includes('dk') ) {
      regex = /(.*?)sa(.*?)dk/;
      foundValues = time.match(regex);
      hour = parseInt(foundValues[1]);
      minute = parseInt(foundValues[2]);

    } else if (time.includes('sa')){
      regex = /(.*?)sa/;
      foundValues = time.match(regex);

      hour = parseInt(foundValues[1]);

    }else if (time.includes('dk')){
      regex = /(.*?)dk/;

      foundValues = time.match(regex);

      minute = parseInt(foundValues[1]);
    }
    return hour * 60 + minute;
  }
  if (flightTime2 === 0) {
    const totalFlightMinute = getMinuteFromText(totalFlightTime);
    const totalFlightMinuteLeg1 = getMinuteFromText(routeFlightTimes.totalFlightTime );
    flightTime2 = Calculations.convertDuration(totalFlightMinute-totalFlightMinuteLeg1);
  }
  return (
      <div className={"direct-flight-info"}>
        <div className={"direct-flight-info-city-list"}>
          <div className={"text-align-left"}>{origin}</div>
          <div className={"text-align-left flex-1"} style={{
            color: 'rgba(122, 136, 154, 1)',
            fontWeight: 400,
            fontSize: 14,
            textAlign: "center",
          }}>{flightTime1 !== 0 ? flightTime1 : 'N/A'}</div>
          <div className={"text-align-left"}>{stopStation}</div>
          <div className={"text-align-left flex-1"} style={{
            color: 'rgba(122, 136, 154, 1)',
            fontWeight: 400,
            fontSize: 14,
            textAlign: "center"
          }}>{flightTime2 !== '' ? flightTime2 : 'N/A'}</div>
          <div className={"text-align-right"}>{destination}</div>
        </div>
        <div className={"direct-flight-info-plane"}>
          <div
              style={{
                width: 11,
                height: 11,
                borderRadius: 5,
                borderColor: "rgba(20,30,46, 1)",
                borderWidth: 1,
                borderStyle: "solid",
              }}
          ></div>
          <div className={"flight-line"}></div>
          <div style={{marginLeft: 5, marginRight: 5, marginTop: 3}}>
            <span className="material-symbols-outlined flight">flight</span>
          </div>
          <div className={"flight-line"}></div>
          <div
              style={{
                width: 11,
                height: 11,
                borderRadius: 5,
                borderColor: "rgba(20,30,46, 1)",
                borderWidth: 1,
                borderStyle: "solid",
              }}
          ></div>
          <div className={"flight-line"}></div>
          <div style={{marginLeft: 5, marginRight: 5, marginTop: 3}}>
            <span className="material-symbols-outlined flight">flight</span>
          </div>

          <div className={"flight-line"}></div>
          <div
              style={{
                width: 11,
                height: 11,
                borderRadius: 5,
                borderColor: "rgba(20,30,46, 1)",
                borderWidth: 1,
                borderStyle: "solid",
              }}
          ></div>
        </div>
      </div>
  );
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DestinationsBoxForManager);
