const newPlaneMarker = {
    'id': 'PlaneMarker',
    'markerExists': false,
    'properties': {
        'iconSize' : 10,
        'message': 'Text',
        'name': '',
        'markerOffset': [0, 0],
        'class': 'plane-marker'
    },
    'geometry': {
        'type': "Feature",
        'coordinates': [
            36,
            39
        ]
    }
};

const selectedPlaneMarker = {};
const destinationMarker = {
    'id': 'DestinationMarker',
    'properties': {
        'iconSize' : 12,
        'offset': 12/2,
        'message': 'Text',
        'name': '',
        'class': 'destination-marker'
    },
    'geometry': {
        'type': "Feature",
        'coordinates': [
            36,
            39
        ]
    }
};

const transferPointMarker = {
    id: 'TransferPointMarker',
    properties: {
        'iconSize' : 12,
        'offset': 0,
        'message': 'Text',
        'name': '',
        'class': 'transfer-marker'
    },
    geometry: {
        'type': "Feature",
        'coordinates': [
            36,
            39
        ]
    }
}

const selectedDestinationMarker = {
    'id': 'SelectedDestinationMarker',
    'properties': {
        'iconSize' : 12,
        'offset': 0,
        'message': 'Text',
        'name': '',
        'class': 'selected-destination-marker'
    },
    'geometry': {
        'type': "Feature",
        'coordinates': [
            36,
            39
        ]
    }
};

const istanbulMarker = {
    'id': 'SelectedDestinationMarker',
    'properties': {
        'iconSize' : 12,
        'offset': 0,
        'message': 'Text',
        'name': '',
        'class': 'istanbul-marker'
    },
    'geometry': {
        'type': "Feature",
        'coordinates': [
            36,
            39
        ]
    }
};
const offerMarker =  {
    'id': 'OfferMarker',
    'properties': {
        'iconSize' : 21,
        'offset': 0,
        'message': 'Text',
        'name': '',
        'class': 'offer-marker'
    },
    'geometry': {
        'type': "Feature",
        'coordinates': [
            36,
            39
        ]
    }
};;
const selectedOfferMarker = {};


export { newPlaneMarker, selectedPlaneMarker, destinationMarker, selectedDestinationMarker, istanbulMarker, offerMarker, selectedOfferMarker, transferPointMarker };