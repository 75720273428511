import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate, t } from 'react-i18nify';
import './Offers.css';
import OfferHilite from './OfferHilite';
import OfferPopup from './OfferPopup';
import ReactGA from 'react-ga';
import ProfileContext from "config/ProfileContext";


class Offers extends Component {

  constructor(props) {
    super(props);
    this.position = {
      top: 100,
      left:100
    };
    this.display = 'none';
    this.offer = null;
    this.offerData = null;
    this.closeOfferDetails = this.closeOfferDetails.bind(this);
    this.showOfferDetails = this.showOfferDetails.bind(this);
    this.state = {
        displayOffer: null,
        position: null,
        animated: 0,
        profileType: this.context,
    };
    this.selectedRegion = 'all';
    this.offerIds = [];
    this.animationPosition = 0;
    this.showAnimation = true;
    this.mapOnClick = 0;
    this.activeTab = 'live-flights';
  }

  componentDidUpdate() {
    if (this.state.animated === 0){
      window.setTimeout(function(){this.animateOffers();}.bind(this), 500);
    }
    if (this.mapOnClick !== this.props.onMapClick) {
      this.showAnimation = false;
      this.mapOnClick = this.props.onMapClick;
      this.closeOfferDetails();
    }

    //this.offer = this.props.activeOffer;
  }

  animateOffers() {
    if (this.props.activeTab === 'offers') {
      this.setState({
        animated: 1
      });
      for (let offerCode in this.props.offers) {
        this.offerIds.push(offerCode);
      }
      window.setTimeout(function(){this.showNext(0);}.bind(this), 1500);
    }
  }

  showNext(position) {
    if (this.props.activeTab !== 'offers' || this.showAnimation !== true){
      window.clearTimeout(this.animationTimeOut);
      return false;
    }
    const offerCode = this.offerIds[position];
    const offerElement = document.getElementById(offerCode);
    if (offerElement === null) {
      window.clearTimeout(this.animationTimeOut);
      return false;
    }
    const elementPosition = offerElement.getBoundingClientRect();
    var event = new MouseEvent('click', {
      x: elementPosition.x,
      y: elementPosition.y
    });
    offerElement.dispatchEvent(event);
    this.animationTimeOut = window.setTimeout(function(){
      const newPosition = position + 1;
      if (newPosition+1 <= this.offerIds.length){
        if (this.props.activeTab === 'offers' && this.showAnimation === true){
          this.showNext(newPosition);
        }
      }
    }.bind(this), 2000);
  }

  selectRegionStandard(region) {
    let selectedRegions = [region.region];
    if (region === 'all' || region.region === 'all') {
      selectedRegions = 'all';
    }
    this.props.setRegion(selectedRegions.length === 0 ? 'all' : selectedRegions);
    ReactGA.event({
      category: 'Offers',
      action: 'change_region',
      label: region.region
    });
  }

  selectRegionSpecial(region) {

    let selectedRegions = this.props.selectedRegion === 'all' ? [] : Object.assign([], this.props.selectedRegion);
    if (region === 'all' || region.region === 'all') {
      selectedRegions = 'all';
    }
    else if (selectedRegions !== 'all' && selectedRegions.includes(region.region)) {
      selectedRegions = selectedRegions.filter(function(value, index, arr){
        return value !== region.region;
      });
    } else {
      selectedRegions.push(region.region);
    }
    if (region === 'all' || region.region === 'all') {
      selectedRegions = 'all';
    }
    this.props.setRegion(selectedRegions.length === 0 ? 'all' : selectedRegions);
    ReactGA.event({
      category: 'Offers',
      action: 'change_region',
      label: region.region
    });
  }

  showOfferDetails(e, offerCode){

    this.showAnimation = false;
    window.clearTimeout(this.animationTimeOut);
    this.setState(
      {
        displayOffer: offerCode,
        position: e.target.getBoundingClientRect()
      }
    );
  }

  closeOfferDetails() {
    this.setState({
      displayOffer: null,
      position: null
    });
  }
  componentDidMount() {
    this.setState({profileType: this.context});
  }
  getRegionButtonsForSpecialProfile (regions) {
    const regionsChildren = [];
    for (var i = 0; i < regions.length; i += 1) {
      let region = regions[i];
      regionsChildren.push(<li key={region.region} onClick={() => this.selectRegionSpecial(region)} className={"region-options"}>
        <a className={  'offers-region-btn ft-btn' }>

          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>

            <div style={{marginLeft: 14, marginRight: 10, width: 18, height:18, border: '2px solid red', borderRadius: 3}}>
              {(this.props.selectedRegion === 'all' || this.props.selectedRegion.includes(region.region)) && (<div style={{margin:3, backgroundColor: 'red', width: 8, height: 8}}></div>)}
            </div>
            <span style={{flexGrow: 1}}>{region.regionName}</span>
          </div>
        </a>
      </li>);
    };

    return regionsChildren;
  }

  getRegionButtonsForStandardProfile (regions) {
    const regionsChildren = [];
    for (var i = 0; i < regions.length; i += 1) {
      let region = regions[i];
      regionsChildren.push(<li key={region.region} onClick={() => this.selectRegionStandard(region)} className={"region-options"}>
        <a className={ (this.props.selectedRegion.includes(region.region)) ? 'offers-region-btn ft-btn color-red' : 'offers-region-btn ft-btn' }>
          {region.regionName}
        </a>
      </li>);
    };

    return regionsChildren;
  }
  render() {

    let regionsChildren = [];
    const offerRegions = [];
    if (Object.keys(this.props.offers).length > 0) {
      const destinations = this.props.destinations;
      let regionsChildren = [];
      const offerRegionsTmp = {};
      const regions = destinations[this.props.language].regions;
      for (let offerCode in this.props.offers) {
        let offer = this.props.offers[offerCode];
        let regionName = t(offer.region);
        offerRegionsTmp[offer.region] = 1;
      }
      let j = 0;
      for (j=0; j < regions.length; j++) {
        for (let regionNameTmp in offerRegionsTmp) {
          if (regions[j].region === regionNameTmp) {
            offerRegions.push(regions[j]);
          }
        }
      }
    }
    regionsChildren = this.state.profileType === 'standard' ? this.getRegionButtonsForStandardProfile(offerRegions)
      : this.getRegionButtonsForSpecialProfile(offerRegions);

    let offerHilite = '';
    let offerPopup = '';

    if (this.props.selectedRegion !== this.selectedRegion) {
      this.setState({
        displayOffer: null
      });
      this.props.setActiveOffer(null);
      window.clearTimeout(this.animationTimeOut);
    }

    if (this.state.displayOffer !== null && this.props.selectedRegion === this.selectedRegion && this.activeOffer === this.props.activeOffer) {
      this.showAnimation = false;
      offerPopup = <OfferPopup position={this.state.position} display='block' data={this.props.offers[this.state.displayOffer]} closeOfferDetails={this.closeOfferDetails}/>;
      window.clearTimeout(this.animationTimeOut);
    }

    if (this.offer !== this.props.activeOffer && this.props.selectedRegion === this.selectedRegion) {
      this.position = this.props.activeOffer.position;
      this.activeOffer = this.props.activeOffer;
      this.display = 'block';
      this.offerData = this.props.offers[this.props.activeOffer.id];
      offerHilite = <OfferHilite position={this.position} display={this.display} data={this.offerData} showOfferDetails={this.showOfferDetails}  closeOfferDetails={this.closeOfferDetails}/>;
    } else {
      this.position = null;
      this.display = 'none';
    }

    if (this.props.selectedRegion !== this.selectedRegion) {
      this.selectedRegion = this.props.selectedRegion;
    }

    return (
      <div id="OffersContainer" className={this.props.activeTab === 'offers' ? '' : 'hidden'}>
        <div className="ft-continent-list zIndexTop">
            <ul id="offers-regions">
              {this.state.profileType === 'standard' && <li onClick={this.selectRegionStandard.bind(this, {region: 'all', regionName: 'ALL'})}><a className={ this.props.selectedRegion === 'all' ? 'offers-region-btn ft-btn color-red' : 'offers-region-btn ft-btn' }><Translate value="application.ALL" /></a></li>}

              {regionsChildren}

              {this.state.profileType === 'special' &&  this.props.selectedRegion !== 'all' && <li onClick={() => this.selectRegionSpecial({region: 'all', regionName: 'ALL'})}>
                <a className={ 'offers-region-btn ft-btn color-dark'  }><Translate value="application.RESET_FILTERS" /></a>
              </li>}
            </ul>
        </div>
        {offerHilite}
        {offerPopup}
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    activeTab: state.app.activeTab,
    language: state.app.language,
    destinations: state.data.destinations,
    offers: state.data.offers,
    onMapClick: state.app.onMapClick,
    selectedRegion: state.app.activeOffersRegion,
    activeOffer: state.app.activeOffer
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setRegion: (region) => {
      dispatch({
        type: 'SET_OFFERS_REGION',
        value: region
      });
    },

    setActiveOffer: (data) => {
      dispatch({
        type: 'SET_ACTIVE_OFFER',
        value: data
      });
    },
  }
}

Offers.contextType = ProfileContext;

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
