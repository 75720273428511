import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate, t} from 'react-i18nify';
import { Calculations, Strings, Routes, Frequencies, FlightTimes } from 'helpers';
import DestinationsTooltip from './DestinationTooltip';
import { transformRotate } from '@turf/turf';
import {transferredFlights, transferredFlightsPairing} from 'config';



class DestinationsBox extends Component {

  constructor(props) {
    super(props);
    this.selectedRouteFromCityFrequency = [0, 0, 0, 0, 0, 0, 0];
    this.selectedRouteToCityFrequency = [0, 0, 0, 0, 0, 0, 0];
    this.fromCityIata = 'IST';
    this.toCityIata = null;

    this.state = {
      tooltipData: {

        display: 'none',
        position: {
          left: 0,
          top: 0
        }
      },
      tooltipType: 'to',
      cityData: null,
      searchResultsForToCity: null,
      searchToCityFirstResult: null,
      searchResultsForFromCity: null,
      searchFromCityFirstResult: null
    };
    this.changeDisplay = this.changeDisplay.bind(this);
    this.changeTooltipType = this.changeTooltipType.bind(this);
    this.activeTooltipType = null;
    this.activeTooltip = 0;
    this.searchToCityFirstResult = null;
    this.formToOnWriting = false;
    this.formFromOnWriting = false;
    this.tooltipCity = null;
    this.transferredFlight = false;
  }


  componentDidCatch(error, info) {

    console.error(error, info);
  }

  componentDidUpdate() {
    if (this.props.fromCity !== null) {
      if (this.state.searchResultsForFromCity === null  && this.formFromOnWriting === false) {
        document.getElementById('destination-text-from').value = t(this.props.fromCity.cityName);
      }
    } else{
      const cityData = this.props.destinations[this.props.language].activeAirports.IST;
      if (this.state.searchResultsForFromCity === null) {
        this.props.setFormCity('SET_FROM_CITY', cityData);
        document.getElementById('destination-text-from').value = '';
      }
    }
    if (this.props.toCity !== null && this.formToOnWriting === false) {
      document.getElementById('destination-text-to').value = t(this.props.toCity.cityName);
    } else {
      if (this.state.searchResultsForToCity === null) {
        this.props.setFormCity('SET_TO_CITY', null);
        document.getElementById('destination-text-to').value = '';
      }
    }
  }

  changeDisplay() {
    this.props.changeDisplay();
  }
  changeTooltipType(type)  {
    this.props.changeTooltipType(type);
  }

  componentDidMount() {
    document.getElementById('destination-text-from').value = t('İstanbul');
  }
  searchDestinationsForFrom(e) {

    let target = "destinations-form-from";
    let position = e.target.getBoundingClientRect();
    let bottomPosition = window.innerHeight-position.top + 30;
    document.getElementById(target).style.bottom = bottomPosition + 'px';
    var charCode = (typeof e.which === "number") ? e.which : e.keyCode;
    if (charCode === 13 && this.state.searchFromCityFirstResult !== null) {
      this.formFromOnWriting = false;
      document.getElementById('destinations-form-from').style.display = 'none';
      if ((this.props.selectedRegion !== this.props.destinations[this.props.language].activeAirports[this.state.searchFromCityFirstResult].region)) {
        this.props.setRegion('all');
        window.setTimeout(function(){
          this.setCity(this.state.searchFromCityFirstResult, document.getElementById(this.state.searchFromCityFirstResult));
        }.bind(this), 200);
        return true;
      }
      return true;
    }
    this.formFromOnWriting = true;
    document.getElementById('destinations-form-from').style.display = 'block';

    const val = document.getElementById('destination-text-from').value;
    const needle = Strings.removeDiacritics(val);
    const searchResults = this.needleInTheHaystack(needle);
    let searchResult = [];
    if ( val === '') {
      this.setState({
        searchFromCityFirstResult: null
      });
      this.setState({
        searchResultsForFromCity: null
      });
      return false;
    }

    if (Object.keys(searchResults).length === 0) {
      searchResult.push(<li key="no-res" className="search-alert">{t('destinations.search_no_result')}</li>);
      this.setState({
        searchFromCityFirstResult: null
      });

    } else {
      searchResult = [];

      let counter = 0;
      for (let airpotIata in searchResults) {
        let city = searchResults[airpotIata];
        if (counter === 0) {
          this.setState({
            searchFromCityFirstResult: city.cityIata
          });
        }
        searchResult.push(<li key={city.iata}><a  data-iata={city.iata} onClick={this.selectCity.bind(this)}>{city.name} ({city.iata})</a></li>);
        counter++;
      }
    }
    this.setState({
      searchResultsForFromCity: searchResult
    });
  }

  searchDestinationsForTo(e) {
    this.formToOnWriting = true;
    let target = "destinations-form-to";
    let position = e.target.getBoundingClientRect();
    let bottomPosition = window.innerHeight-position.top+30;
    document.getElementById(target).style.bottom = bottomPosition+'px';

    console.log(bottomPosition, bottomPosition, bottomPosition);

    var charCode = (typeof e.which === "number") ? e.which : e.keyCode;
    if (charCode == 13 && this.state.searchToCityFirstResult !== null) {
      this.formToOnWriting = false;
      document.getElementById('destinations-form-to').style.display = 'none';
      if ((this.props.selectedRegion !== this.props.destinations[this.props.language].activeAirports[this.state.searchToCityFirstResult].region)) {
        this.props.setRegion('all');
        window.setTimeout(function(){
          this.setCity(this.state.searchToCityFirstResult, document.getElementById(this.state.searchToCityFirstResult));
        }.bind(this), 200);
        return true;
      }
      this.setCity(this.state.searchToCityFirstResult, document.getElementById(this.state.searchToCityFirstResult));
      return true;
    }
    this.formToOnWriting = true;
    document.getElementById('destinations-form-to').style.display = 'block';
    const val = document.getElementById('destination-text-to').value;
    const needle = Strings.removeDiacritics(val);
    const searchResults = this.needleInTheHaystack(needle);

    let searchResult = [];
    if ( val === '') {
      this.setState({
        searchToCityFirstResult: null
      });
      this.setState({
        searchResultsForToCity: null
      });
      return false;
    }

    if (Object.keys(searchResults).length === 0) {
      this.searchToCityFirstResult = null;
      searchResult.push(<li key="nor-res" className="search-alert">{t('destinations.search_no_result')}</li>);
      this.setState({
        searchToCityFirstResult: null
      });

    } else {
      searchResult = [];
      let counter = 0;
      for (let airpotIata in searchResults) {
        let city = searchResults[airpotIata];
        if (counter === 0) {
          this.setState({
            searchToCityFirstResult: city.cityIata
          });
          this.searchToCityFirstResult = city.cityIata;
        }
        searchResult.push(<li key={city.iata} ><a data-iata={city.iata}  onClick={this.selectCity.bind(this)} >{city.name} ({city.iata})</a></li>);
        counter++;
      }
    }

    this.setState({
      searchResultsForToCity: searchResult
    });
  }

  hello() {
    console.log("Hello, i am DestinationsBox");
  }

  selectCity(e) {
    e.preventDefault();
    const element = e.target;

    const iataCode = element.getAttribute('data-iata');
    if ((this.props.selectedRegion !== this.props.destinations[this.props.language].activeAirports[iataCode].region)) {
      this.props.setRegion('all');
      window.setTimeout(function(){
        const destinationElement = document.getElementById(iataCode);
        this.setCity(iataCode, destinationElement);
      }.bind(this), 100);
      return false;
    }

    const destinationElement = document.getElementById(iataCode);
    this.setCity(iataCode, destinationElement);
  }

  setCity(iataCode, element) {
    if (this.props.formFocus === 'from') {

      if (this.props.toCity !== null && this.props.toCity.cityIata === iataCode) {
        return;
      }
      this.setState({
        searchFromCityFirstResult: null
      });
      this.setState({
        searchResultsForFromCity: null
      });
    } else {
      if (this.props.fromCity !== null && this.props.fromCity.cityIata === iataCode) {
        return;
      }
      this.setState({
        searchToCityFirstResult: null
      });
      this.setState({
        searchResultsForToCity: null
      });
    }

    const elementPosition = element.getBoundingClientRect();
    var event = new MouseEvent('click', {
      x: elementPosition.x,
      y: elementPosition.y
    });
    element.dispatchEvent(event);
  }

  needleInTheHaystack(needle) {
    const searchResults = {};
    let nofResults = 0;

    for (let haystack in this.props.searchIndex[this.props.language]) {
      let item = this.props.searchIndex[this.props.language][haystack];
      if (nofResults > 10) {
        break;
      }
      if ((haystack.search(new RegExp(needle, "i")) !== -1 || item.cityNameIndex.search(new RegExp(needle, "i")) !== -1) && needle !== ''){
        nofResults++;
        searchResults[haystack] = item;
      }
    }
    return this.ksort(searchResults);
  }

  ksort(obj){
    var keys = Object.keys(obj).sort()
      , sortedObj = {};

    for(var i in keys) {
      sortedObj[keys[i]] = obj[keys[i]];
    }

    return sortedObj;
  }

  checkCityIata(cityData) {
    if (cityData === null) {
      return '';
    }
    return cityData.airportIata;
  }

  clearFrom() {
    document.getElementById('destination-text-from').focus();
    const cityData = this.props.destinations[this.props.language].activeAirports.IST;

    if (this.props.toCity !== null && this.props.toCity.cityIata !== 'IST') {

    this.props.setFormCity('SET_FROM_CITY', cityData);
    this.props.setFrequency('SET_FROM_CITY_ROUTE_FREQUENCY', null);
    }
    window.setTimeout(function() {
      const element = document.getElementById('IST');
      const elementPosition = element.getBoundingClientRect();
      var event = new MouseEvent('click', {
        x: elementPosition.x,
        y: elementPosition.y
      });
      element.dispatchEvent(event);
      document.getElementById('destination-text-from').value = '';
      document.getElementById('destinations-form-from').style.display = 'none';
    }, 300);
    return false;
  }

  clearTo() {
    document.getElementById('destination-text-to').focus();
    let toCity = null;
    this.props.setFormCity('SET_TO_CITY', null);
    this.props.setFrequency('SET_FROM_CITY_ROUTE_FREQUENCY', null);
    document.getElementById('destination-text-to').value = '';
    document.getElementById('destinations-form-to').style.display = 'none';
    return false;
  }


  showTooltipFromCity(e, fromCity) {

    const bottom  = e.pageY;
    if (this.props.showTooltip === 1 && this.activeTooltipType !== 'from') {
      this.props.changeDisplay();
    }
    window.setTimeout(function(){
      this.activeTooltipType = 'from';
       this.setState({
        tooltipData: {
  
          display: 'block',
          position: {
            left: 310,
            bottom: bottom
          }
        },
        tooltipType : 'from',
        cityData: fromCity
      });

      this.props.changeDisplay();
    }.bind(this), 10);


  }


  showTooltipToCity(e, toCity) {
    const bottom  = e.pageY;

    if (this.props.showTooltip === 1 && this.activeTooltipType !== 'to') {
      this.props.changeDisplay();

    }
    window.setTimeout(function(){
      this.activeTooltipType = 'to';
      this.setState({
        tooltipData: {
  
          display: 'block',
          position: {
            left: 310,
            bottom: bottom
          }
        },
        tooltipType : 'to',
        cityData: toCity
      });

      this.props.changeDisplay();
    }.bind(this), 10);
  }

  setFocus(e, type) {
    let target = "destinations-form-"+type;
    let position = e.target.getBoundingClientRect();
    let bottomPosition = window.innerHeight-position.top+30;
    document.getElementById(target).style.bottom = bottomPosition+'px';

    this.props.setFocus(type);
  }

  setOnBlur(type) {
    this.formToOnWriting = false;
    this.formFromOnWriting = false;
    let target = "destinations-form-"+type;
    window.setTimeout(function(){
        const el = document.getElementById(target);
        if (el !== null) {
          el.style.display = 'none';
        }
        let cityName = '';

        if (type === 'from'  && this.props.fromCity !== null) {
          cityName = this.props.fromCity.cityName;
        }
        if (type === 'to' && this.props.toCity !== null) {
          cityName = this.props.toCity.cityName;
        }
        const element = document.getElementById('destination-text-'+type);
        if (element !== null) {
          element.value = cityName;
        }


    }.bind(this), 200);

  }

  closeBox() {
    document.getElementById('toggleBox').classList.remove('open');
    document.getElementById('search-box').style.display = 'none';
    document.getElementById('result-box').style.display = 'none';
  }

  openBox() {
    document.getElementById('toggleBox').classList.add('open');
    document.getElementById('search-box').style.display = 'block';
    document.getElementById('result-box').style.display = 'block';
  }

  setRouteAndFlightTimes() {
    if (this.props.fromCity !== null && this.props.toCity !== null) {
      const fromIata = this.props.fromCity.airportIata;
      const toIata = this.props.toCity.airportIata;
    }
  }


  getFrequency(frequencyString) {

    if (typeof frequencyString === 'undefined') {
      return [0, 0, 0, 0, 0, 0, 0];
    }
    let frequencyArray = frequencyString.split(',');
    for (let i = 0; i < frequencyArray.length; i++) {
      if (frequencyArray[i] === '.') {
        frequencyArray[i] = 0;
      }
      frequencyArray[i] = parseInt(frequencyArray[i], 10);
    }

    return frequencyArray;
  }



  add(a, b) {
    return a + b;
  }
  render() {
    const istanbul = t('İstanbul');
    let fromCityFormValue = istanbul;
    let toCityFormValue = '';

    let isFromIstanbul = 0;
    let isToIstanbul = 0;
    let showTwoLeggedFlights = 0;
    let tooltipType = 'from';

    if (this.props.fromCity !== null && this.props.fromCity.cityIata === 'IST') {
      isFromIstanbul = 1;
      this.fromCityIata = 'IST';
    } else if (this.props.fromCity !== null && this.props.fromCity.cityIata !== 'IST') {
      this.fromCityIata = this.props.fromCity.cityIata;
    }




    if (this.props.toCity !== null && this.props.toCity.cityIata === 'IST') {
      isToIstanbul = 1;
      this.toCityIata = 'IST';
      tooltipType = 'to';
    } else if (this.props.toCity !== null && this.props.toCity.cityIata !== 'IST') {
      this.toCityIata = this.props.toCity.cityIata;
      tooltipType = 'to';
    }
    const tooltipData = this.state.tooltipData;

    let cityData = this.props.fromCity;



    if (this.props.formFocus === 'to') {
      cityData = this.props.toCity;
    }

    if (this.state.tooltipData.display === 'block') {
      tooltipType = this.state.tooltipType;
    }

    let destinationTooltip = '';
    if (this.props.showTooltip === 1) {
      destinationTooltip = < DestinationsTooltip tooltipData = {
        tooltipData
      }
      tooltipType = {
        tooltipType
      }
      cityData = {
        cityData
      }
      changeDisplay = {
        this.changeDisplay
      }
      type="standard"
      />
    }

    this.selectedRouteFromCityFrequency = [0,0,0,0,0,0,0,0];
    this.selectedRouteToCityFrequency = [0,0,0,0,0,0,0,0];

    const routes = new Routes(
      this.props.frequencies,
      this.props.fromCity,
      this.props.toCity
    );

    const points = routes.getRoutes();
    let transferPointIataCode = Routes.getTransferPoint(points);
    const flightTimes = new FlightTimes(this.props.flightTimes, points, transferPointIataCode);
    const flightFrequencies = new Frequencies(this.props.frequencies, points);
    const routeFlightTimes = flightTimes.getFlightTimes();
    const routeFrequencies = flightFrequencies.getFrequencies();
    if (points.length > 2) {
      showTwoLeggedFlights = 1;
    }

    let transferPoint = this.props.destinations[this.props.language].activeAirports[transferPointIataCode];
    let totalFlightTimeFromTransferPoint = routeFlightTimes.totalFlightFromTransferPoint !== '' ? routeFlightTimes.totalFlightFromTransferPoint : 0;
    let totalFlightTimeToTransferPoint = routeFlightTimes.totalFlightToTransferPoint !== '' ? routeFlightTimes.totalFlightToTransferPoint : 0;
    let totalFlightTime = routeFlightTimes.totalFlightTime !== '' ? routeFlightTimes.totalFlightTime : 0;

    if (points.length > 2){
      this.selectedRouteFromCityFrequency = this.getFrequency(routeFrequencies[0]);
    }
    if (transferPointIataCode === 'IST' && points.length === 2) {
      this.selectedRouteToCityFrequency = this.getFrequency(routeFrequencies[0]);
    }
    if (transferPointIataCode === 'IST' && points.length === 3) {
      this.selectedRouteToCityFrequency = this.getFrequency(routeFrequencies[1]);
    }
    if (transferPointIataCode === 'IST' && points.length === 4) {
      this.selectedRouteToCityFrequency = this.getFrequency(routeFrequencies[routeFrequencies.length-1]);
    }
    if (transferPointIataCode === 'IST' && points.length > 4) {
      this.selectedRouteToCityFrequency = this.getFrequency(routeFrequencies[routeFrequencies.length-2]);
    }
    if (transferPointIataCode !== 'IST') {
      let pos = routeFrequencies.length-1;
      this.selectedRouteToCityFrequency = this.getFrequency(routeFrequencies[pos]);
    }


    let fromCityFrequencyStyle = {display: 'block'};
    let fromCityNoFlight = {display: 'none'};

    let toCityFrequencyStyle = {display: 'block'};
    let toCityNoFlight = {display: 'none'};

    if (totalFlightTimeToTransferPoint === 0) {
      fromCityNoFlight = {display: 'block'};
      fromCityFrequencyStyle = {display: 'none'};
    }
    if (totalFlightTimeFromTransferPoint === 0) {
      toCityNoFlight = {display: 'block'};
      toCityFrequencyStyle = {display: 'none'};
    }
    if (points.length ===2) {
      fromCityNoFlight = {display: 'none'};
      fromCityFrequencyStyle = {display: 'none'};
    }

    if (points.length < 2) {
      toCityNoFlight = {display: 'none'};
      toCityFrequencyStyle = {display: 'none'};

      fromCityNoFlight = {display: 'none'};
      fromCityFrequencyStyle = {display: 'none'};
    }

    return (

      <div className="ft-search-box-wrapper zIndexTop" style={{width: '290px', position: 'absolute', bottom:0, left:0}}>
          <a  className="ft-toggle-searchbox open" id="toggleBox">
              <span className="ft-toggle-searchbox__label"><Translate value="destinations.choose_destination" /></span>
              <span id="open-box-button" className="ft-toggle-searchbox__plus"  onClick={() => this.openBox()}>+</span>
              <span id="close-box-button" className="ft-toggle-searchbox__minus" onClick={() => this.closeBox()}>&ndash;</span>
          </a>
          <div style={{display : 'block'}} className="ft-search-box" id= "search-box" >
              <form onSubmit={() => {return false;}} autoComplete="off">
                  <div className="form-group form-from">
                      <label><Translate value="destinations.from" /></label>
                      <input type="text" required
                      className={this.props.formFocus === 'from' ? "form-control selected": "form-control"}
                      name="text-from"
                      id="destination-text-from"
                      defaultValue={this.props.fromCity !== null ? this.props.fromCity.cityName: ''}
                      onKeyUp={(e) => this.searchDestinationsForFrom(e)}
                      onFocus={(e)=>this.setFocus(e, 'from')}
                      onBlur={(e)=>this.setOnBlur('from')}
                  />
                      <a type="submit" className="ft-clear-input" onClick={() => this.clearFrom()}></a>
                  </div>
                  <div id="destinations-form-from" className={this.state.searchFromCityFirstResult === null ? 'search-alert search-results-popup': ' search-results-popup'} style={this.state.searchResultsForFromCity === null ? {display:'none'}: {display:'block'}}><ul>{this.state.searchResultsForFromCity}</ul></div>
                  <div className="form-group  form-to">
                      <label><Translate value="destinations.to" /></label>
                      <input type="text"
                        className={this.props.formFocus === 'to' ? "form-control selected": "form-control"}
                        name="text-to"
                        id="destination-text-to"
                        onKeyUp={(e) => this.searchDestinationsForTo(e)}
                        onFocus={(e) => this.setFocus(e, 'to')}
                        onBlur={(e) => this.setOnBlur('to')}
                        />
                      <a type="submit" className="ft-clear-input"  onClick={() => this.clearTo()}></a>
                  </div>
                  <div id="destinations-form-to" className={this.state.searchToCityFirstResult === null ? 'search-alert search-results-popup': ' search-results-popup'} style={this.state.searchResultsForToCity === null ? {display:'none'}: {display:'block'}}><ul>{this.state.searchResultsForToCity}</ul></div>

              </form>
          </div>
          <div className="ft-flightsearch-results" id="result-box">
              <div className="ft-flightsearch-results-item" id="destination-search-from" style={this.props.fromCity !== null  ? {display: 'block'} : {display: 'none'}}>
                  <div className="ft-destination-flight-timetable">
                      <div className="header">
                          <h4>{this.props.fromCity !== null ? t(this.props.fromCity.cityName): ''}, {this.props.fromCity !== null ? t(this.props.fromCity.country): ''} <small>{this.props.fromCity !== null ? '(' + this.props.fromCity.airportIata + ')': ''}</small> </h4>

                        {this.props.fromCity && this.props.fromCity.showDetails === 1 && <a className="show-tooltip" data-type="from" onClick={(e) => {this.showTooltipFromCity(e, this.props.fromCity)} }><i className="icon-dots"></i></a>}
                          <div style={this.fromCityIata !== 'IST' ? {display: 'block'} : {display: 'none'}}>
                            <div className="no-flight-alert" style={fromCityNoFlight}>
                                <span className="ft-flight-duration-label"><Translate value="destinations.no_flights" /></span>
                            </div>
                          </div>
                          <div style={fromCityFrequencyStyle}>
                            <div className="ft-flight-duration-label-2" style={this.checkCityIata(this.props.fromCity) !== transferPoint.airportIata ? {display: 'block'} : {display: 'none'}}>
                              <span className="ft-flight-duration-label ft-flight-duration-label-2"><Translate value="destinations.flight_duration" /></span>
                              <span className="flight-time-span"><strong><i className="ft-icon-clock"></i> <span className="flight-time">{this.props.fromCity !== null ? totalFlightTimeToTransferPoint : ''}</span></strong></span>
                            </div>
                          </div>
                      </div>
                      <div className="ft-flight-timetable-wrapper"  style={fromCityFrequencyStyle} >
                          <table className="ft-flight-timetable">
                          <tbody>
                              <tr>
                                  <th colSpan="7"><Translate value="destinations.flight_frequency" /></th>
                              </tr>
                              <tr className="frequency">
                                  <td><Translate value="date.MON" /><br/><strong className="day-0">{this.selectedRouteFromCityFrequency[0] !== 0 ? this.selectedRouteFromCityFrequency[0]+'x' : '-'}</strong></td>
                                  <td><Translate value="date.TUE" /><br/><strong className="day-1">{this.selectedRouteFromCityFrequency[1] !== 0 ? this.selectedRouteFromCityFrequency[1]+'x' : '-'}</strong></td>
                                  <td><Translate value="date.WED" /><br/><strong className="day-2">{this.selectedRouteFromCityFrequency[2] !== 0 ? this.selectedRouteFromCityFrequency[2]+'x' : '-'}</strong></td>
                                  <td><Translate value="date.THU" /><br/><strong className="day-3">{this.selectedRouteFromCityFrequency[3] !== 0 ? this.selectedRouteFromCityFrequency[3]+'x' : '-'}</strong></td>
                                  <td><Translate value="date.FRI" /><br/><strong className="day-4">{this.selectedRouteFromCityFrequency[4] !== 0 ? this.selectedRouteFromCityFrequency[4]+'x' : '-'}</strong></td>
                                  <td><Translate value="date.SAT" /><br/><strong className="day-5">{this.selectedRouteFromCityFrequency[5] !== 0 ? this.selectedRouteFromCityFrequency[5]+'x' : '-'}</strong></td>
                                  <td><Translate value="date.SUN" /><br/><strong className="day-6">{this.selectedRouteFromCityFrequency[6] !== 0 ? this.selectedRouteFromCityFrequency[6]+'x' : '-'}</strong></td>
                              </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
              <div className="ft-flightsearch-results-transfer" id="destination-search-transfer" style={showTwoLeggedFlights === 1  ? {display: 'block'} : {display: 'none'}}>
                  <h4><Translate value={transferPoint.cityName} />, <Translate value={transferPoint.country}  /> <span>(<Translate value="destinations.transfer_point" />)</span></h4>
              </div>
              <div className="ft-flightsearch-results-item"  id="destination-search-to" style={this.props.toCity !== null  ? {display: 'block'} : {display: 'none'}}>
                  <div className="ft-destination-flight-timetable">
                      <div className="header">
                          <h4>
                            {this.props.toCity !== null ? t(this.props.toCity.cityName): ''}, {this.props.toCity !== null ? t(this.props.toCity.country) : ''} <small>{this.props.toCity !== null ? '(' + this.props.toCity.airportIata + ')': ''}</small></h4>
                        {this.props.toCity && this.props.toCity.showDetails === 1 && <a className="show-tooltip" data-type="to" onClick={(e) => {this.showTooltipToCity(e, this.props.toCity)} }><i className="icon-dots"></i></a>}

                          <div style={this.toCityIata !== 'IST' ? {display: 'block'} : {display: 'none'}}>
                            <div className="no-flight-alert" style={toCityNoFlight}>
                                <Translate value="destinations.no_flights" className="ft-flight-duration-label" />
                            </div>
                          </div>
                          <div className="ft-flight-duration-label-2" style={toCityFrequencyStyle}>
                              <span className="ft-flight-duration-label "><Translate value="destinations.flight_duration" /> </span>
                              <strong><i className="ft-icon-clock"></i> <span className="flight-time">{this.props.toCity !== null ? totalFlightTimeFromTransferPoint : ''}</span></strong>
                          </div>
                      </div>
                      <div className="ft-flight-timetable-wrapper" style={toCityFrequencyStyle}>
                          <table className="ft-flight-timetable">
                          <tbody>
                              <tr>
                              <th colSpan="7"><Translate value="destinations.flight_frequency" /></th>
                              </tr>
                              <tr className="frequency">
                                  <td><Translate value="date.MON" /><br/><strong className="day-0">{this.selectedRouteToCityFrequency[0] !== 0 ? this.selectedRouteToCityFrequency[0]+'x' : '-'}</strong></td>
                                  <td><Translate value="date.TUE" /><br/><strong className="day-1">{this.selectedRouteToCityFrequency[1] !== 0 ? this.selectedRouteToCityFrequency[1]+'x' : '-'}</strong></td>
                                  <td><Translate value="date.WED" /><br/><strong className="day-2">{this.selectedRouteToCityFrequency[2] !== 0 ? this.selectedRouteToCityFrequency[2]+'x' : '-'}</strong></td>
                                  <td><Translate value="date.THU" /><br/><strong className="day-3">{this.selectedRouteToCityFrequency[3] !== 0 ? this.selectedRouteToCityFrequency[3]+'x' : '-'}</strong></td>
                                  <td><Translate value="date.FRI" /><br/><strong className="day-4">{this.selectedRouteToCityFrequency[4] !== 0 ? this.selectedRouteToCityFrequency[4]+'x' : '-'}</strong></td>
                                  <td><Translate value="date.SAT" /><br/><strong className="day-5">{this.selectedRouteToCityFrequency[5] !== 0 ? this.selectedRouteToCityFrequency[5]+'x' : '-'}</strong></td>
                                  <td><Translate value="date.SUN" /><br/><strong className="day-6">{this.selectedRouteToCityFrequency[6] !== 0 ? this.selectedRouteToCityFrequency[6]+'x' : '-'}</strong></td>
                              </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
              <div className="ft-flightsearch-results-total"  id="destination-search-total" style={ (showTwoLeggedFlights === 1 && totalFlightTime!== 0)  ? {display: 'block'} : {display: 'none'}}>
                  <span><Translate value="destinations.total_flight_time" /></span>
                   <strong><i className="ft-icon-clock"></i> <span className="flightTime">{ totalFlightTime}</span></strong>
                <span style={{lineHeight: '14px', marginBottom: 3, float: 'right'}}><Translate value='destinations.excluding_transfer_time' /></span>
              </div>
          </div>
          {destinationTooltip}
      </div>

    );
  }
}


const mapStateToProps = (state) => {
  return {
    activeTab: state.app.activeTab,
    language: state.app.language,
    destinations: state.data.destinations,
    frequencies: state.data.frequencies,
    flightTimes: state.data.flightTimes,
    searchIndex: state.data.searchIndex,
    selectedRegion: state.app.activeDestinationsRegion,

    formFocus: state.app.destinationFormFocus,
    fromCity: state.app.destinationsFromCity,
    toCity: state.app.destinationsToCity,
    selectedRouteFromCityFrequency: state.app.selectedRouteFromCityFrequency,
    selectedRouteToCityFrequency: state.app.selectedRouteToCityFrequency
  }
}

const mapDispatchToProps = (dispatch) => {
  return {

    setRegion: (region) => {
      dispatch({
        type: 'SET_DESTINATIONS_REGION',
        value: region
      });
    },
    setFocus: (input) => {
      dispatch({
        type: 'SET_FORM_FOCUS',
        value: input
      });
    },

    setFormCity: (action, value) => {
      dispatch({
        type: action,
        value: value
      });
    },
    setFrequency: (type, frequency) => {
      dispatch({
        type: type,
        value: frequency
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DestinationsBox);
