import React, { Component } from 'react';
import { connect } from 'react-redux';
import logo from 'assets/logo.png';
import planeRed from 'assets/icons/plane-red.svg';

//import './Header.css';
import { setLocale, Translate, Localize } from 'react-i18nify';


class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: new Date().getTime()
    };
  }

  updateTime(){
    setTimeout(function() {
      this.setState({time: new Date().getTime()});
      this.updateTime();
    }.bind(this), 20*1000);
  }

  componentDidMount() {
    this.updateTime();
  }

  render() {

      let filteredNofFlights = '';
      if (this.props.activeLiveFlightsRegion !== 'all') {
          const flights = Object.values(this.props.liveFlights)
          let flightCount = 0;

          for (let i = 0; i < flights.length - 1; i++) {
              const flight = flights[i];
              let showFlight = 0;
              if(
                  typeof flight.arrival !== 'undefined' &&
                  typeof flight.departure !== 'undefined' &&
                  flight.arrivalIata !== 'IST' &&
                  flight.arrivalIata !== 'SAW' &&
                  flight.arrivalIata !== 'ISL' &&
                  flight.departureIata !== 'IST' &&
                  flight.departureIata !== 'SAW' &&
                  flight.departureIata !== 'ISL'
              ) {

                  if ((flight.arrival.region === 'İÇ HAT' || flight.arrival.region === 'DOMESTIC') && this.props.activeLiveFlightsRegion.includes(flight.departure.region)) {
                      showFlight = 1;
                  } else if ((flight.departure.region === 'İÇ HAT' || flight.departure.region === 'DOMESTIC') && this.props.activeLiveFlightsRegion.includes(flight.arrival.region)) {
                      showFlight = 1;
                  } else if (
                      (flight.arrival.region !== 'İÇ HAT' && flight.arrival.region !== 'DOMESTIC') &&
                      (flight.departure.region !== 'İÇ HAT' && flight.departure.region !== 'DOMESTIC') &&
                      (this.props.activeLiveFlightsRegion.includes(flight.arrival.region) || this.props.activeLiveFlightsRegion.includes(flight.departure.region))
                  ) {
                      showFlight = 1;
                  }
              } else {
                  if (typeof flight.arrival !== 'undefined' && this.props.activeLiveFlightsRegion.includes(flight.arrival.region) && flight.arrivalIata !== 'IST' && flight.arrivalIata !== 'SAW' && flight.arrivalIata !== 'ISL') {
                      showFlight = 1;
                  }
                  if (typeof flight.departure !== 'undefined' && this.props.activeLiveFlightsRegion.includes(flight.departure.region) && flight.departureIata !== 'IST' && flight.departureIata !== 'SAW' && flight.departureIata !== 'ISL') {
                      showFlight = 1;
                  }
              }
              if (showFlight === 1) {
                  flightCount++;
              }
          }

          filteredNofFlights = flightCount + ' / ';
      }
    return (
      <header className="ft-header">
        <div className="ft-logo">
          <a>
            <img src={logo} className="logoimg" alt="Turkish Airlines - Widen Your World" /><br/>

          </a>
          </div>
          <nav className="ft-header-nav">
            <div className="ft-lang-switch" style={{
              position: 'absolute',
              top: '40px',
              right: '50px',
              zIndex:9999
            }}>
              <a className={ this.props.language === 'en' ? 'active lang-links' : 'lang-links' } onClick={() => this.props.setLanguage('en', this.props.activeTab)}>EN</a>
              <a className={ this.props.language === 'tr' ? 'active lang-links' : 'lang-links' } onClick={() => this.props.setLanguage('tr', this.props.activeTab)} >TR</a>
            </div>
          </nav>
          <div className="ft-date" style={{backgroundColor: 'rgba(0,0,0, 0.15)', margin: '0 auto', maxWidth: 450, minWidth:400,  padding: 10, borderRadius:8}}>
            <time id="app-time"><Localize value={this.state.time} dateFormat='date.long' style={{textTransform:'uppercase'}}/></time>
            &nbsp;<span className="ft-badge color-red"><Translate value="application.live" /></span>
            &nbsp;<img src={planeRed} />&nbsp;{filteredNofFlights}{this.props.numberOfFlights > 0 ? this.props.numberOfFlights : ''}

          </div>
      </header>
    );
  }
}


const mapStateToProps = (state) => {
    return {
        language: state.app.language,
        activeTab: state.app.activeTab,
        numberOfFlights: state.app.numberOfFlights,
        activeLiveFlightsRegion: state.app.activeLiveFlightsRegion,
        liveFlights: state.data.liveFlights,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLanguage: (lang, activeTab) => {
            setLocale(lang);
            dispatch({
                type: 'SET_LANGUAGE',
                value: lang
            });
            let type = 'SET_LIVE_FLIGHTS_REGION';
            switch (activeTab) {
              case 'destinations':
                type = 'SET_DESTINATIONS_REGION';
                break;
              case 'offers':
                type = 'SET_OFFERS_REGION';

            dispatch({
              type: type,
              value: 'all'
            });
                break;
              default:
                type = 'SET_LIVE_FLIGHTS_REGION';

            dispatch({
              type: type,
              value: 'all'
            });
                break;
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

