import { combineReducers } from 'redux';
import app from './app';
import data from './data';
import profile from './profile';

const rootReducer = combineReducers({
  profile, app, data
});

export default rootReducer;
