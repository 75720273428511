import { transferredFlights, transferredFlightsPairing, leggedFlights } from 'config';




export default class Routes {
  constructor(frequencies, fromCity, toCity) {
    this.frequencies = frequencies;
    this.fromCity = fromCity;
    this.toCity = toCity;
    this.routeCities = [];

  }

  findRoutes() {
    const directFlightIndex = this.fromCity.airportIata + '-' + this.toCity.airportIata;
    if (typeof this.frequencies[directFlightIndex] !== 'undefined') {
      this.routeCities.push(this.fromCity.airportIata);
      this.routeCities.push(this.toCity.airportIata);
      return true;
    }

    if (this.fromCity.airportIata !== 'IST' && this.toCity.airportIata !== 'IST' ) {
      this.findRoutesTransferredAtIST();
      return true;
    }

    if (this.fromCity.airportIata === 'IST') {
      this.findToRoutesFromIST();
      return true;
    }
    this.findFromRoutesToIST();
  }

  findRoutesTransferredAtIST() {
    const originToIstRouteFlightIndex = this.fromCity.airportIata + '-IST';
    const istToDestinationRouteFlightIndex = 'IST-' + this.toCity.airportIata;
    if (typeof this.frequencies[originToIstRouteFlightIndex] !== 'undefined') {
      this.routeCities.push(this.fromCity.airportIata);
      this.routeCities.push('IST');
    } else {
      if (typeof leggedFlights[originToIstRouteFlightIndex] !== 'undefined'){
        this.routeCities = this.routeCities.concat(leggedFlights[originToIstRouteFlightIndex]);
      } else {
        this.routeCities.push(this.fromCity.airportIata);
        this.routeCities.push('IST');
      }
    }
    if (typeof this.frequencies[istToDestinationRouteFlightIndex] !== 'undefined') {
      this.routeCities.push('IST');
      this.routeCities.push(this.toCity.airportIata);
    } else {
      if (typeof leggedFlights[istToDestinationRouteFlightIndex] !== 'undefined'){
        this.routeCities =this.routeCities.concat(leggedFlights[istToDestinationRouteFlightIndex]);
      } else{
        this.routeCities.push('IST');
        this.routeCities.push(this.toCity.airportIata);
      }

    }
  }


  findToRoutesFromIST() {
    const leggedFlightIndex = 'IST-' + this.toCity.airportIata;
    if (typeof leggedFlights[leggedFlightIndex] === 'undefined') {
      this.routeCities.push('IST');
      this.routeCities.push(this.toCity.airportIata);
    } else {
      this.routeCities =this.routeCities.concat(leggedFlights[leggedFlightIndex]);
    }
  }

  findFromRoutesToIST() {
    const leggedFlightIndex = this.fromCity.airportIata + '-IST';
    if (typeof leggedFlights[leggedFlightIndex] === 'undefined') {
      this.routeCities.push(this.fromCity.airportIata);
      this.routeCities.push('IST');
    } else {
      this.routeCities =this.routeCities.concat(leggedFlights[leggedFlightIndex]);
    }
  }

  getRoutes() {
    if (this.fromCity !== null && this.toCity !== null) {
      this.findRoutes();
    }
    return Routes.removeDuplicates(this.routeCities);
  }

  static removeDuplicates(arr) {
    let result = arr.reduce((accumulator, current) => {
      const length = accumulator.length
      if (length === 0 || accumulator[length - 1] !== current) {
          accumulator.push(current);
      }
      return accumulator;
    }, []);
    return result;
  }

  static getTransferPoint(points) {
    const pointsCount = points.length;
    if (pointsCount === 2) {
      return points[0];
    }
    if (points[0] !== 'IST' && points[pointsCount-1] !== 'IST') {
      return 'IST';
    }
    if (pointsCount === 2 && (points[0] === 'IST' || points[pointsCount-1] === 'IST')) {
      return 'IST';
    }


    const midPoint = Math.round(pointsCount/2)-1;
    return points[midPoint];
  }


  getLinePoint(destination, type) {
    return destination.cityGpsLocation.split(',').map(function(item){ return parseFloat(item);});
  }

}
