import React, { Component } from 'react';
import { Translate } from 'react-i18nify';
import { I18n } from 'react-i18nify';

export default class OfferHilite extends Component {

    constructor(props) {
        super(props);
        this.closeOfferDetails = this.closeOfferDetails.bind(this);
        this.showOfferDetails = this.showOfferDetails.bind(this);
        this.offerCode = null;
        this.popupClicked = null;
    }
    componentDidMount(){
        const element = document.getElementById("tooltip_offercityinfo");
    }

    showOfferDetails(e) {
        if (this.popupClicked !== this.offerCode) {
            this.props.showOfferDetails(e, this.offerCode);
            this.popupClicked = this.offerCode;
        } else {
            this.popupClicked = null;
            this.closeOfferDetails();
        }
    }
    closeOfferDetails() {
        console.log('closing');
        this.props.closeOfferDetails();
    }

  render() {
    const offer = this.props.data;
    if (this.offerCode !== offer.code) {
        this.popupClicked = null;
    }
    this.offerCode = offer.code;

    let topPosition  =  this.props.position.top - 10;
    let leftPosition = this.props.position.left + 25;
    let widthDiff = (window.innerWidth-leftPosition);

    let hiliteStyle = {position: 'fixed', top: topPosition, left: leftPosition};
    console.warn(leftPosition, window.innerWidth, widthDiff);
    if (widthDiff < 400) {
        hiliteStyle = {position: 'fixed', top: topPosition, right: (widthDiff+35)};
    }

    let style = this.popupClicked === null ? {color: '#333', lineHeight: '10px'}: {color: '#fff', lineHeight: '10px', backgroundColor: '#141e2d'};

    return (

    <div id="tooltip_offercityinfo" className="zIndexTop" style={hiliteStyle}>
        <div className="ft-offer-price-pin">
            <h4>
                <span className="offer-city"><Translate value={offer.data.cityName} />, <Translate value={offer.data.country} /></span>&nbsp;
                <span className="offer-price">{offer.price} {offer.currency}</span>&nbsp;
                <a className="show-offer-details" style={style} onClick={this.showOfferDetails}><i className="icon-dots"></i></a>
            </h4>
        </div>
    </div>
    );
  }
}
