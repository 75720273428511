
export default class Frequencies {

  constructor(frequencies, points) {
    this.frequencies = frequencies;
    this.points = points;
    this.routeFrequencies = [];
  }

  getFrequencies() {
    this.findFrequency();
    return this.routeFrequencies;
  }

  findFrequency() {
    const nofPoints = this.points.length;
    for (let i=0; i< nofPoints; i++) {
      if (i > 0) {
        let flightIndex = this.points[i-1] + '-' + this.points[i];
        if (typeof this.frequencies[flightIndex] !== 'undefined'){
          this.routeFrequencies.push(this.frequencies[flightIndex]);
        } else {
          this.routeFrequencies.push('0,0,0,0,0,0,0');
        }
      }
    }
  }



}
