import mapboxgl from 'mapbox-gl';

class Marker {

    constructor(map, marker) {
        let markerOffset = [0, 0];
        if (typeof marker.properties.markerOffset !== 'undefined') {
            markerOffset = marker.properties.markerOffset;
        }
        if (typeof marker.properties.rotate !== 'undefined') {
            let styleElement = document.createElement('style');
            styleElement.type = 'text/css';
            //styleElement.title = 'CSS'+marker.id;
            styleElement.innerHTML = '#'+marker.id+'::before { transform:rotate('+marker.properties.rotate+'deg);display:block; }';
            document.getElementsByTagName('head')[0].appendChild(styleElement);
        }
        const el = this.createElement(marker);
        el.onclick = marker.onClick;
        if (typeof marker.onMouseOver !== 'undefined'){
            el.onmouseover = marker.onMouseOver;
        }
        if (typeof marker.onMouseOut !== 'undefined'){
            el.onmouseout = marker.onMouseOut;
        }
        this.newlyCreatedMarker = new mapboxgl.Marker(el, {offset: markerOffset})
            .setLngLat(marker.geometry.coordinates);
        this.newlyCreatedMarker.addTo(map);
    }
    getMarker() {
        return this.newlyCreatedMarker;
    }

    createElement(marker){
        const el = document.createElement('div');
        el.setAttribute("id", marker.id);
        if (typeof marker.properties.rotate !== 'undefined') {
            el.setAttribute("data-angle", marker.properties.rotate);
        }
        el.className = marker.properties.class;
        el.style.width = marker.properties.iconWidth+ 'px';
        el.style.height =  marker.properties.iconHeight + 'px';
        return el;
    }
    static updateMarker(marker, newData) {
        const id = marker._element.id;
        const el = document.getElementById(id);

        /*if (typeof newData.properties.rotate !== 'undefined') {
            let styleElement = document.createElement('style');
            styleElement.type = 'text/css';
            styleElement.innerHTML = '#'+id+'::before { transform:rotate('+newData.properties.rotate+'deg);display:block; }';
            document.getElementsByTagName('head')[0].appendChild(styleElement);
        }*/
        el.setAttribute("data-angle", newData.properties.rotate);

        marker.setLngLat(newData.geometry.coordinates);
    }
}

export default Marker;