import React, { Component } from 'react';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }
    
    componentDidCatch(error, errorInfo) {
      // Catch errors in any components below and re-render with error message
      this.setState({
        error: error,
        errorInfo: errorInfo
      })
      // You can also log error messages to an error reporting service here
    }
    
    render() {
      if (this.state.errorInfo) {
        // Error path
        console.log(this.state.errorInfo);
        if (process.env.NODE_ENV !== 'development') {
          window.location.reload();
        }
        
        return (
          <div/>
        );
      }
      // Normally, just render children
      return this.props.children;
    }  
  }