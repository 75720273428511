import Calculations from './Calculations';

export default class FlightTimes {

  constructor(flightTimes, points, transferPoint) {
    this.flightTimes = flightTimes;
    this.points = points;
    this.transferPoint = transferPoint;
    this.times = [];
    this.totalFlightTime = 0;
    this.totalFlightToTransferPoint = 0;
    this.totalFlightFromTransferPoint = 0;
  }

  getFlightTimes() {
    this.findFlightTime();
    return {
      times:this.times,
      totalFlightTime: Calculations.convertDuration(this.totalFlightTime),
      totalFlightToTransferPoint: Calculations.convertDuration(this.totalFlightToTransferPoint),
      totalFlightFromTransferPoint: Calculations.convertDuration(this.totalFlightFromTransferPoint)
    };
  }

  findFlightTime() {
    let isTransferPoint = 0;
    const nofPoints = this.points.length;
    for (let i=0; i< nofPoints; i++) {
      if (i > 0) {
        let flightIndex = this.points[i-1] + '-' + this.points[i];
        let flightTime = '0:0';
        if (typeof this.flightTimes[flightIndex] !== 'undefined') {
          flightTime = this.flightTimes[flightIndex];
        }
        this.times.push(flightTime);
        this.totalFlightTime += Calculations.convertTextDuration(flightTime);
        if (isTransferPoint === 0) {
          this.totalFlightToTransferPoint+= Calculations.convertTextDuration(flightTime);
        } else {
          this.totalFlightFromTransferPoint += Calculations.convertTextDuration(flightTime);
        }
      }
      if (isTransferPoint === 0 && this.points[i] === this.transferPoint) {
        isTransferPoint = 1;
      }
    }
  }
}
