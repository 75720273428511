import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import './Footer.css';
import Offers from "../App/Offers";
import { Translate } from 'react-i18nify';

class Footer extends Component {

  render() {
    return (
      <footer className="ft-footer">
        <nav className="ft-footer-nav switch-sections">
          <a className={this.props.activeTab === 'live-flights' ? 'ft-btn color-red' : 'ft-btn'} onClick={() => this.props.viewModule('live-flights')}>
            <Translate value="application.liveFlights" />
          </a>
          <a className={this.props.activeTab === 'destinations' ? 'ft-btn color-red' : 'ft-btn'} onClick={() => this.props.viewModule('destinations')}>
            <span>{this.props.numberOfDestinations}</span> <Translate value="application.destinations" />
          </a>
          {this.props.offers && this.props.offers.length > 0 && <a className={this.props.activeTab === 'offers' ? 'ft-btn color-red ddd' : 'ft-btn'} onClick={() => this.props.viewModule('offers')}>
            {this.props.numberOfOffers} <Translate value="application.offers" />
          </a>}
        </nav>
      </footer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeTab: state.app.activeTab,
    numberOfDestinations: state.app.numberOfDestinations,
    numberOfOffers: state.app.numberOfOffers,
    zoomLevel: state.app.zoomLevel
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    viewModule: (moduleName) => {
      dispatch({
        type: 'SET_MODULE',
        value: moduleName
      });
      let type = 'SET_LIVE_FLIGHTS_REGION';
      switch (moduleName) {
        case 'destinations':
          type = 'SET_DESTINATIONS_REGION';
          ReactGA.pageview('DESTINATIONS');

          break;
        case 'offers':
          type = 'SET_OFFERS_REGION';
          ReactGA.pageview('OFFERS');

          break;
        default:
        ReactGA.pageview('LIVE-FLIGHTS');

          break;
      }
      if(type === 'SET_DESTINATIONS_REGION') {

        dispatch({
          type: 'SET_TO_CITY',
          value: null
        });
        dispatch({
          type: 'SET_FROM_CITY',
          value: null
        });


      }
      dispatch({
        type: type,
        value: 'all'
      });
    },
    setZoom(level) {
      const newLevel = this.zoomLevel + level;
      if (newLevel >= 1 && newLevel <=8) {
        dispatch({
          type: 'CHANGE_ZOOM_LEVEL',
          value: level
        });
      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
