
const dataStateReducer = (state = {
    liveFlights: {},
    destinations: {},
    managerCityData: null,
    managerStopCityData: null,
    offers: {},
    frequencies: {},
    flightTimes: {},
    activeFlightInfo: null,
    searchIndex: {}
  },
  action) => {

    // jshint ignore:start
    switch (action.type) {
      case 'SET_DESTINATIONS_DATA':
        return {
          ...state,
          destinations: action.value
        };

      case 'SET_MANAGER_DATA':
        return {
          ...state,
          managerCityData: action.value
        };
      case 'SET_MANAGER_STOP_CITY_DATA':
        return {
          ...state,
          managerStopCityData: action.value
        };
      case 'SET_SEARCH_INDEX':
        return {
          ...state,
          searchIndex: action.value
        };
      case 'SET_OFFERS_DATA':
        return {
          ...state,
          offers: action.value
        };

      case 'SET_FLIGHTS_DATA':
        return {
          ...state,
          liveFlights: action.value
        };
      case 'SET_FLIGHTS_CONFIG':
        return {
          ...state,
          liveFlightsConfig: action.value
        };
      case 'SET_FREQUENCIES_DATA':
        return {
          ...state,
          frequencies: action.value
        };
        case 'SET_FLIGHT_TIMES_DATA':
          return {
            ...state,
            flightTimes: action.value
          };

      case 'SET_ACTIVE_FLIGHT_DATA':
        return {
          ...state,
          activeFlightInfo: action.value
        };
      default:
        return state;
    }
    // jshint ignore:end
};

export default dataStateReducer;
