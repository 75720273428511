import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-i18nify';
import './Destinations.css';
import DestinationsBox from './DestinationsBox';
import DestinationsBoxForManager from './DestinationsBoxForManager';
import ReactGA from 'react-ga';
import ProfileContext from "config/ProfileContext";
import  {managerUrls} from 'config/prod-hosts';

import { I18n } from 'react-i18nify';

class Destinations extends Component {

  constructor(props) {
    super(props);
    this.changeDisplay = this.changeDisplay.bind(this);
    this.changeTooltipType = this.changeTooltipType.bind(this);
    this.state = {
      showTooltip: 0,
      activeTooltipType: 'to',
      profileType: this.context,
    };

    this.fromCity = null;
    this.toCity = null;
    this.activeTooltipType = 'to';
    this.loaded = false;
  }



  componentDidUpdate() {

    /*if (this.props.toCity !== this.toCity) {
      if(this.props.formFocus === 'from') {
        this.setState({showTooltip: 1});
      } else {
        this.setState({showTooltip: 0});
      }
    }
    if (this.props.fromCity !== this.fromCity) {
      if(this.props.formFocus === 'to') {
        this.setState({showTooltip: 1});
      } else {
        this.setState({showTooltip: 0});
      }
    }*/

    if(this.fromCity !== this.props.fromCity || this.toCity !== this.props.toCity) {
      this.setState({showTooltip: 0});
    }

    this.fromCity = this.props.fromCity;
    this.toCity = this.props.toCity;
  }



  selectRegionStandard(region) {

    let selectedRegions = [region.region];
    if (region === 'all' || region.region === 'all') {
      selectedRegions = 'all';
    }
    this.props.setRegion(selectedRegions.length === 0 ? 'all' : selectedRegions);
    ReactGA.event({
      category: 'Destinations',
      action: 'change_region',
      label: region.region
    });
  }
  selectRegionSpecial(region) {

    let selectedRegions = this.props.selectedRegion === 'all' ? [] : Object.assign([], this.props.selectedRegion);
    if (region === 'all' || region.region === 'all') {
      selectedRegions = 'all';
    }
    else if (selectedRegions !== 'all' && selectedRegions.includes(region.region)) {
      selectedRegions = selectedRegions.filter(function(value, index, arr){
        return value !== region.region;
      });
    } else {
      selectedRegions.push(region.region);
    }
    if (region === 'all' || region.region === 'all') {
      selectedRegions = 'all';
    }
    this.props.setRegion(selectedRegions.length === 0 ? 'all' : selectedRegions);
    ReactGA.event({
      category: 'Destinations',
      action: 'change_region',
      label: region.region
    });
  }

  changeDisplay() {
    if (this.state.showTooltip === 0) {
      this.setState({showTooltip: 1});
    } else {
      this.setState({showTooltip: 0});
    }

  }

  changeTooltipType(type) {
    this.setState({
      activeTooltipType: type
    });
  }

  getRegionButtonsForSpecialProfile (regions) {
    const regionsChildren = [];
    for (var i = 0; i < regions.length; i += 1) {
      let region = regions[i];
      regionsChildren.push(<li key={region.region} onClick={() => this.selectRegionSpecial(region)} className={"region-options"}>
        <a className={  'offers-region-btn ft-btn' }>

          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>

            <div style={{marginLeft: 14, marginRight: 10, width: 18, height:18, border: '2px solid red', borderRadius: 3}}>
              {(this.props.selectedRegion === 'all' || this.props.selectedRegion.includes(region.region)) && (<div style={{margin:3, backgroundColor: 'red', width: 8, height: 8}}></div>)}
            </div>
            <span style={{flexGrow: 1}}>{region.regionName}</span>
          </div>
        </a>
      </li>);
    };

    return regionsChildren;
  }

  getRegionButtonsForStandardProfile (regions) {
    const regionsChildren = [];
    for (var i = 0; i < regions.length; i += 1) {
      let region = regions[i];
      regionsChildren.push(<li key={region.region} onClick={() => this.selectRegionStandard(region)} className={"region-options"}>
        <a className={ (this.props.selectedRegion.includes(region.region)) ? 'offers-region-btn ft-btn color-red' : 'offers-region-btn ft-btn' }>
          {region.regionName}
        </a>
      </li>);
    };

    return regionsChildren;
  }

  componentDidMount() {
    this.setState({profileType: this.context});
  }


  render() {
    const destinations = this.props.destinations;
    let regionsChildren = [];
    /**
     * Generate region buttons
     */
    if (Object.keys(destinations).length > 0) {
      regionsChildren = [];
      const regions = destinations[this.props.language].regions;
      regionsChildren = this.state.profileType === 'standard' ? this.getRegionButtonsForStandardProfile(regions): this.getRegionButtonsForSpecialProfile(regions);
    }

    let boxContent = '';
    if (this.props.activeTab === 'destinations' && (window.location.search.includes('manager') || document.location.hostname.includes('manager'))) {
      boxContent = <DestinationsBoxForManager showTooltip={this.state.showTooltip} changeDisplay={this.changeDisplay} changeTooltipType={this.changeTooltipType}/>;
    } else if (this.props.activeTab === 'destinations') {
      boxContent = <DestinationsBox showTooltip={this.state.showTooltip} changeDisplay={this.changeDisplay} changeTooltipType={this.changeTooltipType}/>;
    }

    return (
      <div id="DestinationsContainer" className={this.props.activeTab === 'destinations' ? '' : 'hidden'}>
        { boxContent }
        <div className="ft-continent-list zIndexTop">
            <ul id="destinations-regions">
              {this.state.profileType === 'standard' && <li onClick={this.selectRegionStandard.bind(this, {region: 'all', regionName: 'ALL'})}><a className={ this.props.selectedRegion === 'all' ? 'offers-region-btn ft-btn color-red' : 'offers-region-btn ft-btn' }><Translate value="application.ALL" /></a></li>}

              {regionsChildren}
              {this.state.profileType === 'special' && this.props.selectedRegion !== 'all' && <li onClick={() => this.selectRegionSpecial({region: 'all', regionName: 'ALL'})}>
                <a className={ 'offers-region-btn ft-btn color-dark'  }><Translate value="application.RESET_FILTERS" /></a>
              </li>}
            </ul>
        </div>

      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    activeTab: state.app.activeTab,
    language: state.app.language,
    destinations: state.data.destinations,
    selectedRegion: state.app.activeDestinationsRegion,
    fromCity: state.app.destinationsFromCity,
    toCity: state.app.destinationsToCity,
    formFocus: state.app.destinationFormFocus

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setRegion: (region) => {
      dispatch({
        type: 'SET_DESTINATIONS_REGION',
        value: region
      });
    }
  }
}
Destinations.contextType = ProfileContext;
export default connect(mapStateToProps, mapDispatchToProps)(Destinations);
