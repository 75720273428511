const translations = {
  en: {
    application: {
      live: "LIVE",
      liveFlights: "LIVE FLIGHTS",
      destinations: "DESTINATIONS",
      offers: "OFFERS",
      NOW: "NOW",
      ALL: "ALL",
      to: "to",
      RESET_FILTERS: "Select all",
    },
    destinations: {
      choose_destination: "CHOOSE DESTINATION",
      flight_duration: "FLIGHT DURATION",
      flight_frequency: "Flight Frequency",
      transfer_point: "Transfer Point",
      total_flight_time: "TOTAL FLIGHT TIME",
      your_choice: "YOUR CHOICE(CITY)",
      from: "FROM",
      to: "TO",
      search_no_result: "No result!",
      no_flights: "Flights are temporarily suspended to this destination.",
      excluding_transfer_time: "*except transfer time",
      directorate: "Total Management",
      directorate_duration: "Management duration",
      total: "Total",
      AAL: "AAL",
      key_metrics: "KEY METRICS",
      pass_count: "PASS. COUNT",
      revenue: "REVENUE",

      period: "PERIOD",
      profit: "PROFIT",
      report_date: "Report date",
      flight_frequency2: "FLIGHT FREQUENCY",
      customer_satisfaction: "CUST. SATISFACTION",
      target: "TARGET",
      load_factor: "LOAD FACTOR",
      year_to_date_l_f: "YEAR-TO-DATE L/F",
      month_to_date_l_f: "MONTH-TO-DATE L/F",
      percentage_tr: "",
      percentage_en: "%",
      no_data: "Data couldn't be fetched, pls try again later.",
    },
    live_flights: {
      total_time: "Total time: ",
      remaning_time: "Remaining time: ",
      left: "left",
      left2: "left",
      landed: "Landed",
      scheduled: "Waiting for takeoff",
      enroute: "En Route",
      landing: "Landing",
      diverted: "Diverted",
      estimated: "\n(Waiting for takeoff)",
      status: "Status: ",
      altitude: "Altitude: ",
      speed: "Speed: ",
      dep_delayed: " minutes delayed departure",
      arr_delayed: "Arrival Delay: ",
    },
    offers: {
      "Round Trip": "Round Trip",
      "One Way": "One Way",
      ROUND_TRIP: "Round Trip",
      ONE_WAY: "One Way",
      ECONOMY: "Economy",
      BUSINESS: "Business",
      flight_frequency: "Flight Frequency",
      flight_duration: "FLIGHT DURATION\nFROM ISTANBUL",
      price_starting_from: " starting at",
    },

    date: {
      long: "D MMMM YYYY - hh:mm a",
      h: " h ",
      m: " m ",
      MON: "MON",
      TUE: "TUE",
      WED: "WED",
      THU: "THU",
      FRI: "FRI",
      SAT: "SAT",
      SUN: "SUN",

      JANUARY: "JANUARY",
      FEBRUARY: "FEBRUARY",
      MARCH: "MARCH",
      APRIL: "APRIL",
      MAY: "MAY",
      JUNE: "JUNE",
      JULY: "JULY",
      AUGUST: "AUGUST",
      SEPTEMBER: "SEPTEMBER",
      OCTOBER: "OCTOBER",
      NOVEMBER: "NOVEMBER",
      DECEMBER: "DECEMBER",
      "month-1": "JANUARY",
      "month-2": "FEBRUARY",
      "month-3": "MARCH",
      "month-4": "APRIL",
      "month-5": "MAY",
      "month-6": "JUNE",
      "month-7": "JULY",
      "month-8": "AUGUST",
      "month-9": "SEPTEMBER",
      "month-10": "OCTOBER",
      "month-11": "NOVEMBER",
      "month-12": "DECEMBER",
    },
  },
  tr: {
    application: {
      live: "CANLI",
      liveFlights: "UÇUŞ TAKİBİ",
      destinations: "UÇUŞ NOKTASI",
      offers: "KAMPANYA",
      NOW: "ŞİMDİ",
      ALL: "HEPSİ",
      to: " - ",
      RESET_FILTERS: "Tümünü seç",
    },

    live_flights: {
      total_time: "Toplam süre: ",
      remaning_time: "Kalan süre: ",
      left: "kaldı",
      left2: " ",
      landed: "İndi",
      scheduled: "Kalkış için bekliyor",
      enroute: "Havada",
      landing: "İniş",
      diverted: "Yönlendirildi",
      estimated: "\n (Kalkış için bekliyor)",
      status: "Durum: ",
      altitude: "İrtifa: ",
      speed: "Hız: ",
      dep_delayed: " dakika gecikmeli kalkış",
      arr_delayed: " dakika gecikmeli varış",
    },
    destinations: {
      choose_destination: "UÇUŞ NOKTASI SEÇİNİZ",
      flight_duration: "UÇUŞ SÜRESİ",
      flight_frequency: "Uçuş frekansı",
      transfer_point: "Transfer Noktası",
      total_flight_time: "TOPLAM UÇUŞ SÜRESİ",
      your_choice: "SEÇİMİNİZ(ŞEHİR)",
      from: "NEREDEN",
      to: "NEREYE",
      search_no_result: "Sonuç bulunamadı",
      no_flights: "Uçuşlarımız geçici olarak durdurulmuştur.",
      excluding_transfer_time: "*aktarma süresi hariç",
      directorate: "Toplam Müdürlük",
      directorate_duration: "Müdürlük süresi",
      total: "Toplam",
      AAL: "EBL",

      key_metrics: "TEMEL GÖSTERGE",
      pass_count: "YOLCU SAYISI",
      revenue: "GELİR",
      profit: "KAR",
      period: "DÖNEMİ",
      report_date: "Rapor tarihi",
      flight_frequency2: "UÇUŞ FREKANSI",
      customer_satisfaction: "MÜŞTERİ MEMNUNİYETİ",
      target: "HEDEF",
      load_factor: "DOLULUK ORANLARI",
      year_to_date_l_f: "YILBAŞINDAN BUGÜNE DOLULUK",
      month_to_date_l_f: "AY BAŞINDAN BUGÜNE DOLULUK",
      percentage_tr: "%",
      percentage_en: "",
      no_data: "Verilere ulaşılamıyor, lütfen daha sonra tekrar deneyiniz",
    },
    offers: {
      "Round Trip": "Gidiş-Dönüş",
      "One Way": "Tek Yön",
      ROUND_TRIP: "Gidiş-Dönüş",
      ONE_WAY: "Tek Yön",
      ECONOMY: "Ekonomi",
      BUSINESS: "Business",
      flight_frequency: "Uçuş frekansı",
      flight_duration: "ISTANBUL'DAN\nUÇUŞ SÜRESİ",
      price_starting_from: "'dan başlayan fiyatlarla",
    },

    date: {
      long: "D MMMM YYYY - HH:mm",
      h: " sa ",
      m: " dk ",
      MON: "PTS",
      TUE: "SAL",
      WED: "ÇAR",
      THU: "PER",
      FRI: "CUM",
      SAT: "CTS",
      SUN: "PAZ",
      JANUARY: "OCAK",
      FEBRUARY: "ŞUBAT",
      MARCH: "MART",
      APRIL: "NİSAN",
      MAY: "MAYIS",
      JUNE: "HAZİRAN",
      JULY: "TEMMUZ",
      AUGUST: "AĞUSTOS",
      SEPTEMBER: "EYLÜL",
      OCTOBER: "EKİM",
      NOVEMBER: "KASIM",
      DECEMBER: "ARALIK",
      "month-1": "OCAK",
      "month-2": "ŞUBAT",
      "month-3": "MART",
      "month-4": "NİSAN",
      "month-5": "MAYIS",
      "month-6": "HAZİRAN",
      "month-7": "TEMMUZ",
      "month-8": "AĞUSTOS",
      "month-9": "EYLÜL",
      "month-10": "EKİM",
      "month-11": "KASIM",
      "month-12": "ARALIK",
    },
  },
};

export default translations;
