import {newPlaneMarker, selectedDestinationMarker} from 'config';

export default class Flights {

  static getFlightsData(flights, destinations, language) {
    let data = {};
    for (var key in flights) {
        let flight = flights[key];
        
        if (flight.arrival === '' || flight.departure=== '') {
          continue;
        }
        flight.arrivalIata = flight.arrival;
        flight.departureIata = flight.departure;
        flight.arrival = destinations[language].activeAirports[flight.arrival];
        flight.departure = destinations[language].activeAirports[flight.departure];
        data[key] = flight;
        
        
    }
    return data;
  }

  static getMarkerData(flight, flightType) {
    let planeMarker = newPlaneMarker;
    planeMarker.id = flight.iata_identity;
    planeMarker.properties.name = flight.iata_identity;
    planeMarker.properties.rotate = flight.angle;
    planeMarker.properties.popUpOffset = [0,10];
    planeMarker.properties.iconSize = 20;
    planeMarker.geometry.coordinates = [flight.longitude, flight.latitude];  
    planeMarker.layout = {
      'text-rotate': flight.angle
    };
    planeMarker.onClick = flight.onClick;

    planeMarker.properties.class = flightType;

    if (typeof flight.onMouseOver !== 'undefined'){
      planeMarker.onMouseOver = flight.onMouseOver;
    }
    if (typeof flight.onMouseOut !== 'undefined'){
      planeMarker.onMouseOut = flight.onMouseOut;
    }
    return planeMarker;
}


  static getDestinationMarkerData(cityData) {
    let destinationMarker = selectedDestinationMarker;
    destinationMarker.id = cityData.slug;
    destinationMarker.properties.name = '';
    destinationMarker.properties.iconSize = 24;
    destinationMarker.properties.iconSizeH = 34;
    destinationMarker.properties.markerOffset=[0, -16];
    destinationMarker.geometry.coordinates = [cityData.coordinates.lng, cityData.coordinates.lat];
    return destinationMarker;
  }

}
