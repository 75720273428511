import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import configureStore from 'store/configureStore';
import translations  from 'lang';
import ProfileContext from "config/ProfileContext";
import { setTranslations, setLocale } from 'react-i18nify';
import 'dayjs/locale/tr';
const preloadedState = window.FLIGHT_TRACKER_INITIAL_STATE ?? {};
const store = configureStore(preloadedState);

setTranslations(translations);
setLocale(store.getState().app.language);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ProfileContext.Provider value="standard">
        <App />
      </ProfileContext.Provider>
    </Provider>,
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
