import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Calculations, Destinations, Flights, Offers } from 'helpers';
import translations  from 'lang';
import moment from 'moment';
import { setTranslations } from 'react-i18nify';

class DataCollector extends Component {

  constructor(props) {
    super(props);
    this.offers = null;
    this.language =  'tr';
  }

  componentDidMount() {
    this.getDestinationData();
  }

  componentDidUpdate() {
    if (this.props.activeTab === 'offers' && this.props.language !== this.language) {
      this.setOffersData(this.props.frequencies);
    }
    this.language = this.props.language;
  }

  getDestinationData() {
    fetch("https://tkapi.wewidenyourworld.com/destinations?v=" + (moment().format('YYYYMMDDHHSS')), {method: 'GET'})
    .then(res => res.json())
    .then(
      (result) => {
        result.data.destinations = result.data.destinations.map(destination => {
          if (destination.regionEn === 'MIDDLE EAST' || destination.regionEn === 'FAR EAST') {
            destination.regionEn = 'ASIA';
            destination.regionTr = 'ASYA';
          }
          return destination;
        })
        let helper = new Destinations(result);
        const data = helper.getDestinationsData();

        const searchIndex = Destinations.getDestinationsSearchIndex(data.destinationsData);
        this.props.setStats('SET_DESTINATIONS_NUMBER', data.numberOfDestinations);
        this.updateLanguageTranslationsForCityAndCountryNames(data.destinationsData);
        this.props.setData(data.destinationsData);
        this.props.setSearchIndex(searchIndex);
        this.getFlightsData(data.destinationsData);
        this.getFlightTimesData();
        this.getFrequenciesData(data.destinationsData);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  updateLanguageTranslationsForCityAndCountryNames(data) {
    for (var i=0; i<data.en.regions.length; i++) {
      translations.tr[data.en.regions[i].region] = data.tr.regions[i].region;
      translations.en[data.en.regions[i].region] = data.tr.regions[i].region;
      translations.tr[data.tr.regions[i].region] = data.en.regions[i].region;
      translations.en[data.tr.regions[i].region] = data.en.regions[i].region;
    }
    let c = 0;
    for (var key in data.en.activeDestinations) {
      c++;
      if (typeof translations.tr[data.en.activeDestinations[key].country] === 'undefined'){
        translations.tr[data.en.activeDestinations[key].country] = data.tr.activeDestinations[key].country;
        translations.en[data.en.activeDestinations[key].country] = data.en.activeDestinations[key].country;
        translations.tr[data.tr.activeDestinations[key].country] = data.tr.activeDestinations[key].country;
        translations.en[data.tr.activeDestinations[key].country] = data.en.activeDestinations[key].country;
      }

      if (typeof translations.tr[data.en.activeDestinations[key].cityName] === 'undefined'){
        translations.tr[data.en.activeDestinations[key].cityName] = data.tr.activeDestinations[key].cityName;
        translations.en[data.en.activeDestinations[key].cityName] = data.en.activeDestinations[key].cityName;
        translations.tr[data.tr.activeDestinations[key].cityName] = data.tr.activeDestinations[key].cityName;
        translations.en[data.tr.activeDestinations[key].cityName] = data.en.activeDestinations[key].cityName;
        translations.en['CG-' + key] = data.en.activeDestinations[key].cityDescription;
        translations.tr['CG-' + key] = data.tr.activeDestinations[key].cityDescription;
      }
    }
    setTranslations(translations);
  }

  getOffersData(frequencies) {
    fetch("https://tkapi.wewidenyourworld.com/offers.json?v=" + (moment().valueOf()), {method: 'GET'})
    .then(res => res.json())
    .then(
      (result) => {
        this.setOffersData(result, frequencies);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  setOffersData(offersData, frequencies) {
    const offers = {};
    const destinations = this.props.destinations[this.props.language].activeAirports;
    let offerCount = 0;
    for (let i = 0; i < offersData.length; i++) {
      let offer = offersData[i];
      if (typeof destinations[offer.departure.airport] === 'undefined') {
        continue;
      }
      if (typeof destinations[offer.arrival.airport] === 'undefined') {
        continue;
      }

      if (offer.departure.airport === 'IST' || offer.departure.airport === 'SAW' || offer.departure.airport === 'ISL') {
        offer.data = destinations[offer.arrival.airport];
        offer.url = 'https://www.turkishairlines.com/tr-tr/ucak-bileti/' + offer.data.slug + '-ucak-bileti';

        if (typeof frequencies['IST-'+offer.arrival.airport] !== 'undefined') {
          offer.frequency = frequencies['IST-'+offer.arrival.airport].split(',');
        } else {
          offer.frequency = [0,0,0,0,0,0,0];
        }
      } else {
        offer.data = destinations[offer.departure.airport];
        offer.frequency = frequencies['IST-'+offer.departure.airport].split(',');
      }
      for(let f=0; f<offer.frequency.length;f++) {
        offer.frequency[f] = parseInt(offer.frequency[f], 10);
      }
      offer.region = offer.data.region;
      offer.gps = offer.data.cityGpsLocation.split(',');
      offer.gps[0] = parseFloat(offer.gps[0]);
      offer.gps[1] = parseFloat(offer.gps[1]);
      offers[offer.code] = offer;
    }
    if (this.props.activeTab === 'live-flights') {
      this.props.setOffersData(offers);
      this.props.setStats('SET_OFFERS_NUMBER', Object.keys(offers).length);
    }
  }

  getFlightsData(destinationsData) {
    if (this.props.activeTab === 'live-flights') {
      fetch("https://tkapi.efabsrv.com/live-flights/tk", {method: 'GET'})
      .then(res => res.json())
      .then(
        (result) => {
          const data = Flights.getFlightsData(result.data, destinationsData, this.props.language);
          this.props.setFlightsConfig(result.config);
          this.props.setStats('SET_FLIGHTS_NUMBER', result.total);
          this.props.setFlightsData(data);
        },
        (error) => {
          console.log(error);
        }
      );
    }
    window.setTimeout(function(){
      this.getFlightsData(destinationsData);
    }.bind(this), 30*1000);

  }

  getFrequenciesData() {
    fetch("https://tkapi.wewidenyourworld.com/frequencies", {method: 'GET'})
    .then(res => res.json())
    .then(
      (result) => {
        this.props.setFrequenciesData(result);
       },
      (error) => {
        console.log(error);
      }
    );
  }

  getFlightTimesData() {
    fetch("https://tkapi.wewidenyourworld.com/flight-times", {method: 'GET'})
    .then(res => res.json())
    .then(
      (result) => {
        this.props.setFlightTimesData(result);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getFlightInfo(flightId, frId) {

    if (this.props.activeTab === 'live-flights') {
      fetch('https://tkapi.efabsrv.com/flights/TK/' + flightId + '?frid=' + frId, {method: 'GET'})
      .then(res => res.json())
      .then(
        (result) => {
          console.warn(result);
          const flight = result.data.flights[0];

          if (this.props.activeFlight !==null && flight.number === this.props.activeFlight.id) {
            const weather = result.data.weather;
            flight.arrivalCity = this.props.destinations[this.props.language].activeAirports[flight.arrival.iata];
            flight.departureCity = this.props.destinations[this.props.language].activeAirports[flight.departure.iata];
            this.props.setActiveFlightData({flight: flight, weather: weather});
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }


  render() {
    if (this.props.activeFlight !== null) {
      const flightData = this.props.flights[this.props.activeFlight.id];
      this.getFlightInfo(this.props.activeFlight.id, flightData.fr_id);
    }

    return (
      <div style={{display: 'none'}}>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      flights: state.data.liveFlights,
      activeTab: state.app.activeTab,
      language: state.app.language,
      activeFlight: state.app.activeFlight,
      destinations: state.data.destinations,
      frequencies: state.data.frequencies
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setData: (data, language) => {
      dispatch({
        type: 'SET_DESTINATIONS_DATA',
        value: data
      });
      dispatch({
        type: 'SET_FROM_CITY',
        value: data.tr.activeAirports.IST
      });
    },
    setFlightsData: (data) => {
      dispatch({
        type: 'SET_FLIGHTS_DATA',
        value: data
      });
    },
    setFlightsConfig: (data) => {
      dispatch({
        type: 'SET_FLIGHTS_CONFIG',
        value: data
      });
    },
    setFrequenciesData: (data) => {
      dispatch({
        type: 'SET_FREQUENCIES_DATA',
        value: data
      });
    },
    setFlightTimesData: (data) => {
      dispatch({
        type: 'SET_FLIGHT_TIMES_DATA',
        value: data
      });
    },
    setOffersData: (data) => {
      dispatch({
        type: 'SET_OFFERS_DATA',
        value: data
      });
    },
    setActiveFlightData: (data) => {
      dispatch({
        type: 'SET_ACTIVE_FLIGHT_DATA',
        value: data
      });
    },

    setStats: (action, value) => {
      dispatch({
        type: action,
        value: value
      });
    },
    setSearchIndex: (data) => {
      dispatch({
        type: 'SET_SEARCH_INDEX',
        value: data
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataCollector);
