
export default class Pins {

  constructor(destinations, points) {
    this.destinations = destinations;
    this.points = points;
    this.pins = [];
  }

  getPin(destination, type) {
    const gpsPositions = destination.cityGpsLocation.split(',').map(function(item){ return parseFloat(item);});
    const slug =  destination.airportIata === 'IST' ? 'IST' : type === 'T' ? 'TRANSFER-POINT': 'selected-dest-' + destination.airportIata;
    return {
      slug: slug,
      coordinates: {
        lng: gpsPositions[0],
        lat: gpsPositions[1]
      }
    };
  }

  findPins() {
    const nofPoints = this.points.length;
    for (let i=0; i < nofPoints; i++) {
      let point = this.points[i];
      let destination = this.destinations[point];
      let type = (i !== 0 && i !== nofPoints-1 ) ?  'T': 'P';
      this.pins.push(this.getPin(destination, type));
    }
  }

  getPins() {
    this.findPins();
    return this.pins;
  }
}
