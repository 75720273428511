import React, { Component } from 'react';
import 'App.css';
import {Header, DataCollector, Body, Footer} from 'components/Meta';
import ErrorBoundary from 'components/ErrorBoundary';
import ReactGA from 'react-ga';
import ProfileContext from "config/ProfileContext";

import  validHosts from 'config/prod-hosts';
let gaCode = 'UA-00000000-00';
if (validHosts.includes(document.location.hostname)) {
  gaCode = 'UA-46189212-30';
}
ReactGA.initialize(gaCode);
ReactGA.pageview('Home');
ReactGA.pageview('Live-Flights');

export default class App extends Component {

  static contextType = ProfileContext;

  render() {
    return (
      <div id="FlightTrackerContainer" className={`App profile-${this.context}`} >
        <ErrorBoundary>
          <DataCollector />
          <Header />
            <Body />
          <Footer />
        </ErrorBoundary>
      </div>
    );
  }
}

