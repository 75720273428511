import {destinationMarker, selectedDestinationMarker, istanbulMarker, transferPointMarker} from 'config';
import Strings from './Strings';

const customSortedRegions = {
    tr: {
        'AVRUPA': 0,
        'AMERİKA': 0,
        'AFRİKA': 0,
        'UZAKDOĞU': 0,
        'ORTADOĞU': 0,
        'ASYA': 0,
        'İÇ HAT': 0
    },
    en: {
        'EUROPE': 0,
        'AMERICA': 0,
        'AFRICA': 0,
        'ASIA': 0,
        'FAR EAST': 0,
        'MIDDLE EAST': 0,
        'DOMESTIC': 0
    }
};

export default class Destinations {
    constructor (response) {
        this.data = response.data;
        this.total = response.total+2;
    }

    getDestinationsData(){
        let destinationsData = {
            tr: {
                regions: [],
                subRegions: [],
                activeDestinations: {},
                activeAirports: {}
            },
            en: {
                regions: [],
                subRegions: [],
                activeDestinations: {},
                activeAirports: {}
            }
        };
        for (var destinationIndex in this.data.destinations) {
            var destination = this.data.destinations[destinationIndex];
            if (destination.isActive === 1 && destination.cityGpsLocation !== '') {
                if (destinationsData.tr.regions.includes(destination.regionTr) === false) {
                    destinationsData.tr.regions.push(destination.regionTr);
                }
                if (destinationsData.en.regions.includes(destination.regionEn) === false) {
                    destinationsData.en.regions.push(destination.regionEn);
                }
                if ( destinationsData.tr.subRegions.includes(destination.subRegionTr) === false ) {
                    destinationsData.tr.subRegions.push(destination.subRegionTr);
                }
                if (destinationsData.en.subRegions.includes(destination.subRegionEn) === false ) {
                    destinationsData.en.subRegions.push(destination.subRegionEn);
                }
                let destinationDataTr = this.getTrData(destination);
                let destinationDataEn = this.getEnData(destination);
                destinationsData.tr.activeDestinations[destination.cityIata] = destinationDataTr;
                destinationsData.en.activeDestinations[destination.cityIata] = destinationDataEn;

                for (var item in destination.airports) {
                    const airportDataTr = Object.assign({},destinationDataTr);
                    const airportDataEn = Object.assign({},destinationDataEn);
                    var airport = destination.airports[item];
                    if(airport.iata.length === 0) {
                        continue;
                    }
                    airportDataTr.airportIata = airport.iata;
                    airportDataEn.airportIata = airport.iata;
                    if (airport.coordinates !== null) {
                        airportDataTr.cityGpsLocation = airport.coordinates.longitude.replace('E', '') + ',' + airport.coordinates.latitude.replace('E', '');
                        airportDataEn.cityGpsLocation = airport.coordinates.longitude.replace('E', '') + ',' + airport.coordinates.latitude.replace('E', '');
                    }
                    destinationsData.tr.activeAirports[airport.iata] = airportDataTr;
                    destinationsData.en.activeAirports[airport.iata] = airportDataEn;
                }
            }
        }
        destinationsData.tr.regions = this.getSortedRegionForLanguage('tr', destinationsData.tr.regions);
        destinationsData.en.regions = this.getSortedRegionForLanguage('en', destinationsData.en.regions);
        return {
            destinationsData: destinationsData,
            numberOfDestinations: Object.keys(destinationsData.en.activeAirports).length
        };
    }

    getSortedRegionForLanguage(language, regions) {
        const sortedRegions = [];
        for (var regionIndex in customSortedRegions[language]) {
            if (regions.indexOf(regionIndex) !== -1) {
                customSortedRegions[language][regionIndex] = 1;
            }
        }
        for (regionIndex in customSortedRegions[language]) {
            let regionValue = customSortedRegions[language][regionIndex];
            if (regionValue === 0) {
                continue;
            }
            let region = regionIndex;
            let regionName = region;
            if (region === "UZAKDOĞU") {
                regionName = "UZAKDOĞU - ASYA";
            }
            if (region === "FAR EAST") {
                regionName = "FAR EAST - ASIA";
            }
            sortedRegions.push({region: region, regionName: regionName});
        }

        return sortedRegions;
    }

    getTrData(destination) {


        return {
            'region': destination.regionTr,
            'country': destination.countryTr,
            'cityName': destination.cityTr,
            'cityDescription': destination.cityDescriptionTr,
            'slug': destination.slugTr,
            'cityHeaderImagePath': destination.cityHeaderImagePath,
            'cityTimeZone': destination.cityTimeZone,
            'flightFrequency': destination.flightFrequency,
            'airportIata': destination.airports[0].iata,
            'totalFlightTime': destination.totalFlightTime,
            'cityIata': destination.cityIata,
            'cityGpsLocation': destination.cityGpsLocation,
            'showDetails': destination.showDetails,
            '_id': destination.id
        };
    }


    getEnData(destination) {

        return {
            'region': destination.regionEn,
            'country': destination.countryEn,
            'cityName': destination.cityEn,
            'cityDescription': destination.cityDescriptionEn,
            'slug': destination.slugEn,
            'cityHeaderImagePath': destination.cityHeaderImagePath,
            'cityTimeZone': destination.cityTimeZone,
            'airportIata': destination.airports[0].iata,
            'flightFrequency': destination.flightFrequency,
            'totalFlightTime': destination.totalFlightTime,
            'cityIata': destination.cityIata,
            'cityGpsLocation': destination.cityGpsLocation,
            'showDetails': destination.showDetails,
            '_id': destination.id
        };
    }

    static getDestinationsSearchIndex(destinationData) {

        let index = {
            tr: {},
            en: {}
        };
        for (let airportIata in destinationData.tr.activeAirports) {
            let destination = destinationData.tr.activeAirports[airportIata];

            index.tr[airportIata] = {
                cityIata: destination.cityIata,
                iata: airportIata,
                name: destination.cityName,
                cityNameIndex: Strings.removeDiacritics(destination.cityName)
            };
        }
        for (let airportIata in destinationData.en.activeAirports) {

            let destination = destinationData.en.activeAirports[airportIata];
            destination.airportIata = airportIata;
            index.en[airportIata] = {
                cityIata: destination.cityIata,
                iata: airportIata,
                name: destination.cityName,
                cityNameIndex: Strings.removeDiacritics(destination.cityName)
            };
        }
        return index;
    }


    static getDestinationMarkerData(cityData) {
        let newDestinationMarker = destinationMarker;
        newDestinationMarker.id = cityData.slug;
        newDestinationMarker.properties.iconSize = 24;
        newDestinationMarker.properties.iconSizeH = 34;
        newDestinationMarker.properties.markerOffset=[0, 0];
        newDestinationMarker.geometry.coordinates = [cityData.coordinates.lng, cityData.coordinates.lat];
        newDestinationMarker.onClick = cityData.onClick;

        return newDestinationMarker;
    }




    static getSelectedDestinationMarkerData(cityData) {
        let newDestinationMarker = selectedDestinationMarker;
        if (cityData.slug === 'IST') {
            newDestinationMarker = istanbulMarker;
        } else if(cityData.slug === 'TRANSFER-POINT') {
            newDestinationMarker = transferPointMarker;
        }
        newDestinationMarker.id = cityData.slug;
        newDestinationMarker.properties.iconSize = 24;
        newDestinationMarker.properties.iconSizeH = 34;

        newDestinationMarker.properties.markerOffset=[0, -16];
        if(cityData.slug === 'TRANSFER-POINT') {
            newDestinationMarker.properties.markerOffset=[0, -6];
        }
        newDestinationMarker.geometry.coordinates = [cityData.coordinates.lng, cityData.coordinates.lat];
        return newDestinationMarker;
    }
}
