import React, { Component } from 'react';
import { Translate } from 'react-i18nify';
import { Calculations, Strings } from 'helpers';
import { t } from 'react-i18nify';

export default class OfferPopUp extends Component {

    constructor(props) {
        super(props);
        this.closeOfferDetails = this.closeOfferDetails.bind(this);
    }

    closeOfferDetails () {
        this.props.closeOfferDetails();
    }

  render() {

    const tooltipPosition = document.getElementById('tooltip_offercityinfo').getBoundingClientRect();

    const offer = this.props.data;
    const offerQRCode = 'https://qr.wewidenyourworld.com/generate?code=' + offer.code + '&url=' + offer.url;
    let topPosition  = tooltipPosition.top - 20;
    let leftPosition = tooltipPosition.right + 5;
    if (topPosition + 502 > window.innerHeight) {
        topPosition = tooltipPosition.top - 502 + 42;
    }
    if (leftPosition + 300 > window.innerWidth) {
        leftPosition = tooltipPosition.left - 300 -5;
    }
    const offerType = t('offers.' + offer.ticket_type);
    return (
        <div id="cityinfo_dark" style={{display: this.props.display, position: 'absolute', zIndex: '999900', top: topPosition, left: leftPosition}}>
            <div className="ft-tooltip ft-tooltip-cityinfo-dark">
                <div className="ft-tooltip-content">
                    <div className="thumb" style={{
                      backgroundColor: '#333',
                      backgroundImage: `url(${offer.data.cityHeaderImagePath})`,
                      minHeight: 120,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'top center'
                    }}>

                      <div style={{
                        backgroundColor: 'rgba(0,0,0, 0.2)',
                        width: '100%',
                        height: 120
                      }}>
                        <div className="caption">
                          <Translate value={offerType}/>,  <Translate value={t('offers.' +offer.cabin)} /><br/>
                            <strong className="price">{offer.price} {offer.currency}</strong><Translate value='offers.price_starting_from' /><br />
                            <strong className="offer-city"><Translate value={offer.data.cityName} />{!offer.data.country.includes('.') && <>,</>} <Translate value={offer.data.country} /></strong>
                        </div>
                      </div>
                    </div>
                    <div className="ft-flight-duration display-linebreak">
                        <Translate value='offers.flight_duration'/> <strong><i className="ft-icon-clock"></i> <span className="offer-flight-time">{Calculations.prettyPrintFlightTime(offer.data.totalFlightTime)}</span></strong>
                    </div>
                    <div className="ft-flight-timetable-wrapper">
                        <table className="ft-flight-timetable">
                            <thead>
                                <tr>
                                    <th colSpan="7">
                                        <Translate value='offers.flight_frequency' />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="frequency">
                                    <td><Translate value="date.MON" /><br/><strong className="day-0">{offer.frequency[0] !== 0 ? offer.frequency[0]+'x' : '-'}</strong></td>
                                    <td><Translate value="date.TUE" /><br/><strong className="day-1">{offer.frequency[1] !== 0 ? offer.frequency[1]+'x' : '-'}</strong></td>
                                    <td><Translate value="date.WED" /><br/><strong className="day-2">{offer.frequency[2] !== 0 ? offer.frequency[2]+'x' : '-'}</strong></td>
                                    <td><Translate value="date.THU" /><br/><strong className="day-3">{offer.frequency[3] !== 0 ? offer.frequency[3]+'x' : '-'}</strong></td>
                                    <td><Translate value="date.FRI" /><br/><strong className="day-4">{offer.frequency[4] !== 0 ? offer.frequency[4]+'x' : '-'}</strong></td>
                                    <td><Translate value="date.SAT" /><br/><strong className="day-5">{offer.frequency[5] !== 0 ? offer.frequency[5]+'x' : '-'}</strong></td>
                                    <td><Translate value="date.SUN" /><br/><strong className="day-6">{offer.frequency[6] !== 0 ? offer.frequency[6]+'x' : '-'}</strong></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="body">
                        <div className="ft-qr-wrapper">
                            <img src={offerQRCode+'?v=' + (new Date().valueOf())} alt="" style={{height:'150px'}} />
                        </div>
                    </div>
                </div>
                <a onClick={this.closeOfferDetails} className="ft-tooltip-close"></a>
            </div>
        </div>
    );
  }
}
