import { mapboxConfig } from "config";
import React, { Component } from "react";
import { connect } from "react-redux";
import Map from "services/Map";
import {
  Flights,
  Destinations,
  Offers,
  Routes,
  Pins,
  Frequencies,
} from "helpers";
import moment from "moment";
import { t } from "react-i18nify";
import { managerUrls } from "config/prod-hosts";

import ReactGA from "react-ga";

const transferredFlights = {
  "IST-ZNZ": "JRO",
  "IST-CCS": "HAV",
  "IST-NKC": "BJL",
  "IST-ABJ": "COO",
  "IST-DUR": "JNB",
  "DUR-IST": "JNB",
  "IST-EBB": "KGL",
  "IST-TNR": "MRU",
  "TNR-IST": "MRU",
  "IST-MPM": "JNB",
  "MPM-IST": "JNB",
  "IST-EZE": "GRU",
  "EZE-IST": "GRU",
  "IST-CEB": "MNL",
  "CEB-IST": "MNL",
  "IST-NIM": "NDJ",
  "IST-NSI": "DLA",
  "IST-PTY": "BOG",
  "IST-DLA": "NSI",
  "IST-COO": "ABJ",
  "IST-FNA": "OUA",
  "FNA-IST": "OUA",
  "IST-CUN": "MEX",
  "IST-BJL": "NKC",
  "IST-CKY": "OUA",
  "CKY-IST": "OUA",
  "IST-NDJ": "NIM",
  "IST-LUN": "DAR",
  "LUN-IST": "DAR",
  "IST-GNJ": "NAJ",
  "IST-PNR": "LBV",
  "IST-LAD": "LBV",
  "IST-LBV": "LAD",
  "IST-FIH": "NDJ",
  "IST-MEL": "SIN",
  "MEL-IST": "SIN",
};

class Mapbox extends Component {
  constructor() {
    super();
    this.Map = null;
    this.markers = {
      liveFlights: {},
      liveFlightsSelectedDestinations: {},
      destinationsSelectedDestinations: {},
      liveFlightsSelectedPlane: {},
      historyFlights: {},
      destinations: {},
      selectedDestinations: {},
      offers: {},
      istanbul: null,
    };
    this.layers = {
      dayAndNight: null,
      liveFlightsSelectedDestinationsLines: [],
      destinationsLines: [],
      destinationsSelectedDestinationsLines: [],
      offersLines: [],
    };
    this.flightsOnAir = null;
    this.activeTab = "live-flights";
    this.activeLiveFlightsRegion = "all";
    this.activeDestinationsRegion = "all";
    this.activeOffersRegion = "all";
    //this.zoomLevel = 1;
    this.state = {
      selectedToCity: null,
      selectedFromCity: null,
      isTransferredFlight: false,
      zoomOnceForTheRegion: false,
    };
    this.language = null;
    this.historyData = {};
    this.showHistory = false;
    this.mapLoaded = 0;
    this.zoomLevel = 0;
    this.minZoomLevel = 1;
    this.maxZoomLevel = this.minZoomLevel + 11;
  }

  componentDidMount() {
    this.loadMap();
    //this.getFlightHistory();
  }
  componentDidUpdate(prevProps) {
    if (this.props.language) {
      this.Map.setLanguage(this.props.language === "tr" ? "tr" : "en");
      if (
        this.props.language !== prevProps.language &&
        this.props.fromCity !== null &&
        this.props.toCity !== null
      ) {
        let transferPointAirportIata = null;
        if (
          transferredFlights[
            this.props.fromCity.airportIata +
              "-" +
              this.props.toCity.airportIata
          ] !== undefined
        ) {
          transferPointAirportIata =
            transferredFlights[
              this.props.fromCity.airportIata +
                "-" +
                this.props.toCity.airportIata
            ];
        }
        if (
          transferPointAirportIata === null &&
          this.props.fromCity.airportIata !== "IST" &&
          this.props.toCity.airportIata !== "IST"
        ) {
          transferPointAirportIata = "IST";
        }

        this.getAdminStatistics(
          this.props.fromCity.airportIata,
          this.props.toCity.airportIata,
          transferPointAirportIata
        );
      }
    }
    if (this.props.activeTab !== "live-flights") {
      this.props.setActiveFlight(null);
    }
  }
  componentWillUnmount() {
    this.Map.remove();
  }

  loadMap() {
    this.Map = new Map(mapboxConfig);
    this.Map.addOnLoadFunctions(this.mapOnLoad.bind(this));
    this.Map.addOnZoomEndFunctions(this.mapZoomChanged.bind(this));
    this.Map.addOnClickFunctions(this.mapOnClick.bind(this));
    this.Map.loadMap();
    this.addIstanbulPin();
  }

  mapOnLoad(e) {
    this.minZoomLevel = Math.round(parseFloat(this.Map.getZoom().toFixed(2)));
    this.maxZoomLevel = this.minZoomLevel + 11;
    this.props.setMapLoaded();
    this.checkZoomLevelForControlButtons();
  }

  mapOnClick(e) {
    const originalTarget = e.originalEvent.target;
    if (this.props.activeTab === "live-flights") {
      if (originalTarget.id.indexOf("TK") === -1) {
        this.props.setActiveFlight(null);
        this.clearLayersForLiveFlight();
      }
    }
    if (this.props.activeTab === "offers") {
      this.props.setMapClick();
      if (originalTarget.id === "") {
        this.props.setActiveOffer(null);
        this.restoreSelectedOffer();
      }
    }
  }

  mapZoomChanged() {
    const zoomValue = this.Map.getZoom();
    const newZoomLevel = Math.floor(
      parseFloat((zoomValue - this.minZoomLevel / this.minZoomLevel).toFixed(2))
    );
    this.zoomLevel = newZoomLevel;
    this.props.setZoom(newZoomLevel);
    if (this.props.activeTab === "offers") {
      this.props.setActiveOffer(null);
      this.restoreSelectedOffer();
    }
    document.getElementById("ft-zoom-level").innerText = newZoomLevel + "x";
    this.checkZoomLevelForControlButtons();
  }

  setZoom(newZoomLevel) {
    this.Map.setZoom(newZoomLevel);
  }

  setZoomLevel(level) {
    let newZoomLevel = (this.zoomLevel - 1 + this.minZoomLevel + level).toFixed(
      2
    );
    this.setZoom(newZoomLevel);
  }

  toggleProjection = () => {
    const newProjection =
      this.props.mapProjection === "globe" ? "mercator" : "globe";
    this.props.setMapProjection(newProjection);
    this.Map.setProjection(newProjection);
    if (newProjection === "globe") {
      // Start spinning if the new projection is 'globe'
      this.Map.spinEnabled = true;
      this.Map.userInteracting = false;
      //  this.Map.spinGlobe();  // Start the spinning function
    } else {
      // Stop spinning when projection changes away from 'globe'
      this.Map.spinEnabled = false;
    }
  };

  checkZoomLevelForControlButtons() {
    if (parseFloat(this.Map.getZoom().toFixed(2)) <= this.minZoomLevel) {
      document.getElementById("ft-zoom-out-button").style.color = "#999";
    } else {
      document.getElementById("ft-zoom-out-button").style.color = "#101b2b";
    }
    if (parseFloat(this.Map.getZoom().toFixed(2)) >= this.maxZoomLevel) {
      document.getElementById("ft-zoom-in-button").style.color = "#999";
    } else {
      document.getElementById("ft-zoom-in-button").style.color = "#101b2b";
    }
  }

  isEnabledFlight(flightId) {
    const configLength = this.props.flightsConfig.length;
    for (let i = 0; i < configLength; i++) {
      let flightNumberIntervals = this.props.flightsConfig[i];
      if (
        flightId >= flightNumberIntervals.startNumber &&
        flightId <= flightNumberIntervals.endNumber &&
        flightNumberIntervals.isEnabled === true
      ) {
        return {
          isEnabled: 1,
          type: flightNumberIntervals.type,
        };
      }
    }
    return {
      isEnabled: 0,
      type: "NaN",
    };
  }

  processFlightsData(flights) {
    if (this.activeLiveFlightsRegion !== this.props.activeLiveFlightsRegion) {
      this.removeLiveFlightsLayersAndMarkers();
      this.flightsOnAir = [];
      this.activeLiveFlightsRegion = this.props.activeLiveFlightsRegion;
      this.props.setActiveFlight(null);
    }
    if (this.props.activeTab === "live-flights") {
      let processDataFirstTime = 0;
      if (this.flightsOnAir === null) {
        processDataFirstTime = 1;
        this.flightsOnAir = [];
      }
      let flightsOnAirTmp = [];
      for (var key in flights) {
        const flightId = parseInt(key.replace("TK", ""), 10);
        let showFlight = 0;
        let flightType = "plane-marker";

        let isEnabledFlight = this.isEnabledFlight(flightId);
        if (isEnabledFlight.isEnabled === 1 && isEnabledFlight.type === "AJ") {
          flightType = "plane-marker-aj";
        }

        if (isEnabledFlight.isEnabled === 0) {
          continue;
        }
        if (processDataFirstTime === 1) {
          this.flightsOnAir.push(key);
        }
        let flight = flights[key];

        if (this.props.activeLiveFlightsRegion !== "all") {
          if (
            typeof flight.arrival !== "undefined" &&
            typeof flight.departure !== "undefined" &&
            flight.arrivalIata !== "IST" &&
            flight.arrivalIata !== "SAW" &&
            flight.arrivalIata !== "ISL" &&
            flight.departureIata !== "IST" &&
            flight.departureIata !== "SAW" &&
            flight.departureIata !== "ISL"
          ) {
            if (
              (flight.arrival.region === "İÇ HAT" ||
                flight.arrival.region === "DOMESTIC") &&
              this.props.activeLiveFlightsRegion.includes(
                flight.departure.region
              )
            ) {
              showFlight = 1;
            } else if (
              (flight.departure.region === "İÇ HAT" ||
                flight.departure.region === "DOMESTIC") &&
              this.props.activeLiveFlightsRegion.includes(flight.arrival.region)
            ) {
              showFlight = 1;
            } else if (
              flight.arrival.region !== "İÇ HAT" &&
              flight.arrival.region !== "DOMESTIC" &&
              flight.departure.region !== "İÇ HAT" &&
              flight.departure.region !== "DOMESTIC" &&
              (this.props.activeLiveFlightsRegion.includes(
                flight.arrival.region
              ) ||
                this.props.activeLiveFlightsRegion.includes(
                  flight.departure.region
                ))
            ) {
              showFlight = 1;
            }
          } else {
            if (
              typeof flight.arrival !== "undefined" &&
              this.props.activeLiveFlightsRegion.includes(
                flight.arrival.region
              ) &&
              flight.arrivalIata !== "IST" &&
              flight.arrivalIata !== "SAW" &&
              flight.arrivalIata !== "ISL"
            ) {
              showFlight = 1;
            }
            if (
              typeof flight.departure !== "undefined" &&
              this.props.activeLiveFlightsRegion.includes(
                flight.departure.region
              ) &&
              flight.departureIata !== "IST" &&
              flight.departureIata !== "SAW" &&
              flight.departureIata !== "ISL"
            ) {
              showFlight = 1;
            }
          }
        } else {
          showFlight = 1;
        }

        if (showFlight) {
          flightsOnAirTmp.push(key);
          const isManager =
            window.location.search.includes("manager") ||
            document.location.hostname.includes("manager");

          flight.onClick = function (e) {
            e.preventDefault();
            if (isManager) {
              this.selectFlightRealPath(e);
            } else {
              this.selectFlight(e);
            }
            return false;
          }.bind(this);

          /*flight.onMouseOver = this.hilitePlane.bind(this);
          flight.onMouseOut = this.restorePlane.bind(this);*/
          const planeMarker = Flights.getMarkerData(flight, flightType);
          if (Object.keys(this.markers.liveFlights).indexOf(key) !== -1) {
            this.Map.updateMarker(this.markers.liveFlights[key], planeMarker);
          } else {
            let marker = this.Map.addMarker(planeMarker);
            this.markers.liveFlights[key] = marker;
          }
        }
      }
      var removeTKsOnAir = this.flightsOnAir.filter(function (i) {
        return flightsOnAirTmp.indexOf(i) < 0;
      });
      this.flightsOnAir = flightsOnAirTmp;
      if (removeTKsOnAir.length > 0) {
        for (var i = 0; i < removeTKsOnAir.length; i++) {
          if (
            typeof this.markers.liveFlights[removeTKsOnAir[i]] !== "undefined"
          ) {
            this.markers.liveFlights[removeTKsOnAir[i]].remove();
            delete this.markers.liveFlights[removeTKsOnAir[i]];
          }
        }
      }
    }
  }

  hilitePlane(e) {
    e.target.classList.add("pointed-plane-marker");
  }
  restorePlane(e) {
    e.target.classList.remove("pointed-plane-marker");
  }

  selectFlight(e) {
    const position = e.target.getBoundingClientRect();
    const activeFlight = {
      id: e.target.id,
      position: position,
    };

    ReactGA.event({
      category: "Live-Flights",
      action: "select_flight",
      label: activeFlight.id,
    });
    if (
      this.props.activeFlight !== null &&
      this.props.activeFlight.id === activeFlight.id
    ) {
      return false;
    }

    if (
      this.props.activeFlight !== null &&
      this.props.activeFlight.id !== activeFlight.id
    ) {
      this.clearLayers();
    }
    this.props.setActiveFlight(activeFlight);

    const flightData = this.props.flights[activeFlight.id];
    if (flightData.departure === undefined) {
      flightData.departure = {
        airportIata: flightData.departureIata,
        cityIata: flightData.departureIata,
        cityGpsLocation: flightData.longitude + "," + flightData.latitude,
      };
    }
    if (flightData.arrival === undefined) {
      flightData.arrival = {
        airportIata: flightData.arrivalIata,
        cityIata: flightData.arrivalIata,
        cityGpsLocation: flightData.longitude + "," + flightData.latitude,
      };
    }

    let coordsDeparture = flightData.departure.cityGpsLocation
      .split(",")
      .map(function (item) {
        return parseFloat(item);
      });
    let coordsArrival = flightData.arrival.cityGpsLocation
      .split(",")
      .map(function (item) {
        return parseFloat(item);
      });

    const flightIndex =
      flightData.departure.airportIata + "-" + flightData.arrival.airportIata;
    let departurePoint = flightData.departure.cityGpsLocation
      .split(",")
      .map(function (item) {
        return parseFloat(item);
      });
    let arrivalPoint = flightData.arrival.cityGpsLocation
      .split(",")
      .map(function (item) {
        return parseFloat(item);
      });
    if (flightData.arrivalIata === "SAW") {
      coordsArrival = arrivalPoint = [29.309167, 40.898333];
    }
    if (flightData.arrivalIata === "ISL") {
      coordsArrival = arrivalPoint = [28.8556455, 40.9866258];
    }
    if (flightData.arrivalIata === "IST") {
      coordsArrival = arrivalPoint = [28.7270623, 41.274273];
    }

    if (flightData.departureIata === "SAW") {
      coordsDeparture = departurePoint = [29.309167, 40.898333];
    }

    if (flightData.departureIata === "ISL") {
      coordsDeparture = departurePoint = [28.8556455, 40.9866258];
    }
    if (flightData.departureIata === "IST") {
      coordsDeparture = departurePoint = [28.7270623, 41.274273];
    }
    this.Map.addFlightRoute(
      activeFlight.id,
      departurePoint,
      [flightData.longitude, flightData.latitude],
      arrivalPoint,
      "#ffffff"
    );
    //console.log(activeFlight, flightData, flightIndex);

    /*if (typeof leggedFlights[flightIndex] !== 'undefined') {
      this.Map.addFlightRoute(
        activeFlight.id + '2',
        [28.8186794, 40.9818546],
        [28.8186794, 40.9818546],
        departurePoint,
        '#ffffff'
      );
    }*/

    e.target.classList.add("selected-plane-marker");
    var departureData = {
      slug: "selected-dest-" + flightData.departure.cityIata,
      coordinates: {
        lng: parseFloat(coordsDeparture[0]),
        lat: parseFloat(coordsDeparture[1]),
      },
    };
    var arrivalData = {
      slug: "selected-dest-" + flightData.arrival.cityIata,
      coordinates: {
        lng: parseFloat(coordsArrival[0]),
        lat: parseFloat(coordsArrival[1]),
      },
    };
    window.setTimeout(
      function () {
        if (flightData.departureIata !== "IST") {
          this.addSelectedDestinationMarker(departureData);
        }
        if (flightData.arrivalIata !== "IST") {
          this.addSelectedDestinationMarker(arrivalData);
        }

        var insideCoordinates = [
          [30, 42],
          [parseFloat(coordsDeparture[0]), parseFloat(coordsDeparture[1])],
          [parseFloat(coordsArrival[0]), parseFloat(coordsArrival[1])],
        ];
        this.Map.checkCoordinateInsideOfThePolygon(
          insideCoordinates,
          this.Map.map.getZoom()
        );
      }.bind(this),
      500
    );
    return false;
  }

  selectFlightRealPath(e) {
    const position = e.target.getBoundingClientRect();
    const flightId = e.target.id;
    const activeFlight = {
      id: flightId,
      position: position,
    };

    ReactGA.event({
      category: "Live-Flights",
      action: "select_flight",
      label: activeFlight.id,
    });

    if (
      this.props.activeFlight &&
      this.props.activeFlight.id !== activeFlight.id
    ) {
      this.clearLayers();
    }

    this.props.setActiveFlight(activeFlight);

    const flightData = this.props.flights[flightId];

    const { departureIata, arrivalIata, longitude, latitude } = flightData;

    // Extracting departure and arrival coordinates directly from flightData
    const departureCoords = flightData.departure.cityGpsLocation
      .split(",")
      .map(Number);
    const arrivalCoords = flightData.arrival.cityGpsLocation
      .split(",")
      .map(Number);

    // Drawing the route with enhanced method
    this.Map.drawRealRoute(
      flightId,
      departureIata,
      arrivalIata,
      departureCoords,
      arrivalCoords
    );

    e.target.classList.add("selected-plane-marker");

    this.scheduleDestinationMarkers(
      departureIata,
      arrivalIata,
      departureCoords,
      arrivalCoords
    );
    return false;
  }

  scheduleDestinationMarkers(
    departureIata,
    arrivalIata,
    departureCoords,
    arrivalCoords
  ) {
    setTimeout(() => {
      if (departureIata !== "IST") {
        this.addSelectedDestinationMarker({
          slug: "selected-dest-" + departureIata,
          coordinates: { lng: departureCoords[0], lat: departureCoords[1] },
        });
      }
      if (arrivalIata !== "IST") {
        this.addSelectedDestinationMarker({
          slug: "selected-dest-" + arrivalIata,
          coordinates: { lng: arrivalCoords[0], lat: arrivalCoords[1] },
        });
      }

      this.Map.checkCoordinateInsideOfThePolygon(
        [[30, 42], departureCoords, arrivalCoords],
        this.Map.map.getZoom()
      );
    }, 500);
  }

  showHistoryFlights() {
    //this.getFlightHistory();
    this.removeLiveFlightsLayersAndMarkers();
    this.processHistoryFlightsData(10000);
  }

  getFlightHistory() {
    const apiUrl = "//tkapi.efabsrv.com/flights_history_full/tk";
    const hoursBack = 3;
    if (this.props.activeTab === "live-flights") {
      fetch(apiUrl, { method: "GET" })
        .then((res) => res.json())
        .then(
          (result) => {
            this.historyData = Flights.getFlightsData(
              result.data,
              this.props.destinations,
              this.props.language
            );
            /*this.removeLiveFlightsLayersAndMarkers();
            this.processHistoryFlightsData(10000);*/
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  processHistoryFlightsData(start) {
    const limit = 300;
    const timerElement = document.getElementById("current-time");

    if (start < 0) {
      timerElement.innerText = "";
      this.removeLiveFlightsLayersAndMarkers();
      this.processFlightsData(this.props.flights);
      this.props.setShowHistory(false);
      return false;
    }
    const flights = this.historyData;
    if (
      this.props.activeTab === "live-flights" &&
      this.props.showHistory === true
    ) {
      let counter = start;
      const upLimit = Object.keys(flights).length - 1;
      for (let i = start; i > start - limit; i--) {
        if (typeof flights[i] === "undefined") {
          continue;
        }
        let flight = flights[i];
        if (i === start) {
          let lastUpdated = moment.unix(flight.last_update).utcOffset(3);
          timerElement.innerText =
            lastUpdated.format("hh:mm") + " " + t("application.to") + " ";
        }
        //console.log(lastUpdated.format('hh:mm')); break;
        if (
          typeof flight["iata_identity"] === "undefined" ||
          flight["iata_identity"] === "undefined"
        ) {
          continue;
        }
        let key = flight.iata_identity;
        const flightId = parseInt(key.replace("TK", ""), 10);
        let isEnabledFlight = this.isEnabledFlight(flightId);
        if (isEnabledFlight.isEnabled === 0) {
          continue;
        }
        flight.onClick = console.log; //this.selectFlight.bind(this);
        const planeMarker = Flights.getMarkerData(flight);
        if (typeof this.markers.liveFlights[key] !== "undefined") {
          this.Map.updateMarker(this.markers.liveFlights[key], planeMarker);
        } else {
          let marker = this.Map.addMarker(planeMarker);
          this.markers.liveFlights[key] = marker;
        }
      }
      window.setTimeout(
        function () {
          this.processHistoryFlightsData(start - limit);
        }.bind(this),
        140
      );
    }
    return false;
  }

  sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > milliseconds) {
        break;
      }
    }
  }

  processDestinationsData(destinationsData) {
    if (this.activeDestinationsRegion !== this.props.activeDestinationsRegion) {
      this.removeDestinationsLayersAndMarkers();
      let cityData =
        this.props.destinations[this.props.language].activeAirports.IST;

      this.props.setFormCity("SET_TO_CITY", null);
      this.setState({
        selectedToCity: null,
      });

      this.props.setFormCity("SET_FROM_CITY", cityData);
      this.setState({
        selectedFromCity: cityData,
      });
      this.activeDestinationsRegion = this.props.activeDestinationsRegion;
    }
    for (let destinationIndex in destinationsData) {
      let destination = destinationsData[destinationIndex];
      let showDestination = 1;

      if (
        this.activeDestinationsRegion !== "all" &&
        !this.activeDestinationsRegion.includes(destination.region) &&
        destination.cityIata !== "IST"
      ) {
        showDestination = 0;
      }

      if (showDestination === 0) {
        continue;
      }
      let coordinates = destination.cityGpsLocation.split(",");
      coordinates[0] = parseFloat(coordinates[0]);
      coordinates[1] = parseFloat(coordinates[1]);

      let pinType = "destination";
      if (destination.airportIata === "IST") {
        pinType = "istanbul";
      }
      if (destination.airportIata === "") {
        continue;
      }

      let destinationMarkerData = {
        slug: destination.airportIata,
        name: destination.cityName,
        pinType: pinType,
        coordinates: {
          lat: coordinates[1],
          lng: coordinates[0],
        },
        data: destination,
      };

      destinationMarkerData.onClick = this.selectDestination.bind(this);
      this.addDestinationMarker(destinationMarkerData);
      /*
      if (type === 'all') {
          addRoute('DL_' + destination.slug, [28.8186794, 40.9818546], coordinates);
      }*/
    }
  }

  async getAdminStatistics(origin, destination, stopStation = null) {
    try {
      this.props.setManagerData(null);
      this.props.setManagerStopCityData(null);

      let detail = {
        from: origin,
        to: destination,
      };
      if (stopStation === "IST") {
        detail = {
          from: "IST",
          to: stopStation === "IST" ? origin : stopStation,
        };
      }

      if (stopStation !== null && stopStation !== "IST") {
        detail = {
          from: origin,
          to: destination,
          stopStation: stopStation,
        };
      }

      const apiUrl = managerUrls.includes(document.location.hostname)
        ? "https://wsintra.thy.com/crm-solutions-services/api/flightTracker/flightInfo "
        : "https://wskurumsaltest.thy.com/crm-solutions-services/api/flightTracker/flightInfo";
      //console.log(apiUrl);
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: "Basic ZmxpZ2h0IHRyYWNrZXI6VHkxMjM0NTY=",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          requestHeader: {
            clientCode: "FTRC",
            clientUsername: "FTRC",
            application: "FTRC",
            clientTransactionId: "efabrika-ft-" + Math.floor(Date.now() / 1000),
            languageCode: this.props.language.toUpperCase(),
          },
          detail,
        }),
      });
      const json = await response.json();
      if (stopStation !== null && stopStation !== "IST") {
        json.stopStation = stopStation;
      }
      if (stopStation === "IST") {
        detail.from = "IST";
        detail.to = destination;

        const response2 = await fetch(apiUrl, {
          method: "POST",
          headers: {
            Authorization: "Basic ZmxpZ2h0IHRyYWNrZXI6VHkxMjM0NTY=",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            requestHeader: {
              clientCode: "FTRC",
              clientUsername: "FTRC",
              application: "FTRC",
              clientTransactionId:
                "efabrika-ft-" + Math.floor(Date.now() / 1000),
              languageCode: this.props.language.toUpperCase(),
            },
            detail,
          }),
        });
        const json2 = await response2.json();
        json2.stopStation = stopStation;
        json2.stopStationCity =
          this.props.destinations[this.props.language].activeAirports[
            stopStation
          ];
        this.props.setManagerStopCityData(json2);
      }
      this.props.setManagerData(json);
    } catch (error) {
      console.log(error);
    }
  }

  selectDestination(e) {
    e.preventDefault();
    const cityId = e.target.id;
    let dests = ["IST"];
    if (
      (this.props.formFocus === "from" &&
        this.props.toCity !== null &&
        this.props.toCity.cityIata === cityId) ||
      (this.props.formFocus === "to" &&
        this.props.fromCity !== null &&
        this.props.fromCity.cityIata === cityId)
    ) {
      return;
    }

    if (Object.keys(this.markers.destinationsSelectedDestinations).length > 0) {
      this.clearLayers();
    }

    const cityData =
      this.props.destinations[this.props.language].activeAirports[cityId];
    const cityDataIst =
      this.props.destinations[this.props.language].activeAirports.IST;

    let fromCity = null;
    let toCity = null;
    let cityType = "from";
    if (this.props.formFocus === "from") {
      fromCity = cityData;
      toCity = this.props.toCity;
    } else {
      cityType = "from";
      fromCity =
        this.props.fromCity === null ? cityDataIst : this.props.fromCity;
      toCity = cityData;
    }

    switch (this.props.formFocus) {
      case "to":
        this.props.setFormCity("SET_TO_CITY", cityData);

        this.setState({
          selectedToCity: cityData,
        });
        break;
      case "from":
        this.props.setFormCity("SET_FROM_CITY", cityData);

        this.setState({
          selectedFromCity: cityData,
        });
        break;
      default:
        break;
    }

    const transferPointIST = this.props.destinations[
      this.props.language
    ].activeAirports.IST.cityGpsLocation
      .split(",")
      .map(function (item) {
        return parseFloat(item);
      });

    const routes = new Routes(this.props.frequencies, fromCity, toCity);

    const points = routes.getRoutes();

    const pins = new Pins(
      this.props.destinations[this.props.language].activeAirports,
      points
    );
    const flightFrequencies = new Frequencies(this.props.frequencies, points);
    const dropPins = pins.getPins();

    if (
      toCity !== null &&
      fromCity !== null &&
      typeof toCity.cityGpsLocation !== "undefined"
    ) {
      const coordsDeparture = fromCity.cityGpsLocation.split(",");
      const coordsArrival = toCity.cityGpsLocation.split(",");
      let transferPoint = transferPointIST;
      let transferPointAirportIata = null;
      if (
        transferredFlights[fromCity.airportIata + "-" + toCity.airportIata] !==
        undefined
      ) {
        transferPointAirportIata =
          transferredFlights[fromCity.airportIata + "-" + toCity.airportIata];
        const transferPointCity =
          this.props.destinations[this.props.language].activeAirports[
            transferPointAirportIata
          ];
        const coordsTransferPointCity =
          transferPointCity.cityGpsLocation.split(",");

        transferPoint = [
          parseFloat(coordsTransferPointCity[0]),
          parseFloat(coordsTransferPointCity[1]),
        ];
      } else if (
        fromCity.airportIata !== "IST" &&
        toCity.airportIata !== "IST" &&
        flightFrequencies.points.length > 2
      ) {
        transferPoint = [
          parseFloat(coordsDeparture[0]),
          parseFloat(coordsDeparture[1]),
        ];
        transferPointAirportIata = "IST";
      }
      this.getAdminStatistics(
        fromCity.airportIata,
        toCity.airportIata,
        transferPointAirportIata
      );

      var insideCoordinates = [
        [30, 42],
        [parseFloat(coordsDeparture[0]), parseFloat(coordsDeparture[1])],
        [parseFloat(coordsArrival[0]), parseFloat(coordsArrival[1])],
      ];
      this.Map.checkCoordinateInsideOfThePolygon(
        insideCoordinates,
        this.Map.map.getZoom()
      );
      for (let i = 0; i < dropPins.length; i++) {
        let pin = dropPins[i];
        if (pin.slug !== "IST") {
          this.addSelectedDestinationMarkerForDestinations(pin);
        }
        if (i > 0) {
          let previous = dropPins[i - 1];
          let id = i + pin.slug + previous.slug;
          let originCoordinates = [
            previous.coordinates.lng,
            previous.coordinates.lat,
          ];
          let destinationCoordinates = [
            pin.coordinates.lng,
            pin.coordinates.lat,
          ];
          this.Map.addFlightRouteBetweenTwoPoints(
            id,
            originCoordinates,
            destinationCoordinates
          );
        }
      }
    }

    let frequencyArrayFrom = null;
    let frequencyArrayTo = null;

    fromCity = this.props.fromCity;

    toCity = this.props.toCity;
    if (this.props.formFocus === "from") {
      fromCity = cityData;
    }
    if (this.props.formFocus === "to") {
      toCity = cityData;
    }

    if (fromCity !== null && fromCity.cityIata !== "IST") {
      frequencyArrayFrom = this.getFrequency(fromCity);
    }
    this.props.setFrequency(
      "SET_FROM_CITY_ROUTE_FREQUENCY",
      frequencyArrayFrom
    );

    if (toCity !== null && toCity !== "IST") {
      frequencyArrayTo = this.getFrequency(toCity);
    }
    this.props.setFrequency("SET_TO_CITY_ROUTE_FREQUENCY", frequencyArrayTo);

    if (fromCity && fromCity.cityIata !== "IST" && toCity === null) {
      const coordsSelectedPinData = fromCity.cityGpsLocation.split(",");

      var selectedPinData = {
        slug: "selected-dest-" + fromCity.airportIata,
        coordinates: {
          lng: parseFloat(coordsSelectedPinData[0]),
          lat: parseFloat(coordsSelectedPinData[1]),
        },
      };
      this.addSelectedDestinationMarkerForDestinations(selectedPinData);
    }

    ReactGA.event({
      category: "Destinations",
      action: "select_destination_" + cityType,
      label: cityId,
    });
  }

  getFrequency(cityData) {
    let frequencyArray = null;
    const frequencyIndex = "IST-" + cityData.airportIata;
    const frequencyString = this.props.frequencies[frequencyIndex];

    if (typeof frequencyString !== "undefined") {
      if (frequencyString === ".......") {
        this.props.setFrequency("SET_FROM_CITY_ROUTE_FREQUENCY", null);
      } else {
        frequencyArray = frequencyString.split(",");
        for (let i = 0; i < frequencyArray.length; i++) {
          if (frequencyArray[i] === ".") {
            frequencyArray[i] = 0;
          }
          frequencyArray[i] = parseInt(frequencyArray[i], 10);
        }
      }
    }
    return frequencyArray;
  }

  adjustMap(region) {
    if (this.Map === null) {
      this.loadMap();
    }
    this.Map.adjustMap(region);
  }

  processOffersData() {
    this.clearLayers();
    if (Object.keys(this.props.offers).length > 0) {
      const destinations =
        this.props.destinations[this.props.language].activeAirports;
      let offerCount = 0;
      let showOffer = 1;
      for (let offerCode in this.props.offers) {
        showOffer = 1;
        let offer = this.props.offers[offerCode];
        let offerData = {
          slug: offerCode,
          name: offer.data.cityName + ", " + offer.data.country,
          coordinates: {
            lat: offer.gps[1],
            lng: offer.gps[0],
          },
          data: offer,
          onClick: this.selectOffer.bind(this),
        };
        if (
          this.props.activeOffersRegion !== "all" &&
          this.props.activeOffersRegion !== offer.region
        ) {
          showOffer = 0;
        }
        if (showOffer === 1) {
          this.addOfferMarker(offerData);
        }
      }
      return;
    }
  }

  addOfferMarker(offerData) {
    const offerMarker = Offers.getOfferMarkerData(offerData);
    this.markers.offers[offerData.data.code] = this.Map.addMarker(offerMarker);
  }

  selectOffer(e) {
    this.restoreSelectedOffer();
    e.target.classList.add("selected-offer-marker");

    const position = e.target.getBoundingClientRect();
    const activeOffer = {
      id: e.target.id,
      position: position,
    };
    this.props.setActiveOffer(activeOffer);

    ReactGA.event({
      category: "Offers",
      action: "select_offer",
      label: activeOffer.id,
    });

    const elements = document.getElementsByClassName("ft-tooltip-close");
    if (elements.length > 0) {
      var event = new MouseEvent("click");
      elements[0].dispatchEvent(event);
    }
  }

  addDestinationMarker(cityData) {
    const destinationMarker = Destinations.getDestinationMarkerData(cityData);
    var newCreatedMarker = this.Map.addMarker(destinationMarker);
    this.markers.destinations[cityData.slug] = newCreatedMarker;
  }

  addSelectedDestinationMarker(cityData) {
    const destinationMarker = Flights.getDestinationMarkerData(cityData);
    var newCreatedMarker = this.Map.addMarker(destinationMarker);
    this.markers.liveFlightsSelectedDestinations[cityData.slug] =
      newCreatedMarker;
  }
  addSelectedDestinationMarkerForDestinations(cityData) {
    const destinationMarker =
      Destinations.getSelectedDestinationMarkerData(cityData);
    this.markers.destinationsSelectedDestinations[cityData.slug] =
      this.Map.addMarker(destinationMarker);
  }

  addIstanbulPin() {
    var cityData = {
      slug: "IST",
      coordinates: {
        lng: 28.7270623,
        lat: 41.274273,
      },
    };
    const destinationMarker =
      Destinations.getSelectedDestinationMarkerData(cityData);
    destinationMarker.onClick = this.clickIstanbulMarker.bind(this);
    this.markers.istanbul = this.Map.addMarker(destinationMarker);
  }

  clickIstanbulMarker(e) {
    if (this.props.activeTab === "destinations") {
      this.selectDestination(e);
    }
  }

  addDayNightLayer() {
    this.Map.addDayNightLayer();
  }

  clearLayers() {
    if (this.props.activeTab === "live-flights") {
      this.clearLayersForLiveFlight();
    }

    if (this.props.activeTab === "destinations") {
      this.clearLayersForDestinations();
    }

    if (this.props.activeTab === "offers") {
      this.clearLayersForOffers();
    }
  }

  removeLiveFlightsLayersAndMarkers() {
    this.clearLayersForLiveFlight();
    for (var flightId in this.markers.liveFlights) {
      this.markers.liveFlights[flightId].remove();
    }
    this.markers.liveFlights = {};
  }

  removeHistoryFlightsLayersAndMarkers() {
    this.clearLayersForLiveFlight();
    for (var flightId in this.markers.historyFlights) {
      this.markers.historyFlights[flightId].remove();
    }
    this.markers.historyFlights = {};
  }

  removeDestinationsLayersAndMarkers() {
    this.removeLiveFlightsLayersAndMarkers();

    for (var destinationIata in this.markers.destinations) {
      this.markers.destinations[destinationIata].remove();
    }
    this.markers.destinations = {};

    for (let cityIata in this.markers.destinationsSelectedDestinations) {
      this.markers.destinationsSelectedDestinations[cityIata].remove();
    }
    this.markers.destinationsSelectedDestinations = {};
    let elements = document.getElementsByClassName("destination-marker");
    if (elements !== null) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].remove();
      }
    }
    let transferElements = document.getElementsByClassName("transfer-marker");
    if (transferElements !== null) {
      for (let i = 0; i < transferElements.length; i++) {
        transferElements[i].remove();
      }
    }
  }

  removeOffersLayersAndMarkers() {
    this.removeLiveFlightsLayersAndMarkers();
    this.removeDestinationsLayersAndMarkers();

    for (let offerCode in this.markers.offers) {
      this.markers.offers[offerCode].remove();
    }
    this.markers.offers = {};
  }

  clearLayersForLiveFlight() {
    const elements = document.getElementsByClassName("selected-plane-marker");
    if (elements.length > 0) {
      elements[0].classList.remove("selected-plane-marker");
    }
    this.Map.clearLayers("selectedDestinationLines");
    for (let flightId in this.markers.liveFlightsSelectedDestinations) {
      this.markers.liveFlightsSelectedDestinations[flightId].remove();
    }
    this.markers.liveFlightsSelectedDestinations = {};
  }

  clearLayersForDestinations() {
    const elements = document.getElementsByClassName(
      "selected-destination-marker"
    );
    if (elements.length > 0) {
      elements[0].classList.remove("selected-destination-marker");
    }
    const transferElements = document.getElementsByClassName("transfer-marker");
    if (transferElements.length > 0) {
      transferElements[0].remove();
    }

    this.Map.clearLayers("selectedDestinationLines");
    for (let cityIata in this.markers.destinationsSelectedDestinations) {
      this.markers.destinationsSelectedDestinations[cityIata].remove();
    }
    this.markers.destinationsSelectedDestinations = {};
  }

  restoreSelectedOffer() {
    const elements = document.getElementsByClassName("selected-offer-marker");
    if (elements.length > 0) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.remove("selected-offer-marker");
      }
    }
  }

  clearLayersForOffers() {
    const elements = document.getElementsByClassName("destination-marker");
    if (elements.length > 0) {
      elements[0].classList.remove("destination-marker");
    }
    this.restoreSelectedOffer();

    for (let offerCode in this.markers.offers) {
      this.markers.offers[offerCode].remove();
    }
    this.markers.offers = {};
  }

  render() {
    if (this.props.flightsConfig === undefined) {
    }
    if (
      this.activeLiveFlightsRegion !== this.props.activeLiveFlightsRegion ||
      this.activeDestinationsRegion !== this.props.activeDestinationsRegion ||
      this.activeOffersRegion !== this.props.activeOffersRegion
    ) {
      this.setState({ zoomOnceForTheRegion: false });
    }
    const zoomOnceForTheRegion =
      this.activeLiveFlightsRegion !== this.props.activeLiveFlightsRegion ||
      this.activeDestinationsRegion !== this.props.activeDestinationsRegion ||
      this.activeOffersRegion !== this.props.activeOffersRegion
        ? false
        : this.state.zoomOnceForTheRegion;

    console.log(zoomOnceForTheRegion, this.state.zoomOnceForTheRegion);
    if (
      this.Map !== null &&
      this.activeTab !== this.props.activeTab &&
      this.props.mapLoaded === true
    ) {
      this.Map.zoomToRegion(mapboxConfig.mapOptions.center, this.minZoomLevel);
      this.props.setActiveOffer(null);
    }
    if (
      this.props.activeTab === "live-flights" &&
      !this.props.activeLiveFlightsRegion.includes(
        this.activeLiveFlightsRegion
      ) &&
      zoomOnceForTheRegion === false
    ) {
      //this.Map.zoomToRegion(mapboxConfig.mapOptions.center, this.minZoomLevel);
      this.adjustMap(this.props.activeLiveFlightsRegion);
      this.setState({ zoomOnceForTheRegion: true });
      //console.log(this.props.activeLiveFlightsRegion)
    }
    if (
      this.props.activeTab === "offers" &&
      !this.props.activeOffersRegion.includes(this.activeOffersRegion) &&
      zoomOnceForTheRegion === false
    ) {
      this.Map.zoomToRegion(mapboxConfig.mapOptions.center, this.minZoomLevel);
      this.setState({ zoomOnceForTheRegion: true });
    }
    if (
      this.props.activeTab === "destinations" &&
      !this.props.activeDestinationsRegion.includes(
        this.activeDestinationsRegion
      ) &&
      zoomOnceForTheRegion === false
    ) {
      this.adjustMap(this.props.activeDestinationsRegion);
      this.setState({ zoomOnceForTheRegion: true });
      // this.Map.zoomToRegion(mapboxConfig.mapOptions.center, this.minZoomLevel);
    }

    if (this.props.mapLoaded === true && this.Map !== null) {
      //this.addDayNightLayer();
      this.processFlightsData(this.props.flights);
      this.mapLoaded++;
    }

    if (
      this.props.activeTab === "destinations" &&
      this.props.toCity === null &&
      this.props.fromCity !== null &&
      this.props.fromCity.cityIata === "IST"
    ) {
      this.clearLayers();
    }

    if (
      this.activeTab !== this.props.activeTab ||
      this.props.activeDestinationsRegion !== this.activeDestinationsRegion ||
      this.props.activeOffersRegion !== this.activeOffersRegion ||
      this.props.showHistory !== this.showHistory
    ) {
      this.setState({ zoomOnceForTheRegion: false });
      switch (this.props.activeTab) {
        case "live-flights":
          if (
            this.props.activeDestinationsRegion !==
              this.activeDestinationsRegion ||
            this.props.activeDestinationsRegion === "all"
          ) {
            //  this.adjustMap(this.props.activeDestinationsRegion);
          }
          this.removeDestinationsLayersAndMarkers();
          this.removeOffersLayersAndMarkers();
          if (this.props.showHistory === false) {
            this.processFlightsData(this.props.flights);
          } else {
            this.showHistoryFlights();
          }
          break;

        case "destinations":
          if (
            this.props.activeDestinationsRegion !==
              this.activeDestinationsRegion ||
            this.props.activeDestinationsRegion === "all"
          ) {
            //  this.adjustMap(this.props.activeDestinationsRegion);
          }
          this.removeLiveFlightsLayersAndMarkers();
          this.removeOffersLayersAndMarkers();
          this.processDestinationsData(
            this.props.destinations[this.props.language].activeAirports
          );

          break;

        case "offers":
          this.adjustMap(this.props.activeOffersRegion);
          this.removeLiveFlightsLayersAndMarkers();
          this.removeDestinationsLayersAndMarkers();
          this.processOffersData(this.props.offers);
          this.activeOffersRegion = this.props.activeOffersRegion;
          break;
      }
      this.activeTab = this.props.activeTab;
    }
    if (this.zoomLevel !== this.props.zoomLevel) {
      this.zoomLevel = this.props.zoomLevel;
    }

    return (
      <div
        id="map"
        style={{
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          touchAction: "none",
        }}
      >
        <div className="ft-zoom-btns zIndexTop">
          {this.props.zoomLevel > 1 && (
            <div
              className="material-symbols-outlined"
              style={{
                fontSize: 34,
                backgroundColor: "#fff",
                marginBottom: 10,
                cursor: "pointer",
                width: 34,
                height: 34,
              }}
              onClick={() => this.setZoom(1)}
            >
              zoom_out_map
            </div>
          )}
          <span className="ft-zoom-level" id="ft-zoom-level">
            1x
          </span>
          <a
            id="ft-zoom-in-button"
            className="ft-zoom-in"
            onClick={() => this.setZoomLevel(1)}
          >
            <span>+</span>
          </a>
          <a
            id="ft-zoom-out-button"
            className="ft-zoom-out"
            onClick={() => this.setZoomLevel(-1)}
          >
            <span>&ndash;</span>
          </a>
          <a
            id="ft-projection-button"
            className="ft-projection"
            onClick={this.toggleProjection}
          >
            {" "}
            <span
              className="material-symbols-outlined"
              style={{ fontSize: 34, marginTop: 10, cursor: "pointer" }}
            >
              {this.props.mapProjection === "mercator" ? "public" : "map"}
            </span>
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeTab: state.app.activeTab,
    language: state.app.language,
    showHistory: state.app.showHistory,
    flights: state.data.liveFlights,
    flightsConfig: state.data.liveFlightsConfig,
    destinations: state.data.destinations,
    frequencies: state.data.frequencies,
    offers: state.data.offers,
    mapLoaded: state.app.mapLoaded,
    activeFlight: state.app.activeFlight,
    activeLiveFlightsRegion: state.app.activeLiveFlightsRegion,
    activeDestinationsRegion: state.app.activeDestinationsRegion,
    activeOffersRegion: state.app.activeOffersRegion,
    zoomLevel: state.app.zoomLevel,
    toCity: state.app.destinationsToCity,
    fromCity: state.app.destinationsFromCity,
    formFocus: state.app.destinationFormFocus,
    mapProjection: state.app.mapProjection,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setData: (data) => {
      dispatch({
        type: "SET_DATA",
        value: data,
      });
    },
    setZoom(newLevel) {
      if (newLevel >= 1 && newLevel <= 8) {
        dispatch({
          type: "SET_ZOOM_LEVEL",
          value: newLevel,
        });
      }
    },
    setStats: (action, value) => {
      dispatch({
        type: action,
        value: value,
      });
    },
    setFormCity: (action, value) => {
      dispatch({
        type: action,
        value: value,
      });
    },
    setMapLoaded: () => {
      dispatch({
        type: "SET_MAP_LOADED",
        value: true,
      });
    },
    setActiveFlight: (data) => {
      dispatch({
        type: "SET_ACTIVE_FLIGHT",
        value: data,
      });
      dispatch({
        type: "SET_ACTIVE_FLIGHT_DATA",
        value: null,
      });
    },
    setActiveOffer: (data) => {
      dispatch({
        type: "SET_ACTIVE_OFFER",
        value: data,
      });
    },
    setMapClick: () => {
      dispatch({
        type: "SET_MAP_CLICK",
        value: null,
      });
    },

    setManagerData: (data) => {
      dispatch({
        type: "SET_MANAGER_DATA",
        value: data,
      });
    },
    setManagerStopCityData: (data) => {
      dispatch({
        type: "SET_MANAGER_STOP_CITY_DATA",
        value: data,
      });
    },
    setShowHistory: (opt) => {
      dispatch({
        type: "SET_SHOW_HISTORY",
        value: opt,
      });
    },
    setFrequency: (type, frequency) => {
      dispatch({
        type: type,
        value: frequency,
      });
    },
    setMapProjection(projection) {
      dispatch({
        type: "SET_MAP_PROJECTION",
        value: projection,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mapbox);
