
const leggedFlights = {
  'IST-CMB': ['IST', 'MLE', 'CMB'],
  'CMB-IST': ['CMB', 'MLE', 'IST'],
  'IST-DUR': ['IST', 'JNB', 'DUR'],
  'DUR-IST': ['DUR', 'JNB', 'IST'],
  'IST-MPM': ['IST', 'JNB', 'MPM'],
  'MPM-IST': ['MPM', 'JNB', 'IST'],
  'IST-TNR': ['IST', 'MRU', 'TNR'],
  'TNR-IST': ['TNR', 'MRU', 'IST'],

  'IST-PTY': ['IST', 'BOG', 'PTY'],
  'BOG-IST': ['BOG', 'PTY', 'IST'],
  'IST-CCS': ['IST', 'HAV', 'CCS'],
  'HAV-IST': ['HAV', 'CCS', 'IST'],
  'IST-ABJ': ['IST', 'COO', 'ABJ'],
  'COO-IST': ['COO', 'ABJ', 'IST'],
  'IST-JRO': ['IST', 'ZNZ', 'JRO'],
  'ZNZ-IST': ['ZNZ', 'JRO', 'IST'],
  'IST-EBB': ['IST', 'KGL', 'EBB'],
  'KGL-IST': ['KGL', 'EBB', 'IST'],
  //'IST-MBA': ['IST', 'JRO', 'MBA'],
  'IST-CKY': ['IST', 'OUA', 'CKY'],
  'CKY-IST': ['CKY', 'OUA', 'IST'],
  'IST-FNA': ['IST', 'OUA', 'FNA'],
  'FNA-IST': ['FNA', 'OUA', 'IST'],
  'IST-DSS': ['IST', 'NKC', 'DSS'],
  //'IST-LBV': ['IST', 'FIH', 'LBV'],
  'IST-NDJ': ['IST', 'NSI', 'NDJ'],
  'IST-MGQ': ['IST', 'JIB', 'MGQ'],
  'JIB-IST': ['JIB', 'MGQ', 'IST'],
  'IST-EZE': ['IST', 'GRU', 'EZE'],
  'EZE-IST': ['EZE', 'GRU', 'IST'],
  'IST-KVD': ['IST', 'NAJ', 'KVD'],
  'NAJ-IST': ['NAJ', 'KVD', 'IST'],
  'IST-ULN': ['IST', 'FRU', 'ULN'],
  'ULN-IST': ['ULN', 'FRU', 'IST'],
  'IST-HAH': ['IST', 'SEZ', 'HAH'],
  'HAH-IST': ['HAH', 'SEZ', 'IST']

};

const transferredFlightsPairing = {
  'MLE-CMB': 'IST-MLE',
  'JNB-DUR': 'IST-JNB',
  'JNB-MPM': 'IST-JNB',
  'MRU-TNR': 'IST-MRU',
  'BOG-PTY': 'IST-BOG',
  'HAV-CCS': 'IST-HAV',
  'HAN-SGN': 'IST-HAN',
  'COO-ABJ': 'IST-COO',
  'ZNZ-JRO': 'IST-ZNZ',
  'KGL-EBB': 'IST-KGL',
  'JRO-MBA': 'IST-JRO',
  'NIM-BKO': 'IST-NIM',
  'BKO-NIM': 'IST-BKO',
  'OUA-CKY': 'IST-OUA',
  'NKC-DSS': 'IST-NKC',
  //'FIH-LBV': 'IST-FIH',
  //'TIF-ASM': 'IST-TIF',
  'NSI-NDJ': 'IST-NSI',
  //'NSI-DLA': 'IST-NSI',
  'JIB-MGQ': 'IST-JIB',
  'GRU-EZE': 'IST-GRU',
  'NAJ-KVD': 'IST-NAJ',
  'FRU-ULN': 'IST-FRU',
  'CND-VAR': 'IST-CND'
};

const transferredFlights = {
  'IST-CMB': 'MLE',
  'CMB-IST': 'MLE',

  'IST-DUR': 'JNB',
  'DUR-IST': 'JNB',

  'IST-MPM': 'JNB',
  'MPM-IST': 'JNB',

  'IST-TNR': 'MRU',
  'TNR-IST': 'MRU',

  'IST-PTY': 'BOG',
  'BOG-IST': 'PTY',

  'IST-CCS': 'HAV',
  'HAV-IST': 'CCS',
  
  'IST-SGN': 'HAN',
  'HAN-IST': 'SGN',

  'IST-ABJ': 'COO',
  'COO-IST': 'ABJ',

  'IST-JRO': 'ZNZ',
  'ZNZ-IST': 'JRO',

  'IST-EBB': 'KGL',
  'KGL-IST': 'EBB',
  
  'IST-MBA': 'JRO',
  'JRO-IST': 'MBA',
  
  'IST-BKO': 'NIM',
  'NIM-IST': 'BKO',
  
  'IST-CKY': 'OUA',  
  'CKY-IST': 'OUA',
  
  'IST-DSS': 'NKC',
  'NKC-IST': 'DSS',
  
  //'IST-LBV': 'FIH',
  //'FIH-IST': 'LBV',
  //'TIF-ASM': 'IST-TIF',
  
  'IST-NDJ': 'NSI',
  'NSI-IST': 'NDJ',
  
  //'IST-DLA': 'NSI',
  
  'IST-MGQ': 'JIB',  
  'MGQ-IST': 'JIB',
  
  'IST-EZE': 'GRU',
  'EZE-IST': 'GRU',
  
  'IST-KVD': 'NAJ',
  'NAJ-IST': 'KVD',
  
  'IST-ULN': 'FRU',  
  'ULN-IST': 'FRU',
  
  'IST-VAR': 'CND',
  'CND-IST': 'VAR'
};


export {transferredFlights, transferredFlightsPairing, leggedFlights};