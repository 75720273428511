import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-i18nify';
import './LiveFlights.css';
import FlightInfo from './FlightInfo';
import ReactGA from 'react-ga';
import ProfileContext from "config/ProfileContext";

class LiveFlights extends Component {

  constructor(props) {
    super(props);
    this.state = {
      profileType: this.context,
    };
  }

  selectRegionStandard(region) {

    let selectedRegions = [region.region];
    if (region === 'all' || region.region === 'all') {
      selectedRegions = 'all';
    }
    this.props.setRegion(selectedRegions.length === 0 ? 'all' : selectedRegions);
    this.previousFlight = null;
    ReactGA.event({
      category: 'Live-Flights',
      action: 'change_region',
      label: region.region
    });
  }

  selectRegionSpecial(region) {

    let selectedRegions = this.props.selectedRegion === 'all' ? [] : Object.assign([], this.props.selectedRegion);
    if (region === 'all' || region.region === 'all') {
      selectedRegions = 'all';
    }
    else if (selectedRegions !== 'all' && selectedRegions.includes(region.region)) {
      selectedRegions = selectedRegions.filter(function(value, index, arr){
        return value !== region.region;
      });
    } else {
      selectedRegions.push(region.region);
    }
    // let selectedRegions = [region.region];
    if (region === 'all' || region.region === 'all') {
      selectedRegions = 'all';
    }
    this.props.setRegion(selectedRegions.length === 0 ? 'all' : selectedRegions);
    this.previousFlight = null;
    ReactGA.event({
      category: 'Live-Flights',
      action: 'change_region',
      label: region.region
    });
  }

  animatePlanes(hoursBack) {
    this.props.showHistory(true);
  }

  getRegionButtonsForSpecialProfile (regions) {
    const regionsChildren = [];
    for (var i = 0; i < regions.length; i += 1) {
      let region = regions[i];
      regionsChildren.push(<li key={region.region} onClick={() => this.selectRegionSpecial(region)} className={"region-options"}>
        <a className={  'offers-region-btn ft-btn' }>

          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>

            <div style={{marginLeft: 14, marginRight: 10, width: 18, height:18, border: '2px solid red', borderRadius: 3}}>
              {(this.props.selectedRegion === 'all' || this.props.selectedRegion.includes(region.region)) && (<div style={{margin:3, backgroundColor: 'red', width: 8, height: 8}}></div>)}
            </div>
            <span style={{flexGrow: 1}}>{region.regionName}</span>
          </div>
        </a>
      </li>);
    };

    return regionsChildren;
  }

  getRegionButtonsForStandardProfile (regions) {
    const regionsChildren = [];
    for (var i = 0; i < regions.length; i += 1) {
      let region = regions[i];
      regionsChildren.push(<li key={region.region} onClick={() => this.selectRegionStandard(region)} className={"region-options"}>
        <a className={ (this.props.selectedRegion.includes(region.region)) ? 'offers-region-btn ft-btn color-red' : 'offers-region-btn ft-btn' }>
               {region.regionName}
        </a>
      </li>);
    };

    return regionsChildren;
  }

  componentDidMount() {
    this.setState({profileType: this.context});
  }

  render() {
    if (this.state.profileType === undefined) {
      return (<React.Fragment></React.Fragment>);
    }

    const destinations = this.props.destinations;
    let regionsChildren = [];
    /**
     * Generate region buttons
     */
    if (Object.keys(destinations).length > 0) {
      const regions = destinations[this.props.language].regions;
      regionsChildren = this.state.profileType === 'standard' ? this.getRegionButtonsForStandardProfile(regions): this.getRegionButtonsForSpecialProfile(regions);
    }

    let flightInfo = '';
    if (this.props.activeFlight !== null && this.props.activeFlightInfo !== null) {
      if(this.props.activeFlightInfo !== null) {
        let flightInfoData = this.previousFlight;
        if (this.props.activeFlightInfo !== this.previousFlight) {
          flightInfoData = this.props.activeFlightInfo;
          this.previousFlight = this.props.activeFlightInfo;
        }
        flightInfo = <FlightInfo flightInfo={flightInfoData} selectedFlight={this.props.activeFlight}/>;
      }
    }
    const hours = [
      new Date().getHours() - 1,
      new Date().getHours() - 2,
      new Date().getHours() - 3,
    ];

    return (

      <div id="LiveFlightsContainer" className={this.props.activeTab === 'live-flights' ? '' : 'hidden'}>
        <div className="ft-continent-list zIndexTop">
            <ul id="flights-regions">
              {this.state.profileType === 'standard' && <li onClick={this.selectRegionStandard.bind(this, {region: 'all', regionName: 'ALL'})}><a className={ this.props.selectedRegion === 'all' ? 'offers-region-btn ft-btn color-red' : 'offers-region-btn ft-btn' }><Translate value="application.ALL" /></a></li>}

              {regionsChildren}
              {this.state.profileType === 'special' && this.props.selectedRegion !== 'all' && <li onClick={() => this.selectRegionSpecial({region: 'all', regionName: 'ALL'})}>
                  <a className={ 'offers-region-btn ft-btn color-dark'  }><Translate value="application.RESET_FILTERS" /></a>
                </li>}

            </ul>
        </div>

        {flightInfo}

      </div>
    );
  }
}


/*
         <div className="ft-timelist zIndexTop">
            <div className="ft-timelist-now"><span id="current-time"></span> <Translate value="application.NOW" /></div>
            <ul>
                <li onClick={() => {this.animatePlanes(1)}}><a >{hours[0]}:00</a></li>
                <li onClick={() => {this.animatePlanes(2)}}><a >{hours[1]}:00</a></li>
                <li onClick={() => {this.animatePlanes(3)}}><a >{hours[2]}:00</a></li>
            </ul>
        </div>
        */



const mapStateToProps = (state) => {
  return {
      activeTab: state.app.activeTab,
      language: state.app.language,
      destinations: state.data.destinations,
      flights: state.data.liveFlights,
      selectedRegion: state.app.activeLiveFlightsRegion,
      activeFlight: state.app.activeFlight,
      activeFlightInfo: state.data.activeFlightInfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setRegion: (region) => {
      dispatch({
        type: 'SET_LIVE_FLIGHTS_REGION',
        value: region
      });
    },
    showHistory: (opt) => {
      dispatch({
        type: 'SET_SHOW_HISTORY',
        value: opt
      });

    }
  }
}
LiveFlights.contextType = ProfileContext;

export default connect(mapStateToProps, mapDispatchToProps)(LiveFlights);
