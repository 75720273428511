import React, { Component } from 'react';
import { connect } from 'react-redux';
import Mapbox from 'components/App/Mapbox';
import LiveFlights from 'components/App/LiveFlights';
import Destinations from 'components/App/Destinations';
import Offers from 'components/App/Offers';
import moment from 'moment';
import ReactGA from 'react-ga';

import { mapboxConfig } from 'config';
import logo from 'assets/logo.png';
import { I18n } from 'react-i18nify';

class Body extends Component {

  constructor(props) {
    super(props);

    let baseURlForVideo = '/';
    if (window.location.hostname.indexOf('ft.wewidenyourworld.com') === 0) {
      baseURlForVideo = 'http://localhost:3000/';
    }
    this.baseURlForVideo = baseURlForVideo;
    this.videoPlayCount = 0;
    this.currentVideo = baseURlForVideo+'data/EN.mp4?v=' + Date.now();
    this.videoState = {
      status: 'W',
      lang: 'TR'
    };
    this.state = {
      isReady: false
    }
    this.waitingForFlightTrackerAppTime = 3 * 60 * 1000; // 3 min
    this.waitingForFlightTrackerAppReloadTime = 12 * 60 * 60 * 1000; // 60 min;
  }

  componentDidMount() {
    this.video = document.getElementById("promo-video");
    this.overlay = document.getElementById("promo-video-overlay");
    this.overlayBg = document.getElementById("promo-video-bg");
    const body = document.body;
    body.onclick = this.bodyOnClick.bind(this);
    if (window.screenSaverVideoTimeOut.length === 0) {
      window.screenSaverVideoTimeOut.push(window.setTimeout(this.openVideo.bind(this) , this.waitingForFlightTrackerAppTime));
    }
    window.setTimeout(function(){
      window.location = window.location.protocol + '//' + window.location.host + '?v=' + moment().format('YYYYMMDDHHmmSS');
    } , this.waitingForFlightTrackerAppReloadTime);
    this.setState({isReady: true});
  }


  bodyOnClick(e) {
    this.clearTimeouts();
    window.setTimeout(function(){
      window.screenSaverVideoTimeOut.push(window.setTimeout(this.openVideo.bind(this) , this.waitingForFlightTrackerAppTime));
    }.bind(this), 2000);
  }

  clearTimeouts() {
    for(let i = 0; i<window.screenSaverVideoTimeOut.length; i++){
      window.clearTimeout(window.screenSaverVideoTimeOut[i]);
    }
    window.screenSaverVideoTimeOut = [];
  }

  closeVideo() {
    console.log('closing video');
    ReactGA.event({
      category: 'Video',
      action: 'play_video',
      label: this.video.src
    });
    this.video.pause();
    this.video.style.display = 'none';
    this.overlay.style.display = 'none';
    this.overlayBg.style.display = 'none';
    this.clearTimeouts();
    window.setTimeout(function(){
      window.screenSaverVideoTimeOut.push(window.setTimeout(this.openVideo.bind(this) , this.waitingForFlightTrackerAppTime));
    }.bind(this), 2000);
    this.props.viewModule('live-flights');

  }

  openVideo() {

    if (window.location.hostname.indexOf('ft-stage.wewidenyourworld.com') === 0 ) {
      return;
    }
    console.log('opening video');
    let nextVideo = this.baseURlForVideo+"data/TR.mp4?v=" + Date.now();
    console.log(this.video.src);
    if (this.video.src.indexOf('TR') !== -1) {
      nextVideo = this.baseURlForVideo+"data/EN.mp4?v=" + Date.now();
    }
    ReactGA.event({
      category: 'Video',
      action: 'play_video',
      label: nextVideo
    });
    this.video.src = nextVideo;
    this.video.style.display = 'block';
    this.overlay.style.display = 'block';
    this.overlayBg.style.display = 'block';
    window.setTimeout(function(){this.video.play();}.bind(this), 200);
    this.clearTimeouts();
    this.props.viewModule('promo-video');

  }

  videoEnded() {
    this.closeVideo();
  }

  render() {
    const {isReady} = this.state;
      return(<div>
        <div id="promo-video-bg" style={{display: 'none'}} ><img src={logo} style={{opacity: 0.5, position: 'absolute', top: window.innerHeight/2-100, left: window.innerWidth/2-200}}/></div>
        <video muted id="promo-video" src={this.currentVideo} type="video/mp4" onEnded={() => this.videoEnded()} style={{display: 'none'}}/>
        <div id="promo-video-overlay" onClick={() => this.closeVideo()} style={{display: 'none'}}/>
        {isReady && <Mapbox />}
        <LiveFlights />
        <Destinations />
        <Offers />
        <small style={{color: 'grey', position: 'fixed', left: 5, bottom:5}}>{mapboxConfig.buildId} </small>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    offers: state.data.offers,

    activeTab: state.app.activeTab,
      language: state.app.language,
      destinations: state.data.destinations,
      flights: state.data.liveFlights,
      selectedRegion: state.app.activeLiveFlightsRegion,
      activeFlight: state.app.activeFlight,
      activeFlightInfo: state.data.activeFlightInfo,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {

    viewModule: (moduleName) => {
      dispatch({
        type: 'SET_MODULE',
        value: moduleName
      });
      let type = 'SET_LIVE_FLIGHTS_REGION';
      switch (moduleName) {
        case 'destinations':
          type = 'SET_DESTINATIONS_REGION';
          break;
        case 'offers':
          type = 'SET_OFFERS_REGION';
          break;
        default:
          break;
      }
      dispatch({
        type: type,
        value: 'all'
      });
    },

    setActiveFlight: (data) => {
      dispatch({
        type: 'SET_ACTIVE_FLIGHT',
        value: data
      });
      dispatch({
        type: 'SET_ACTIVE_FLIGHT_DATA',
        value: null
      });
    },

    setRegion: (region) => {
      dispatch({
        type: 'SET_LIVE_FLIGHTS_REGION',
        value: region
      });
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Body);
