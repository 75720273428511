import React, { Component } from "react";
import { connect } from "react-redux";
import { Translate, t } from "react-i18nify";
import gsap, { Circ } from "gsap";

import { point, distance } from "@turf/turf";
import { Calculations } from "helpers";

const aircrafts = {
  A310: "Airbus A310",
  A312: "Airbus A312",
  A313: "Airbus A313",
  A319: "Airbus A319",
  A320: "Airbus A320",
  A321: "Airbus A321",
  A322: "Airbus A322",
  A330: "Airbus A330",
  A331: "Airbus A330-100",
  A332: "Airbus A330-200",
  A333: "Airbus A330-300",
  A340: "Airbus A340",
  A350: "Airbus A350",
  A359: "Airbus A350", //Airbus A350-941
  A21N: "Airbus A321 NEO",
  B732: "Boeing 737",
  B734: "Boeing 737",
  B735: "Boeing 737",
  B737: "Boeing 737-700",
  B738: "Boeing 737-800",
  B789: "Boeing 787-9 Dreamliner",
  789: "Boeing 787-9 Dreamliner",
  738: "Boeing 737-800",
  "73J": "Boeing 737-900ER",
  B739: "Boeing 737-900ER",
  B777: "Boeing 777",
  B77L: "Boeing 777",
  B77W: "Boeing 777-300ER",
  "7M8": "Boeing B737-8 MAX",
  N78: "Boeing B737-8 MAX",
  B7M8: "Boeing B737-8 MAX",
  B38M: "Boeing B737-8 MAX",
  B39M: "Boeing B737-8 MAX",
};

class FlightInfo extends Component {
  constructor(props) {
    super(props);
    this.componentHeight = 370;
    this.componentWidth = 280;
    //this.animatePlane = this.animatePlane.bind(this);
  }

  componentDidMount() {

    const newTopValue = window.innerHeight - this.componentHeight - 250;
    document.getElementById("LiveFlightInfoContainer").style.zIndex = 9999;

    document.getElementById("LiveFlightInfoContainer").style.position = "fixed";
    document.getElementById("LiveFlightInfoContainer").style.top =
      newTopValue + "px";
    document.getElementById("LiveFlightInfoContainer").style.left = 20 + "px";
    //document.getElementById('LiveFlightInfoContainer').style.display = 'block';

    gsap.to("#LiveFlightInfoContainer", 1, {
      ease: Circ.easeIn,
      top: newTopValue,
      display: "block",
      left: 20,
      onComplete: this.animatePlane.bind(this),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("componentDidUpdate - FlightInfo", {
      activeTab: this.props.activeTab,
      flightNumber: this.props.flightInfo.flight.number,
    });
  }

  componentWillUnmount() {
    gsap.killTweensOf("#LiveFlightInfoContainer .plane-marker");
    gsap.killTweensOf("#LiveFlightInfoContainer .ft-duration-track");
  }

  animatePlane() {
    const duration = (1.5 - (1 - this.remainingTimePercentage / 100)) / 2;
    console.log(
      "Starting animation with remainingTimePercentage:",
      this.remainingTimePercentage
    );

    gsap.to("#LiveFlightInfoContainer .plane-marker", duration, {
      ease: "sine.out",
      left: this.remainingTimePercentage + "%",
    });

    gsap.to("#LiveFlightInfoContainer .ft-duration-track", duration, {
      ease: "sine.out",
      width: this.remainingTimePercentage + "%",
    });
  }

  getAircraftType(flightId) {
    const configLength = this.props.liveFlightsConfig.length;
    for (let i = 0; i < configLength; i++) {
      let flightNumberIntervals = this.props.liveFlightsConfig[i];
      if (
        flightId >= flightNumberIntervals.startNumber &&
        flightId <= flightNumberIntervals.endNumber &&
        flightNumberIntervals.isEnabled === true
      ) {
        return flightNumberIntervals.type.toLowerCase();
      }
    }
    return "aircrafts";
  }

  render() {
    const isManager = window.location.search.includes("manager") || document.location.hostname.includes("manager");

    if (
      typeof this.props.liveFlights[this.props.flightInfo.flight.number] ===
        "undefined" ||
      this.props.activeTab !== "live-flights"
    ) {
      return <div style={{ display: "none" }}></div>;
    }
    let { departureCity, arrivalCity } = this.props.flightInfo.flight;
    const departureCityName =
      departureCity && departureCity.cityName ? departureCity.cityName : "-";
    const arrivalCityName =
      arrivalCity && arrivalCity.cityName ? arrivalCity.cityName : "-";

    let flightNumber = this.props.flightInfo.flight.number;
    flightNumber = parseInt(flightNumber.replace("TK", ""), 10);
    const imageFolder = this.getAircraftType(flightNumber);

    let aircraftName = "--";
    let aircraftImage = "/data/images/" + imageFolder + "/undefined.png";
    if (
      typeof aircrafts[this.props.flightInfo.flight.aircraft.code] !==
      "undefined"
    ) {
      aircraftName = aircrafts[this.props.flightInfo.flight.aircraft.code];
      aircraftImage =
        "/data/images/" +
        imageFolder +
        "/" +
        this.props.flightInfo.flight.aircraft.code +
        ".png";
    } else {
      console.log("undefined", this.props.flightInfo.flight.aircraft);
    }
    const liveFlightInfo =
      this.props.liveFlights[this.props.flightInfo.flight.number];
    const { status, time } = this.props.flightInfo.flight;
    const { departure_time, duration } = time;

    let remainingTimePercentage = 0;
    let remainingTime = "";
    let flightStatus = "";
    let altitude = this.props.flightInfo.flight.debug.alt || null;
    let speed = this.props.flightInfo.flight.debug.speed || null;
    let dep_delayed = "";
    let arr_delayed = "";
    let reg_number = this.props.flightInfo.flight.debug.reg_number || null

    if (this.props.flightInfo.flight.debug.dep_delayed > 0) {
      dep_delayed = this.props.flightInfo.flight.debug.dep_delayed;
    }

    if (this.props.flightInfo.flight.debug.arr_delayed > 0) {
      arr_delayed = this.props.flightInfo.flight.debug.arr_delayed;
    }

    // Directly displaying the status based on the flight data
    switch (status) {
      case "landed":
        flightStatus = t("live_flights.landed");
        this.remainingTimePercentage = 100;
        //this.animatePlane()
        break;
      case "scheduled":
        flightStatus = t("live_flights.scheduled");
        break;
      case "en-route":
      case "estimated":
        flightStatus = t("live_flights.enroute");
        // Calculate remaining time if flight is en-route
        remainingTime = Calculations.calculateRemainingFlightTime(
          departure_time,
          duration
        );

        this.remainingTimePercentage =
          Calculations.calculateRemainingPercentageReal(
            duration,
            remainingTime
          );
        console.log("Remaining Percentage:", this.remainingTimePercentage);
        // this.animatePlane()
        break;
      case "diverted":
        flightStatus = t("live_flights.diverted");
        break;
      default:
        flightStatus = t("live_flights.enroute"); // Use enroute as a default if status is unrecognized
    }

    const flightIndex = `${this.props.flightInfo.flight.departure.iata}-${this.props.flightInfo.flight.arrival.iata}`;
    const totalFlightTime = Calculations.convertDuration(duration);
    /*     const totalFlightTime2 = Calculations.prettyPrintFlightTime(
      this.props.flightTimes[flightIndex]
    ); */

    let arrivalWeather = [];

    if (
      typeof this.props.flightInfo.weather.arrival.condition !== "undefined"
    ) {
      let arrivalWeatherImage =
        "/data/images/weather/open-meteo/" +
        this.props.flightInfo.weather.arrival.condition.code +
        ".png";
      arrivalWeather.push(
        <img
          key="1"
          alt=""
          className="f-arrival-weather-image"
          src={arrivalWeatherImage}
          style={{ width: "30px" }}
        />
      );
      arrivalWeather.push(<span key="1-1"> </span>);
      arrivalWeather.push(
        <span key="2" className="f-arrival-weather-temperature">
          {this.props.flightInfo.weather.arrival.condition.temp_C}
        </span>
      );
      arrivalWeather.push(<sup key="3">o</sup>);
    }
    let departureWeather = [];

    if (
      typeof this.props.flightInfo.weather.departure.condition !== "undefined"
    ) {
      let departureWeatherImage =
        "/data/images/weather/open-meteo/" +
        this.props.flightInfo.weather.departure.condition.code +
        ".png";
      departureWeather.push(
        <img
          key="1"
          alt=""
          className="f-arrival-weather-image"
          src={departureWeatherImage}
          style={{ width: "30px" }}
        />
      );
      departureWeather.push(<span key="1-1"> </span>);
      departureWeather.push(
        <span key="2" className="f-arrival-weather-temperature">
          {this.props.flightInfo.weather.departure.condition.temp_C}
        </span>
      );
      departureWeather.push(<sup key="3">o</sup>);
    }
    return (
      <div
        id="LiveFlightInfoContainer"
        style={{
          position: "fixed",
          top: this.props.selectedFlight.position.top,
          left: this.props.selectedFlight.position.left,
          width: this.componentWidth + "px",
          height: this.componentHeight + "px",
          display: "none",
          zIndex: 9999,
        }}
      >
        <div className="ft-tooltip ft-tooltip-flightinfo">
          <div className="ft-tooltip-header">
            <h4>{this.props.flightInfo.flight.number}</h4>
            <p>
              {flightStatus && (
                <span className="flight_status display-linebreak">
                  {flightStatus}
                </span>
              )}
            </p>
            {/*             <p>
              {totalFlightTime && totalFlightTime !== "" && (
                <span>
                  <strong>
                    <Translate value="live_flights.total_time" />
                    &nbsp;<span className="total_time">{totalFlightTime}</span>
                  </strong>
                </span>
              )}
            </p> */}

            {/* <span className="remaining_time display-linebreak">{flightStatus}</span> */}
          </div>
          <div className="ft-tooltip-content">
            <div className="ft-plane-info">
              <p>{aircraftName}</p>
              <p>{reg_number} </p>
              <img src={aircraftImage} alt="" />
              <div className="ft-duration-left">
                <div className="ft-duration-track-wrapper">
                  <div className="ft-duration-track" style={{ width: 0 }}></div>
                  <i className="plane-marker" style={{ left: 0 }}></i>
                </div>
                <div>
                  {/*                   {flightStatus && (
                    <span>
                      <strong>
                        <Translate value="live_flights.status" />
                      </strong>
                      <span className="remaining_time2 display-linebreak">
                        {flightStatus}
                      </span>
                    </span>
                  )}
                  {flightStatus && <br />} */}

                  {speed && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: 20,
                      }}
                    >
                      {altitude && (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: "20px",
                          }}
                        >
                          <strong>
                            <Translate value="live_flights.altitude" />
                          </strong>
                          <span className="remaining_time2 display-linebreak">
                            {altitude} ft
                          </span>
                        </span>
                      )}

                      {speed && (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: "20px",
                          }}
                        >
                          <strong>
                            <Translate value="live_flights.speed" />
                          </strong>
                          <span className="remaining_time2 display-linebreak">
                            {speed} kt
                          </span>
                        </span>
                      )}
                    </div>
                  )}

                  {totalFlightTime && (
                    <span>
                      <strong>
                        <Translate value="live_flights.total_time" />
                      </strong>
                      <span className="remaining_time2 display-linebreak">
                        {totalFlightTime}
                      </span>
                    </span>
                  )}
                  {totalFlightTime && <br />}

                  {remainingTime && (
                    <span>
                      <strong>
                        <Translate value="live_flights.remaning_time" />
                      </strong>
                      <span className="remaining_time2 display-linebreak">
                        {remainingTime}
                      </span>
                    </span>
                  )}
                  {remainingTime && <br />}

                  <div>
                    {isManager && (
                      <>
                        {dep_delayed && (
                          <>
                            <br />
                            <span>
                              <span className="remaining_time2 display-linebreak">
                                {dep_delayed}
                              </span>
                              <Translate value="live_flights.dep_delayed" />
                            </span>
                          </>
                        )}

                        {arr_delayed && (
                          <>
                            <br />
                            <span>
                              <span className="remaining_time2 display-linebreak">
                                {arr_delayed}
                              </span>
                              <Translate value="live_flights.arr_delayed" />
                            </span>
                            <br />
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="ft-flightinfo-divider"></div>
            <div className="ft-flightinfo-table-wrapper">
              <table>
                <tbody>
                  <tr>
                    <td className="f-departure">
                      <strong className="iata">
                        {this.props.flightInfo.flight.departure.iata}
                      </strong>
                      <br />
                      <span className="f-departure-city-name">
                        <Translate value={departureCityName} />
                      </span>
                      <div className="ft-city-weather">{departureWeather}</div>
                    </td>
                    <td>
                      <span className="city-plane-city">
                        <i className="icon-plane" />
                      </span>
                    </td>
                    <td className="f-arrival">
                      <strong className="iata">
                        {this.props.flightInfo.flight.arrival.iata}
                      </strong>
                      <br />
                      <span className="f-arrival-city-name">
                        <Translate value={arrivalCityName} />
                      </span>
                      <div className="ft-city-weather">{arrivalWeather}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeTab: state.app.activeTab,
    language: state.app.language,
    //destinations: state.data.destinations,
    //flightTimes: state.data.flightTimes,
    activeFlight: state.app.activeFlight,
    liveFlights: state.data.liveFlights,
    liveFlightsConfig: state.data.liveFlightsConfig,
  };
};

export default connect(mapStateToProps)(FlightInfo);
