import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate} from 'react-i18nify';
import gsap from "gsap";
import  {managerUrls} from 'config/prod-hosts';

class DestinationsTooltip extends Component {

  constructor(props) {
    super(props);
    this.visible = 0;
    this.tooltipType = 'to';
    this.topPosition = 0;
    this.toCity = null;
    this.fromCity = null;
  }

  componentDidUpdate() {
      const element = document.getElementById('cityInfoPopup');
      if (this.visible === 1) {
          this.closeThisPopUp();
      }
      else if (element !== null) {
          if (this.props.type === 'manager') {
              element.style.top = this.topPosition + 'px';
          } else {
              const newBottomPosition =  (window.innerHeight - this.topPosition - 30);
              element.style.bottom = newBottomPosition + 'px';
          }

        element.style.display = 'block';
        element.style.opacity = 0.1;
        this.visible = 1;
        gsap.to('#cityInfoPopup', {
        //    ease: SlowMo.ease.config(0.2, 0.2, false), //Circ.easeIn,
            opacity: 1,
            duration: 1,
            ease: "slowmo"

          });
      }
  }

  closeThisPopUp() {
    /*const element = document.getElementById('cityInfoPopup');
    if (this.visible === 1) {
        this.visible = 0;
    }
    if (element !== null) {
        element.style.display = 'none';
    }*/
    this.props.changeDisplay();
  }



  render() {

      console.log(this.props.type);

    let visibility = this.props.tooltipData.display;

    if (this.toCity !== null && this.props.toCity !== null && this.props.toCity.cityIata !== this.toCity.cityIata ) {
        visibility = 'none';
    }
    if (this.fromCity !== null && this.props.fromCity !== null && this.props.fromCity.cityIata !== this.fromCity.cityIata ) {
        visibility = 'none';
    }
    let cityData = this.props.fromCity;
    if (this.props.tooltipType === 'to') {
        cityData = this.props.toCity;
    }
    if (cityData === null) {
        return(<div/>);
    }

    this.toCity = this.props.toCity;
    this.fromCity = this.props.fromCity;

    if (visibility === 'none') {
        this.visible = 0;
        return (<div style={{display: 'none'}}></div>);
    }
    if(this.tooltipType !== this.props.tooltipType) {
        this.visible = 0;
    }
    this.tooltipType = this.props.tooltipType;
      let style = {
          display: this.props.tooltipData.display,
          position: 'fixed',
          bottom: window.innerHeight - this.props.tooltipData.position.bottom - 40,
          left: this.props.tooltipData.position.left,
          zIndex: 99999,
          // height: '300px',
          width: '240px'
      };
      this.topPosition = this.props.tooltipData.position.bottom;
    if (this.props.type === 'manager') {
        const topDiff = this.props.tooltipData.position.top > 600 ? 'bottom' : 'top';

        style = {
            display: this.props.tooltipData.display,
            position: 'fixed',
            top: this.props.tooltipData.position.top-19,
            bottom: -10,
            left: this.props.tooltipData.position.left,
            zIndex: 99999,
            // height: '300px',
            width: '240px'
        };
        if (topDiff === 'top') {
            delete style.bottom;
        } else {
            delete style.top;
        }
        this.topPosition = this.props.tooltipData.position.top - topDiff;
    }
    return (
        <div style={style} id="cityInfoPopup">
            <div className="ft-tooltip ft-tooltip-cityshortinfo">
                <div className="ft-tooltip-content">
                    <div className="thumb" style={{
                      backgroundColor: '#333',
                      backgroundImage: `url(${cityData.cityHeaderImagePath})`,
                      minHeight: 120,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'top center'
                    }}>
                      <div style={{
                        backgroundColor: 'rgba(0,0,0, 0.2)',
                        width: '100%',
                        height: 120
                      }}>
                        <div className="caption"><strong><Translate value={cityData.cityName} />, <Translate value={cityData.country} /></strong></div>
                      </div>

                    </div>
                    <p><Translate value={'CG-'+cityData.cityIata} /></p>
                </div>
                <a  className="ft-tooltip-close" onClick={() => this.closeThisPopUp()}>&nbsp;</a>
            </div>
        </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    activeTab: state.app.activeTab,
    language: state.app.language,
    destinations: state.data.destinations,
    selectedRegion: state.app.activeDestinationsRegion,

    formFocus: state.app.destinationFormFocus,
    fromCity: state.app.destinationsFromCity,
    toCity: state.app.destinationsToCity,
    selectedRouteFromCityFrequency: state.app.selectedRouteFromCityFrequency,
    selectedRouteToCityFrequency: state.app.selectedRouteToCityFrequency
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFocus: (input) => {
      dispatch({
        type: 'SET_FORM_FOCUS',
        value: input
      });
    },

    setFormCity: (action, value) => {
      dispatch({
        type: action,
        value: value
      });
    },
    setFrequency: (type, frequency) => {
      dispatch({
        type: type,
        value: frequency
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DestinationsTooltip);


