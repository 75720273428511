const appStateReducer = (
  state = {
    language: "tr",
    activeTab: "live-flights",
    numberOfFlights: 0,
    numberOfDestinations: 300,
    numberOfOffers: 0,
    activeLiveFlightsRegion: "all",
    activeDestinationsRegion: "all",
    activeOffersRegion: "all",
    mapLoaded: false,
    activeFlight: null,
    activeOffer: null,
    zoomLevel: 1,
    destinationFormFocus: "to",
    destinationsFromCity: null,
    destinationsToCity: null,
    selectedRouteFromCityFrequency: null,
    selectedRouteToCityFrequency: null,
    showHistory: false,
    onMapClick: 0,
    mapProjection: "mercator",
  },
  action
) => {
  // jshint ignore:start
  switch (action.type) {
    case "SET_LANGUAGE":
      return {
        ...state,
        language: action.value,
      };
    case "SET_MODULE":
      return {
        ...state,
        activeTab: action.value,
      };
    case "SET_MAP_CLICK":
      return {
        ...state,
        onMapClick: state.onMapClick + 1,
      };
    case "CHANGE_ZOOM_LEVEL":
      let newValue = state.zoomLevel + action.value;
      return {
        ...state,
        zoomLevel: newValue,
      };

    case "SET_ZOOM_LEVEL":
      return {
        ...state,
        zoomLevel: action.value,
      };
    case "SET_ACTIVE_FLIGHT":
      return {
        ...state,
        activeFlight: action.value,
      };

    case "SET_SHOW_HISTORY":
      return {
        ...state,
        showHistory: action.value,
      };
    case "SET_ACTIVE_OFFER":
      return {
        ...state,
        activeOffer: action.value,
      };
    case "SET_MAP_LOADED":
      return {
        ...state,
        mapLoaded: action.value,
      };
    case "SET_DESTINATIONS_NUMBER":
      return {
        ...state,
        numberOfDestinations: action.value,
      };
    case "SET_OFFERS_NUMBER":
      return {
        ...state,
        numberOfOffers: action.value,
      };
    case "SET_FLIGHTS_NUMBER":
      return {
        ...state,
        numberOfFlights: action.value,
      };
    case "SET_LIVE_FLIGHTS_REGION":
      return {
        ...state,
        activeLiveFlightsRegion: action.value,
      };

    case "SET_DESTINATIONS_REGION":
      return {
        ...state,
        activeDestinationsRegion: action.value,
      };
    case "SET_FROM_CITY":
      return {
        ...state,
        destinationsFromCity: action.value,
      };

    case "SET_TO_CITY_ROUTE_FREQUENCY":
      return {
        ...state,
        selectedRouteToCityFrequency: action.value,
      };
    case "SET_FROM_CITY_ROUTE_FREQUENCY":
      return {
        ...state,
        selectedRouteFromCityFrequency: action.value,
      };
    case "SET_TO_CITY":
      return {
        ...state,
        destinationsToCity: action.value,
      };
    case "SET_FORM_FOCUS":
      return {
        ...state,
        destinationFormFocus: action.value,
      };
    case "SET_OFFERS_REGION":
      return {
        ...state,
        activeOffersRegion: action.value,
      };
    case "SET_MAP_PROJECTION":
      return {
        ...state,
        mapProjection: action.value,
      };
    default:
      return state;
  }
  // jshint ignore:end
};

export default appStateReducer;
