import mapboxgl, { Map as MapboxMap } from "mapbox-gl";
import mapboxCSS from 'mapbox-gl/dist/mapbox-gl.css';
import Marker from './Marker';
import dn2geojson from './daynight';
import { lineDistance, along } from '@turf/turf';
import * as turf from '@turf/turf';
import { mapboxConfig } from 'config';

class Map {

    constructor(mapboxConfig) {
        mapboxgl.accessToken = mapboxConfig.accessToken;
        this.config = mapboxConfig;
        this.onLoadFunctions = [];
        this.onClickFunctions = [];
        this.onZoomEndFunctions = [];
        this.layers = {
            dayAndNight: null,
            selectedDestinationLines: []
        };
        this.flyTo = 0;
        this.checkForLayersSetTimeouts = [];
        this.minZoomLevel = 1;
        this.maxZoomLevel = this.minZoomLevel + 11;
        this.userInteracting = false;
        this.spinEnabled = false; 
        this.layerIDs = [];
    }
    isLoaded() {
        return this.map.loaded();
    }

    remove() {
        return this.map.remove();
    }

    loadMap() {
        this.map = new MapboxMap(this.config.mapOptions);
        this.zoomToRegion(this.config.mapOptions.center, this.minZoomLevel);
        this.map.dragRotate.disable();
        this.map.touchZoomRotate.disableRotation();
     // enabling this causes high cpu usage on client
        //   this.addEventListeners();

            // Add scale control
    this.map.addControl(new mapboxgl.ScaleControl({
        position: 'bottom-left', // You can change the position as needed
        maxWidth: 100,             // Customize the max width of the scale control
        unit: 'metric'            // Use 'imperial' for miles
      }));

        this.map.on('load', function () {
            this.minZoomLevel = parseFloat(this.map.getZoom().toFixed(2));
            this.maxZoomLevel = this.minZoomLevel + 11;
            this.map.setMaxZoom(this.maxZoomLevel);
            this.map.setMinZoom(this.minZoomLevel);
            this.onMapLoad();
            this.map.on('zoomend', function () {
                this.onZoomEnd();
            }.bind(this));
            this.map.on('click', function (e) {
                this.onClick(e);
            }.bind(this));
            this.map.on('style.load', function(){
                this.map.addSource('mapbox://mapbox.mapbox-streets-v8-i18n', {
                    type: 'vector',
                    url: 'mapbox://mapbox.mapbox-streets-v8-i18n',
                    minzoom: 0,
                    maxzoom: 6
                });
                this.map.addLayer({
                    "id": "places",
                    "type": "circle",
                    "source": "mapbox://mapbox.mapbox-streets-v8-i18n",
                    "source-layer": "place_label",
                    "layout": {},
                    "paint": {
                        "circle-radius": 3,
                        "circle-color": [
                            "case",
                            ["==", ["get", "name"], ["get", "name_local"]], "#ffffff",
                            "#ffffff"
                        ]
                    }
                }).bind(this);

                this.map.addLayer({
                    "id": "places-labels",
                    "type": "symbol",
                    "source": "mapbox://mapbox.mapbox-streets-v8-i18n",
                    "source-layer": "place_label",
                    "layout": {
                        "text-field": ["to-string", ["get", "name"]],
                        "text-anchor": "bottom-left",
                        "text-size": [
                            "case",
                            ["==", ["get", "name"], ["get", "name_local"]], 10,
                            12
                        ],
                        "text-font": [
                            "Source Code Pro Semibold",
                            "Arial Unicode MS Regular"
                        ],
                        "text-offset": [0.4, -0.1],
                        "text-allow-overlap": true,
                        "text-justify": "left"
                    },
                    "paint": {
                        "text-color": [
                            "case",
                            ["==", ["get", "name"], ["get", "name_local"]], "#ffffff",
                            "#ffffff"
                        ]
                    }
                }).bind(this);

                this.map.addLayer({
                    "id": "borders",
                    "type": "line",
                    "source": "mapbox://mapbox.mapbox-streets-v8-i18n",
                    "source-layer": "admin",
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-width": 2,
                        "line-opacity": 0.5,
                        "line-color": [
                            "case",
                            ["==", ["get", "worldview"], "US"], "#0074D9",
                            ["==", ["get", "worldview"], "IN"], "#7FDBFF",
                            ["==", ["get", "worldview"], "CN"], "#FF851B",
                            ["==", ["get", "worldview"], "JP"], "#FFDC00",
                            ["==", ["get", "worldview"], "RU"], "#eb4034",
                            ["==", ["get", "worldview"], "MA"], "#17b03b",
                            ["==", ["get", "worldview"], "AR"], "#182acc",
                            ["==", ["get", "worldview"], "TR"], "#9e106a",
                            "#34b5cf"
                        ]
                    }
                }).bind(this);

                this.map.addLayer({
                    "id": "borders-labels",
                    "type": "symbol",
                    "source": "mapbox://mapbox.mapbox-streets-v8-i18n",
                    "source-layer": "admin",
                    "layout": {
                        "text-field": ["to-string", ["get", "worldview"]],
                        "text-size": 14,
                        "text-font": [
                            "Source Code Pro Semibold",
                            "Arial Unicode MS Regular"
                        ],
                        "text-anchor": "center",
                        "text-allow-overlap": true,
                        "symbol-placement": "line",
                        "symbol-spacing": 20,
                        "text-keep-upright": true
                    },
                    "paint": {
                        "text-color": [
                            "case",
                            ["==", ["get", "worldview"], "US"], "#0074D9",
                            ["==", ["get", "worldview"], "IN"], "#7FDBFF",
                            ["==", ["get", "worldview"], "CN"], "#FF851B",
                            ["==", ["get", "worldview"], "JP"], "#FFDC00",
                            ["==", ["get", "worldview"], "RU"], "#eb4034",
                            ["==", ["get", "worldview"], "MA"], "#17b03b",
                            ["==", ["get", "worldview"], "AR"], "#182acc",
                            ["==", ["get", "worldview"], "TR"], "#9e106a",
                            "#79a0a8"
                        ]
                    }
                }).bind(this);

            })
        }.bind(this));
    }
     // calling this causes high cpu usage on client
    addEventListeners() {

        this.map.on('mousedown', () => {
            this.userInteracting = true;
        });
        this.map.on('touchstart', () => {
            this.userInteracting = true;
        });
        this.map.on('mouseup', () => {
            this.userInteracting = false;
            this.spinGlobe();
        });
        this.map.on('touchend', () => {
            this.userInteracting = false;
            this.spinGlobe();
        });
        this.map.on('dragend', () => {
            this.userInteracting = false;
            this.spinGlobe();
        });
        this.map.on('pitchend', () => {
            this.userInteracting = false;
            this.spinGlobe();
        });
        this.map.on('rotateend', () => {
            this.userInteracting = false;
            this.spinGlobe();
        });
        this.map.on('moveend', () => {
            this.spinGlobe();
        });

    }
    setLanguage(language) {
        this.map.setLanguage(language);
    }

    addOnLoadFunctions(callable) {
        this.onLoadFunctions.push(callable);
    }

    addOnClickFunctions(callable) {
        this.onClickFunctions.push(callable);
    }

    addOnZoomEndFunctions(callable) {
        this.onZoomEndFunctions.push(callable);
    }

    onMapLoad() {
        for (var i = 0; i < this.onLoadFunctions.length; i++) {
            this.onLoadFunctions[i]();
        }
        this.map.resize();
    }

    onZoomEnd() {
        for (var i = 0; i < this.onZoomEndFunctions.length; i++) {
            this.onZoomEndFunctions[i]();
        }
        if (this.flyTo === 1) {
            this.checkCoordinateInsideOfThePolygon(this.usePointCoordinates, this.getZoom());
        }
    }

    onClick(e) {
        for (let i = 0; i < this.onClickFunctions.length; i++) {
            this.onClickFunctions[i](e);
        }
    }
    setZoom(zoomLevel) {
        console.log('Map.setZoom: ', zoomLevel);
        this.map.jumpTo({
            center: this.map.getCenter(),
            zoom: zoomLevel,
            speed: 5,
            curve: 1,
            easing: function (t) {
                return t;
            } 
        });
    }

    setProjection(projection){
        this.map.setProjection(projection);
    }

    getZoom() {
        return this.map.getZoom();
    }

    zoomToRegion(center, zoomLevel) {

        this.map.jumpTo({
            center: center,
            zoom: zoomLevel,
            speed: 5,
            curve: 1,
             easing: function (t) {
                return t;
            } 
        });
    }

    addMarker(marker) {
        const newMarker = new Marker(this.map, marker);
        return newMarker.getMarker();
    }

    clearLayers(layersCategory) {
        for (let i = 0; i < this.layers[layersCategory].length; i++) {
            this.map.removeLayer(this.layers[layersCategory][i]);
        }
        this.layers[layersCategory] = [];
        if (this.layerIDs.length > 0) {
            this.layerIDs.forEach(id => {
                if (this.map.getLayer(id)) {
                    this.map.removeLayer(id);
                    if (this.map.getSource(id)) { // Also check for and remove the source if it exists
                        this.map.removeSource(id);
                    }
                }
            });
            this.layerIDs = []; // Clear the list after removing layers
        }
    }

    updateMarker(marker, newData) {
        return Marker.updateMarker(marker, newData);
    }

    addDayNightLayer() {
        var delta = 1;
        var latmin = -90., latmax = 90., lonmin = -180., lonmax = 180.;
        var datetocomp = [];
        var res_geojson = dn2geojson(datetocomp, delta, latmax, lonmax, latmin, lonmin);
        if (this.layers.dayAndNight !== null) {
            this.map.getSource('DayAndNight').setData(res_geojson.features[0]);
        } else {
            var feature = {
                'id': 'DayAndNight',
                'type': 'fill',
                'source': {
                    'type': 'geojson',
                    'data': res_geojson.features[0]
                },
                'layout': {},
                'paint': {
                    'fill-color': '#2B333D',
                    'fill-opacity': 0.4
                }
            };

            this.map.addLayer(feature);
            this.layers.dayAndNight = feature;
        }
        window.setTimeout(function () {
            this.addDayNightLayer();
        }.bind(this), 60 * 1000);
    }

    checkCoordinateInsideOfThePolygon(pointCoordinates, zoomLevel) {
        this.usePointCoordinates = pointCoordinates;
        let newZoomLevel = this.map.getZoom() - 0.3 * this.minZoomLevel;

        if (typeof zoomLevel !== 'undefined') {
            newZoomLevel = zoomLevel - 0.3 * this.minZoomLevel;
        }

        if (newZoomLevel < this.minZoomLevel) {
            newZoomLevel = this.minZoomLevel;
        }
        var bounds = this.map.getBounds();

        var polygonCoordinates = [
            [bounds._sw.lng, bounds._ne.lat],
            [bounds._ne.lng, bounds._ne.lat],
            [bounds._ne.lng, bounds._sw.lat],
            [bounds._sw.lng, bounds._sw.lat]
        ];

        var poly = {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Polygon",
                "coordinates": [polygonCoordinates]
            }
        };


        /*
            map.addLayer({
                    'id': 'bounds_'+Math.random(),
                    'type': 'fill',
                    'source': {
                        'type': 'geojson',
                        'data': poly
                    },
                    'layout': {},
                    'paint': {
                        'fill-color': '#088',
                        'fill-opacity': 0.8
                    }
            });

        */

        var point1 = {
            "type": "Feature",
            "properties": {
                "marker-color": "#f00"
            },
            "geometry": {
                "type": "Point",
                "coordinates": pointCoordinates[0]
            }
        };
        var point2 = {
            "type": "Feature",
            "properties": {
                "marker-color": "#f00"
            },
            "geometry": {
                "type": "Point",
                "coordinates": pointCoordinates[1]
            }
        };

        var point3 = {
            "type": "Feature",
            "properties": {
                "marker-color": "#f00"
            },
            "geometry": {
                "type": "Point",
                "coordinates": pointCoordinates[2]
            }
        };


        var isInside1 = turf.inside(point1, poly);
        var isInside2 = turf.inside(point2, poly);
        var isInside3 = turf.inside(point3, poly);
        var isInside = isInside1 && isInside2 && isInside3;

        if (isInside === false) {
            this.flyTo = 1;
            let pointToCalculate1 = turf.point(pointCoordinates[0]);
            let pointToCalculate2 = turf.point(pointCoordinates[1]);
            let midpoint = turf.midpoint(pointToCalculate1, pointToCalculate2);

            this.map.jumpTo({
                //center: [midpoint.geometry.coordinates[0], midpoint.geometry.coordinates[1]],
                zoom: newZoomLevel,
                speed: 5, // make the flying slow
                curve: 1, // change the speed at which it zooms out
                easing: function (t) {
                    return t;
                }
            }
            );

        }
        else {
            this.flyTo = 0;
            window.setTimeout(this.checkLayers.bind(this), 300);
        }
    }

    checkLayers() {

        let markers = document.getElementsByClassName('selected-destination-marker');
        let markerIds = ['IST'];
        for (let i = 0; i < markers.length; i++) {
            let marker = markers[i];
            let markerId = marker.id.replace('selected-dest-', '');
            if (!markerIds.includes(markerId)) {
                markerIds.push(markerId);
            }
        }
        this.checkPositionsForWidth(markerIds);

    }

    returnExtremePoints(markerIds) {
        var markerLeftPositions = [];
        var markerTopPositions = [];
        for (var i = 0; i < markerIds.length; i++) {
            let element = document.getElementById('selected-dest-' + markerIds[i]);
            if (markerIds[i] === 'IST') {
                element = document.getElementById('IST');
            }

            if (element !== null) {
                var markerPosition = element.getBoundingClientRect();
                markerLeftPositions.push(markerPosition.left);
                markerTopPositions.push(markerPosition.top);
            } else {
                console.warn(markerIds[i] + ' missing.');
            }
        }

        markerLeftPositions = markerLeftPositions.sort(function (a, b) { return a > b });
        markerTopPositions = markerTopPositions.sort(function (a, b) { return a > b });

        return {
            left: [markerLeftPositions[0], markerLeftPositions[markerLeftPositions.length - 1]],
            top: [markerTopPositions[0], markerTopPositions[markerTopPositions.length - 1]],
        };
    }

    checkPositionsForWidth(markerIds) {
        this.checkZoomLevelForSetTimeout();
        var visibibleAreaWidth = window.innerWidth - 650;
        //var visibibleAreaHeight = window.innerHeight - 200;
        var windowLeft = 320;
        var windowRight = window.innerWidth - 280;
        var windowTop = 100;
        var windowBottom = window.innerHeight - 100;

        var points = this.returnExtremePoints(markerIds);

        var pointDiff = points.left[1] - points.left[0];
        var amount = 0;
        var newZoomLevel = this.map.getZoom() - 0.5;

        if (points.top[1] >= windowBottom) {
            this.panByVertical('up', (windowBottom - points.top[1] - 30));

            var t1 = window.setTimeout(function () {
                this.checkPositionsForWidth(markerIds);
            }.bind(this), 800);
            this.checkForLayersSetTimeouts.push(t1);
        } else if (points.top[0] <= windowTop) {
            this.panByVertical('down', (windowTop - points.top[0] + 30));

            var t1 = window.setTimeout(function () {
                this.checkPositionsForWidth(markerIds);
            }.bind(this), 800);
            this.checkForLayersSetTimeouts.push(t1);
        }
        else if (pointDiff > visibibleAreaWidth) {
            if (newZoomLevel >= this.minZoomLevel) {
                this.flyToZoomLevelMinusOne();
                var t1 = window.setTimeout(function () {
                    this.checkPositionsForWidth(markerIds);
                }.bind(this), 800);
                this.checkForLayersSetTimeouts.push(t1);
            }
        } else if (points.left[0] < windowLeft) {

            if (parseFloat(this.map.getZoom().toFixed(2)) > this.minZoomLevel) {
                newZoomLevel = this.map.getZoom() - 0.2;

                amount = (windowLeft - points.left[0] + 30);
                if (Math.ceil(Math.abs(amount)) > 0) {
                    this.panBy('right', amount);
                    this.map.jumpTo({
                        zoom: newZoomLevel,
                        speed: 5, // make the flying slow
                        curve: 1, // change the speed at which it zooms out
                        easing: function (t) {
                            return t;
                        }
                    });
                    let t2 = window.setTimeout(function () {
                        this.checkPositionsForWidth(markerIds);
                    }.bind(this), 1000);

                    this.checkForLayersSetTimeouts.push(t2);
                }
            }
        } else if (points.left[1] > windowRight) {
            amount = (windowRight - points.left[1]) - 30;
            if (parseFloat(this.map.getZoom().toFixed(2)) <= this.minZoomLevel) {
                newZoomLevel = this.map.getZoom() + 0.3;

                this.map.setZoom(newZoomLevel);
            }
            if (this.map.getZoom() >= this.minZoomLevel) {
                //newZoomLevel = this.map.getZoom()+0.2;

                if (Math.ceil(Math.abs(amount)) > 0) {
                    this.panBy('left', amount);

                    //this.map.setZoom(newZoomLevel);
                    let t3 = window.setTimeout(function () {
                        this.checkPositionsForWidth(markerIds);
                    }.bind(this), 1000);
                    this.checkForLayersSetTimeouts.push(t3);
                }
            }
        } else if (points.top[1] > windowRight) {

        }

    }

    panBy(direction, amount) {

        this.map.panBy([-amount, 0], {
            duration: 0.9
        });
    }

    panByVertical(direction, amount) {

        this.map.panBy([0, -amount], {
            duration: 0.9
        });
    }

    checkZoomLevelForSetTimeout() {
        if (this.map.getZoom() >= this.minZoomLevel) {
            return false;
        } else {
            for (var i = 0; i < this.checkForLayersSetTimeouts.length; i++) {
                window.clearTimeout(this.checkForLayersSetTimeouts[i]);
            }
            this.checkForLayersSetTimeouts = [];
        }
    }

    flyToZoomLevelMinusOne() {
        var newZoomLevel = this.map.getZoom() - 1 * this.minZoomLevel;
        if (newZoomLevel < this.minZoomLevel) {
            newZoomLevel = this.minZoomLevel;
        }
        this.map.jumpTo({
            zoom: newZoomLevel,
            speed: 5, // make the flying slow
            curve: 1, // change the speed at which it zooms out
            easing: function (t) {
                return t;
            }
        });

    }


    addFlightRoute(id, origin, current, destination, color) {

        let lineColor = '#fff';
        if (typeof color !== 'undefined') {
            lineColor = color;
        }
        const route = {
            "type": "FeatureCollection",
            "features": [{
                "type": "Feature",
                "geometry": {
                    "type": "LineString",
                    "coordinates": [
                        origin,
                        current
                    ]
                }
            }]
        };

        const route2 = {
            "type": "FeatureCollection",
            "features": [{
                "type": "Feature",
                "geometry": {
                    "type": "LineString",
                    "coordinates": [
                        current,
                        destination
                    ]
                }
            }]
        };

        // Calculate the distance in kilometers between route start/end point.
        const lineDistance1 = lineDistance(route.features[0], { units: 'kilometers' });

        const arc = [];
        arc.push(origin);

        // Draw an arc between the `origin` & `destination` of the two points
        for (let i = 0; i < lineDistance1; i++) {
            let segment = along(route.features[0], i / 1000 * lineDistance1, { units: 'kilometers' });
            arc.push(segment.geometry.coordinates);
        }

        arc.push(current);
        // Calculate the distance in kilometers between route start/end point.
        const lineDistance2 = lineDistance(route2.features[0], { units: 'kilometers' });

        // Draw an arc between the `origin` & `destination` of the two points
        for (let j = 0; j < lineDistance2; j++) {
            var segment = along(route2.features[0], j / 1000 * lineDistance2, { units: 'kilometers' });
            arc.push(segment.geometry.coordinates);
        }

        arc.push(destination);
        route.features[0].geometry.coordinates = arc;

        const source = this.map.getSource(id);
        if (typeof source !== 'undefined') {
            this.map.removeSource(id);
        }

        this.map.addSource(id, {
            "type": "geojson",
            "data": route
        });

        const lineId = 'l_' + id;
        const lineLayer = this.map.getLayer(lineId);
        const lineIdIndex = this.layers.selectedDestinationLines.indexOf(lineId);
        if (typeof lineLayer !== 'undefined') {
            this.map.removeLayer(lineId);
            delete this.layers.selectedDestinationLines[lineIdIndex];
        }
        this.map.addLayer({
            "id": 'l_' + id,
            "source": id,
            "type": "line",
            "paint": {
                "line-width": 2,
                "line-color": lineColor,
                "line-dasharray": [2, 2]
            }
        });
        this.layers.selectedDestinationLines.push('l_' + id);
    }

    drawRealRoute = async (routeID, departure, arrival, departureCoords, arrivalCoords) => {
        console.log("Drawing real route", routeID);
    
        // Ensure removal of previous route layers
        if (this.layerIDs.length > 0) {
            this.layerIDs.forEach(id => {
                if (this.map.getLayer(id)) {
                    this.map.removeLayer(id);
                    this.map.removeSource(id);
                }
            });
            this.layerIDs = []; // Clear the list after removing layers
        }
    
        // Prepare API endpoint data
        const startDate = new Date(Date.now() - 12 * 3600 * 1000).toISOString();
        const endDate = new Date().toISOString();
        const airline = "TK";
        const flightHistoryUrl = `https://aviationgateway.efabsrv.com/api/v3/flights-history/${airline}?iata_identity=${routeID}&departure=${departure}&arrival=${arrival}&start_date=${startDate}&end_date=${endDate}`;
    
        try {
            const response = await fetch(flightHistoryUrl, {
                method: 'GET',
                headers: {
                    'X-API-KEY': 'HoEnc0JnHWrOCBSxRpbpWZgrV7FLSDe91i7MxiTr'  // Remember to secure your API Key
                }
            });
            const flightHistoryData = await response.json();

            if (!flightHistoryData || !Array.isArray(flightHistoryData)) {
                console.error('Invalid flight history data:', flightHistoryData);
                return;
            }
            // Generate lineString from the coordinates and altitude data
            const coordinates = flightHistoryData.map(point => [point.longitude, point.latitude]);
            const altitudes = flightHistoryData.map(point => point.altitude);
            const currentCoords = coordinates[coordinates.length - 1]; 

            const line = turf.lineString(coordinates);
            const smoothedLine = turf.bezierSpline(line);
    
            // Add the flight path data as a source and layer on the map
            this.map.addSource(routeID, {
                type: "geojson",
                data: smoothedLine,
                lineMetrics: true // Enable line metrics for gradient usage
            });
    
            this.map.addLayer({
                id: routeID,
                source: routeID,
                type: "line",
                layout: {
                    "line-cap": "round",
                    "line-join": "round"
                },
                paint: {
                    "line-width": 2,
                    "line-gradient": [
                        'interpolate',
                        ['linear'],
                        ['line-progress'], // Use the line progress expression
                        ...altitudes.reduce((acc, alt, index) => { // Spread operator to flatten the array
                            const color = this.altitudeToColor(alt); // Function call to get the color based on altitude
                            const position = index / (altitudes.length - 1); // Position of the gradient stop
                            return [...acc, position, color];
                        }, [])
                    ],
                    "line-opacity": 0.8
                },
            });
    
            this.map.addLayer({
                id: routeID + '-projected',
                type: 'line',
                source: {
                    type: 'geojson',
                    data: {
                        type: 'Feature',
                        geometry: {
                            type: 'LineString',
                            coordinates: [currentCoords, arrivalCoords]
                        }
                    }
                },
                layout: {
                    "line-cap": "round",
                    "line-join": "round"
                },
                paint: {
                    "line-width": 1,
                    "line-color": '#fff',
                    "line-dasharray": [2, 2]
                }
            });
    
            this.layerIDs.push(routeID, routeID + '-projected');
        } catch (error) {
            console.error('Error fetching or drawing the flight path', error);
        }
    };
    
    
    altitudeToColor(altitude) {
        const colors = [
            { alt: 0, color: '#0000ff' },    // Blue at sea level
            { alt: 5000, color: '#0074D9' }, // Lighter blue at 5,000 feet
            { alt: 10000, color: '#00ff00' },// Green at 10,000 feet
            { alt: 15000, color: '#7FFF00' },// Chartreuse green at 15,000 feet
            { alt: 20000, color: '#ffff00' },// Yellow at 20,000 feet
            { alt: 25000, color: '#FFD700' },// Gold at 25,000 feet
            { alt: 30000, color: '#FF8C00' },// Dark orange at 30,000 feet
            { alt: 35000, color: '#ff0000' },// Red at 35,000 feet
            { alt: 40000, color: '#DC143C' } // Crimson at 40,000 feet and above
        ];
        
    
        for (let i = 0; i < colors.length - 1; i++) {
            if (altitude < colors[i + 1].alt) {
                return colors[i].color;
            }
        }
        return colors[colors.length - 1].color; // Return the highest color for altitudes above the highest breakpoint
    }

    addFlightRouteBetweenTwoPoints(id, origin, destination, color) {

      let lineColor = '#fff';
      if (typeof color !== 'undefined') {
          lineColor = color;
      }
      const route = {
          "type": "FeatureCollection",
          "features": [{
              "type": "Feature",
              "geometry": {
                  "type": "LineString",
                  "coordinates": [
                      origin,
                      destination
                  ]
              }
          }]
      };

      // Calculate the distance in kilometers between route start/end point.
      const lineDistance1 = lineDistance(route.features[0], { units: 'kilometers' });

      const arc = [];
      arc.push(origin);

      // Draw an arc between the `origin` & `destination` of the two points
      for (let i = 0; i < lineDistance1; i++) {
          let segment = along(route.features[0], i / 1000 * lineDistance1, { units: 'kilometers' });
          arc.push(segment.geometry.coordinates);
      }
      arc.push(destination);

      route.features[0].geometry.coordinates = arc;

      const source = this.map.getSource(id);
      if (typeof source !== 'undefined') {
          this.map.removeSource(id);
      }

      this.map.addSource(id, {
          "type": "geojson",
          "data": route
      });

      const lineId = 'l_' + id;
      const lineLayer = this.map.getLayer(lineId);
      const lineIdIndex = this.layers.selectedDestinationLines.indexOf(lineId);
      if (typeof lineLayer !== 'undefined') {
          this.map.removeLayer(lineId);
          delete this.layers.selectedDestinationLines[lineIdIndex];
      }
      this.map.addLayer({
          "id": 'l_' + id,
          "source": id,
          "type": "line",
          "paint": {
              "line-width": 2,
              "line-color": lineColor,
              "line-dasharray": [2, 2]
          }
      });
      this.layers.selectedDestinationLines.push('l_' + id);
  }

  spinGlobe() {
     if (!this.spinEnabled || this.userInteracting) {
        return;
    } 

    const zoom = this.map.getZoom();
    const secondsPerRevolution = 120;
    const maxSpinZoom = 3;
    const slowSpinZoom = 2;

    if (zoom >= maxSpinZoom) {
        return;
    }

    let distancePerSecond = 360 / secondsPerRevolution;
    if (zoom > slowSpinZoom) {
        const zoomDif = (maxSpinZoom - zoom) / (maxSpinZoom - slowSpinZoom);
        distancePerSecond *= zoomDif;
    }

    const center = this.map.getCenter();
    center.lng -= distancePerSecond; // This line adjusts the longitude for spinning

    this.map.easeTo({
        center: center,
        duration: 1000,
        easing: (t) => t
    });

    // Optional: schedule the next spin
    setTimeout(() => this.spinGlobe(), 1000);
}

toggleSpin(spinEnabled) {
    this.spinEnabled = spinEnabled;
    this.userInteracting = false;  // Reset or set based on actual interaction
    if (spinEnabled) {
        this.spinGlobe(); // Start spinning when enabled
    }
}


    adjustMap(region) {
        let regionString = Array.isArray(region) && region.length > 0 ? region[0] : region;


        if (regionString === "all") {
            this.map.jumpTo({
                center: [29.8186794, 20.9818546],
                zoom: 0
            });
        }

        if (regionString === "EUROPE" || regionString === "AVRUPA") {
            this.map.jumpTo({
                center: [25, 50],
                zoom: 3.6
            });
        }
        else if (regionString === "MIDDLE EAST" || regionString === "ORTADOĞU") {

            this.map.jumpTo({
                center: [45, 30],
                zoom: 4
            });
        }
        else if (regionString === "ASYA" || regionString === "ASIA") {

            this.map.jumpTo({
                center: [90, 30],
                zoom: 2,
                speed: 5, // make the flying slow
                curve: 1, // change the speed at which it zooms out
                easing: function (t) {
                    return t;
                }
            });
        }
        else if ((regionString === "İÇ HAT") || (regionString === "DOMESTIC")) {
            this.map.jumpTo({
                center: [35, 40],
                zoom: 5.5,
                speed: 5, // make the flying slow
                curve: 1, // change the speed at which it zooms out
                easing: function (t) {
                    return t;
                }
            });

        }
        else if ((regionString === "AFRİKA") || (regionString === "AFRICA")) {

            this.map.jumpTo({
                center: [10, 0],
                zoom: 2.4,
                speed: 5, // make the flying slow
                curve: 1, // change the speed at which it zooms out
                easing: function (t) {
                    return t;
                }
            });

        }
        else if ((regionString === "AMERİKA") || (regionString === "AMERICA")) {

            this.map.jumpTo({
                center: [-80, 30],
                zoom: 2.1,
                speed: 5, // make the flying slow
                curve: 1, // change the speed at which it zooms out
                easing: function (t) {
                    return t;
                }
            });
        }
    }

}

export default Map;
