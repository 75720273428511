import {offerMarker, selectedOfferMarker} from 'config';


export default class Offers {
    constructor (offers, destinations) {
        this.offers = offers;
        this.destinations = destinations;
    }

    static getOfferMarkerData(offerData) {
        var destinationMarker = offerMarker;
        destinationMarker.properties.class = 'offer-marker';
        destinationMarker.id = offerData.data.code;
        destinationMarker.geometry.coordinates = [offerData.coordinates.lng, offerData.coordinates.lat];
        destinationMarker.onClick = offerData.onClick;
        return destinationMarker;
    }

}